import useSWR, { SWRResponse } from 'swr';
import { dataFetcher } from '../utils/dataFetcher';
import { BackendError } from '../utils/BackendError';
import { useContext } from 'react';
import { LoginContext } from '../Login';
import { getSwrRevalidationOptions } from './getSwrRevalidationOptions';

export function getUseSwr<Type>(fullUrl: string): SWRResponse<Type, BackendError> {
  const loginContext = useContext(LoginContext);

  const urlToUse = loginContext.accessToken ? fullUrl : null;
  const dataFetcherToUse = loginContext.accessToken ? dataFetcher<Type>(loginContext.accessToken) : null;
  return useSWR<Type, BackendError>(urlToUse, dataFetcherToUse, getSwrRevalidationOptions(fullUrl));
}
