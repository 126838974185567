import React, { useState, useEffect, useContext } from 'react';
import Typography from '@mui/material/Typography';
import { Box, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import {
  TreeCustomerFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeDeviceFE,
  TreeItemFE,
  instanceOfTreeDeviceFE,
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetGroupFE,
  instanceOfTreeFleetFE,
  instanceOfTreeDeviceUnassignedFE,
  instanceOfTreeFleetUnassignedFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  instanceOfTreeGatewayFE,
  instanceOfTreeGatewayUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
} from '../../../model/frontendDataModels';
import { AppContext } from '../../../App';
import { COMPONENT_PADDING } from '../../../themes/theme';

function addFleet(searchables: Searchable[], fleet: TreeFleetFE, canSelect: (treeItem: TreeItemFE) => boolean): void {
  function addDevices(devices: TreeDeviceFE[] | TreeGatewayFE[]): void {
    devices.forEach(device => {
      if (canSelect(device)) {
        searchables.push({
          treeItem: device,
          searchString: `${device.deviceId}, ${device.serialNumber}, ${device.type}, ${device.deviceTag}`,
          displayString: `${device.deviceId}, ${device.serialNumber}, ${device.type}, ${device.deviceTag}`,
        });
      }
    });
  }
  if (canSelect(fleet)) {
    searchables.push({
      treeItem: fleet,
      searchString: fleet.fleetName,
      displayString: `${fleet.fleetName}`,
    });
  }
  if (fleet.chargers) {
    addDevices(fleet.chargers);
  }
  if (fleet.batteries) {
    addDevices(fleet.batteries);
  }
  if (fleet.gateways) {
    addDevices(fleet.gateways);
  }
}

function addFleetUnassigned(searchables: Searchable[], fleet: TreeFleetUnassignedFE, canSelect: (treeItem: TreeItemFE) => boolean): void {
  function addUnassignedDevice(devices: TreeDeviceUnassignedFE[] | TreeGatewayUnassignedFE[]): void {
    devices.forEach(device => {
      if (canSelect(device)) {
        searchables.push({
          treeItem: device,
          searchString: `${device.deviceId}, ${device.serialNumber}, ${device.type}, ${device.deviceTag}`,
          displayString: `${device.deviceId}, ${device.serialNumber}, ${device.type}, ${device.deviceTag}`,
        });
      }
    });
  }
  if (fleet.chargers) {
    addUnassignedDevice(fleet.chargers);
  }
  if (fleet.batteries) {
    addUnassignedDevice(fleet.batteries);
  }
  if (fleet.gateways) {
    addUnassignedDevice(fleet.gateways);
  }
}

function addFleetGroup(searchables: Searchable[], fleetGroup: TreeFleetGroupFE, canSelect: (treeItem: TreeItemFE) => boolean): void {
  if (canSelect(fleetGroup)) {
    searchables.push({
      treeItem: fleetGroup,
      searchString: fleetGroup.fleetgroupName,
      displayString: `${fleetGroup.fleetgroupName}`,
    });
  }
  fleetGroup.fleets.forEach(fleet => addFleet(searchables, fleet, canSelect));
  fleetGroup.fleetgroups.forEach(subgroup => addFleetGroup(searchables, subgroup, canSelect));
}

function addCustomer(
  searchables: Searchable[],
  customer: TreeCustomerFE,
  includeUnassigned: boolean,
  canSelect: (treeItem: TreeItemFE) => boolean
): void {
  if (canSelect(customer)) {
    searchables.push({
      treeItem: customer,
      searchString: customer.customerName,
      displayString: `${customer.customerName}`,
    });
  }
  customer.fleets.forEach(fleet => {
    if (instanceOfTreeFleetFE(fleet)) {
      addFleet(searchables, fleet, canSelect);
    }
    if (instanceOfTreeFleetUnassignedFE(fleet) && includeUnassigned) {
      addFleetUnassigned(searchables, fleet, canSelect);
    }
  });
  customer.fleetgroups.forEach(group => addFleetGroup(searchables, group, canSelect));
  customer.children.forEach(customer => addCustomer(searchables, customer, includeUnassigned, canSelect));
}

function createSearchableList(
  rootCustomer: TreeCustomerFE,
  includeUnassigned: boolean,
  canSelect: (treeItem: TreeItemFE) => boolean
): Searchable[] {
  const searchables: Searchable[] = [];
  addCustomer(searchables, rootCustomer, includeUnassigned, canSelect);
  return searchables;
}

export type Searchable = {
  treeItem: Exclude<TreeItemFE, TreeFleetUnassignedFE>;
  searchString: string;
  displayString: string;
};

export type SearchItem = {
  id: string;
  searchString: string;
};

type Props = {
  selectedTreeItems: TreeItemFE[];
  selectTreeItems: (selectedTreeItems: TreeItemFE[]) => void;
  includeUnassigned: boolean;
  canSelect: (treeItem: TreeItemFE) => boolean;
};

function FleetDeviceSearch({ selectedTreeItems, selectTreeItems, includeUnassigned, canSelect }: Props): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');
  const [value, setValue] = useState<Searchable | null>(null);
  const theme = useTheme();

  const appContext = useContext(AppContext);

  const options = appContext.rootTreeCustomer ? createSearchableList(appContext.rootTreeCustomer, includeUnassigned, canSelect) : [];

  useEffect(() => {
    setInputValue('');
  }, [selectedTreeItems]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: `17px ${COMPONENT_PADDING}px`, marginBottom: '14px' }}>
      <Autocomplete
        sx={{ bgcolor: 'background.default', borderRadius: 2, width: '100%' }}
        clearOnEscape
        handleHomeEndKeys={false}
        inputValue={inputValue}
        value={value}
        onInputChange={(event, value): void => setInputValue(value)}
        noOptionsText={<Typography variant='columnHeader'>No options</Typography>}
        onChange={(event: React.SyntheticEvent, value: Searchable | null): void => {
          if (value && canSelect(value.treeItem)) {
            setValue(null);
            selectTreeItems([value.treeItem]);
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => {
          return (
            <TextField
              {...params}
              label='Search'
              variant='filled'
              color='info'
              InputLabelProps={{ sx: { color: theme.typography.columnHeader.color, marginLeft: '8px' } }}
              sx={{ input: { color: theme.typography.columnHeader.color, marginLeft: '8px' } }}
            />
          );
        }}
        options={options}
        getOptionLabel={(option: Searchable): string => option.searchString}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: Searchable): React.ReactNode => {
          return (
            <li
              {...props}
              key={
                instanceOfTreeDeviceFE(option.treeItem) ||
                instanceOfTreeDeviceUnassignedFE(option.treeItem) ||
                instanceOfTreeGatewayFE(option.treeItem) ||
                instanceOfTreeGatewayUnassignedFE(option.treeItem)
                  ? option.treeItem.mui
                  : instanceOfTreeCustomerFE(option.treeItem)
                  ? option.treeItem.customerId
                  : instanceOfTreeFleetGroupFE(option.treeItem)
                  ? option.treeItem.fleetgroupId
                  : option.treeItem.fleetId
              }
            >
              <Typography variant='columnHeader' sx={{ cursor: 'pointer' }}>
                {option.displayString}
              </Typography>
            </li>
          );
        }}
      />
    </Box>
  );
}

export default FleetDeviceSearch;
