import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from './Icon';
import { useTheme } from '@mui/material';

type HelpBoxProps = {
  hide: () => void;
  title: string;
  info: ReactNode;
};

export default function HelpBox({ hide, title, info }: HelpBoxProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '2px solid black',
        flexShrink: 0,
        width: 326,
        minWidth: 326,
        maxWidth: 326,
        padding: '16px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant='sectionHeader'
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon name='?' />
          <Box sx={{ marginLeft: '1em' }}>{title}</Box>
        </Typography>
        <Typography variant='sectionHeader' sx={{ cursor: 'pointer' }} onClick={hide}>
          <Icon name='x' />
        </Typography>
      </Box>

      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {info}

        <Typography sx={{ marginTop: '2em', color: theme.palette.info.main, cursor: 'pointer', userSelect: 'none' }} onClick={hide}>
          Ok, got it! Hide the window
        </Typography>
      </Box>
    </Box>
  );
}
