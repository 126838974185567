import { SWRResponse } from 'swr';
import {
  AggregatedAlarms,
  CalendarStateData,
  FieldsAndNumberValues,
  FieldsAndValues,
  LineChartData,
  ListTabs,
} from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useListTabs(type: 'fleet' | 'fleetgroup', id: string): SWRResponse<ListTabs, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/listTabs`);

  return getUseSwr<ListTabs>(fullUrl);
}

export function useChargingCompletionDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/daily`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<CalendarStateData>(fullUrl);
}

export function useChargingCompletionSum(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/sum`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<LineChartData>(fullUrl);
}

export function useEqualizingCompletion(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/completion`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<LineChartData>(fullUrl);
}

export function useSocDeepDischargeDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/daily`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<CalendarStateData>(fullUrl);
}

export function useSocDeepDischargeSum(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/sum`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<LineChartData>(fullUrl);
}

export function useAlarmsDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/daily`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<CalendarStateData>(fullUrl);
}

export function useAlarmsAggregated(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<AggregatedAlarms, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/aggregated`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<AggregatedAlarms>(fullUrl);
}

export function useAlarmLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/alarm/logs`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl);
}

export function useChargingLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/logs`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl);
}

export function useEquilizingDaily(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/daily`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<CalendarStateData>(fullUrl);
}

export function useEquilizingLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/logs`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl);
}

export function useSocLogs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/logs`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl);
}

export function useChargingMissed(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/charging/missed`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl);
}

export function useEquilizingMissed(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/equalizing/missed`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl);
}

export function useSocLowestSoc(
  type: 'fleet' | 'fleetgroup',
  id: string,
  startDate: Date,
  endDate: Date
): SWRResponse<FieldsAndNumberValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${id}/soc/lowestsoc`, {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  });

  return getUseSwr<FieldsAndNumberValues>(fullUrl);
}
