import React from 'react';
import { TreeCustomerFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { AddFleetData } from '../../../../services/fleetManipulation';
import AddEditFleet from '../common/addEditFleet/AddEditFleet';

type AddFleetProps = {
  parent: TreeCustomerFE | TreeFleetGroupFE;
  open: boolean;
  close: () => void;
  addFleet: (newFleet: AddFleetData) => Promise<boolean>;
};

export default function AddFleet({ parent, open, close, addFleet }: AddFleetProps): JSX.Element {
  return <AddEditFleet parent={parent} mode={'Add'} open={open} close={close} addFleet={addFleet} />;
}
