import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { BackendError } from '../../utils/BackendError';

type ErrorMessageProps = {
  messages: BackendError[];
  removeMessage: () => void;
};

export default function ErrorMessage(props: ErrorMessageProps): JSX.Element {
  const showError = props.messages.length > 0;

  if (showError === false) {
    return <></>;
  }

  return (
    <Alert
      onClose={props.removeMessage}
      severity='error'
      variant='filled'
      sx={{ width: '400px', color: '#D5D5D5', backgroundColor: '#941919' }}
    >
      <AlertTitle>Error</AlertTitle>
      An unexpected error occurred.
    </Alert>
  );
}
