import React from 'react';
import Typography from '@mui/material/Typography';

export default function HelpBoxArrange(): JSX.Element {
  return (
    <>
      <Typography variant='bigHeader'>Transfer</Typography>
      <Typography sx={{ marginTop: '1em' }}>
        You can transfer <i>devices</i>, <i>fleets</i>, <i>fleet groups</i> and <i>customers</i> from the left tree to the right. Select
        what you want to transfer on the left tree and the target customer, fleetgroup, or fleet on the right tree and press the transfer
        button. You can select multiple devices by <b>ctrl + click</b>.
      </Typography>
      <Typography variant='bigHeader' sx={{ marginTop: '2em' }}>
        Create - Edit - Delete
      </Typography>
      <Typography sx={{ marginTop: '1em' }}>
        In the left tree you can use the buttons on the bottom to create new, edit, and delete customers, fleet groups, and fleets.
      </Typography>
    </>
  );
}
