import React, { useState, useContext } from 'react';
import ComponentBase from '../../common/componentBase/ComponentBase';
import MonthAndYearPicker from '../../../common/monthAndYearPicker/MonthAndYearPicker';
import { utcToZonedTime } from 'date-fns-tz';
import Store from '../../../../store/Store';
import LineGraph from '../../common/LineGraph';
import { LineChartData } from '../../../../model/backendDataModels';

type FakeDailyRateGraphProps = {
  title: string;
  help?: React.ReactNode;
  fakeData: LineChartData;
};

export default function FakeDailyRateGraph({ title, help, fakeData }: FakeDailyRateGraphProps): JSX.Element {
  const { state } = useContext(Store);

  const date = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  return (
    <ComponentBase title={title} help={help}>
      <MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />
      <LineGraph data={fakeData} />
    </ComponentBase>
  );
}
