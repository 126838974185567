import { LngLatType } from '../components/arrange/addEditDeleteFooter/common/addEditFleet/AddEditFleetMap';

type MapBoxTileQueryFeature = {
  type: string;
  id: number;
  place_name: string;
};

type MapBoxTileQueryResponse = {
  type: string;
  features: MapBoxTileQueryFeature[];
};

export default async function reverseGeocoding(location: LngLatType): Promise<string | null> {
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${location.lng},${location.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
  const res = await fetch(url);
  if (!res.ok) {
    // eslint-disable-next-line no-console
    console.log(`Error getting reverse geocoding result - result: ${JSON.stringify(res)}`);
    return null;
  }
  const body = await res.json();
  if (body && body.type === 'FeatureCollection' && body.features && Array.isArray(body.features) && body.features.length > 0) {
    if (body.features[0].place_name) {
      const result = body as MapBoxTileQueryResponse;
      return result.features[0].place_name;
    }
  }
  // eslint-disable-next-line no-console
  console.log(`Error getting reverse geocoding result - body: ${JSON.stringify(body)}`);
  return null;
}
