import React, { useContext } from 'react';
import ComponentBase from './componentBase/ComponentBase';
import LineGraph from './LineGraph';
import LoadingIndicator from '../../common/LoadingIndicator';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../utils/BackendError';
import { LineChartData } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import BarGraph from './BarGraph';
import { Box } from '@mui/material';

type DailyRateGraphProps = {
  selector?: React.ReactNode;
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<LineChartData, BackendError>;
  title: string;
  startDate: Date;
  endDate: Date;
  help?: React.ReactNode;
  bar?: boolean;
};

export default function DailyRateGraph({ selector, dataHook, title, startDate, endDate, help, bar }: DailyRateGraphProps): JSX.Element {
  const appContext = useContext(AppContext);

  const showBarGraph = bar ?? false;

  const { data, error } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <ComponentBase title={title} help={help} sx={{ display: 'flex', flexDirection: 'column' }}>
      {selector}
      <Box sx={{ alignContent: 'center', flexGrow: 1 }}>
        {data && data.values && data.values.length > 0 && !error && (showBarGraph ? <BarGraph data={data} /> : <LineGraph data={data} />)}
        {!data && !error && <LoadingIndicator />}
      </Box>
    </ComponentBase>
  );
}
