import { compareItems, TreeItemFE } from '../../../model/frontendDataModels';

export function selectItem(
  item: TreeItemFE,
  selectedItems: TreeItemFE[],
  selectItems: (selectedItems: TreeItemFE[]) => void,
  event: React.MouseEvent
): void {
  const index = selectedItems.findIndex(selectedItem => compareItems(selectedItem, item));
  if (index === -1) {
    // not selected -> select it
    if (event.ctrlKey) {
      selectItems([item, ...selectedItems]);
    } else {
      selectItems([item]);
    }
  } else {
    // already selected -> unselect it
    // except if we already had multiple things selected, then only select this item
    if (event.ctrlKey) {
      selectItems(selectedItems.filter((item, i) => i !== index));
    } else if (selectedItems.length > 1) {
      selectItems([item]);
    } else {
      selectItems([]);
    }
  }
}
