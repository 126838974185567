import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../App';
import { TreeCustomerFE } from '../../../model/frontendDataModels';
import CustomerSelector from '../common/CustomerSelector';
import LoadingIndicator from '../../common/LoadingIndicator';

function getCustomerOrChildren(treeCustomer: TreeCustomerFE, ids: string[]): TreeCustomerFE[] {
  return ids.includes(treeCustomer.customerId)
    ? [
        {
          ...treeCustomer,
          fleetgroups: [],
          fleets: [],
          children: treeCustomer.children.flatMap(child => getCustomerOrChildren(child, ids)),
        },
      ]
    : treeCustomer.children.flatMap(child => getCustomerOrChildren(child, ids));
}

function treeKeepOnlyCustomerWithIds(topTreeCustomer: TreeCustomerFE, ids: string[]): TreeCustomerFE {
  const returnCustomer = {
    ...topTreeCustomer,
    fleetgroups: [],
    fleets: [],
    children: topTreeCustomer.children.flatMap(child => getCustomerOrChildren(child, ids)),
  };

  return returnCustomer;
}

type InvoiceSelectCustomerProps = {
  invoiceCustomerIds: string[];
  updateSelectedCustomer: (customer: TreeCustomerFE | undefined) => void;
};

export default function InvoiceSelectCustomer({ invoiceCustomerIds, updateSelectedCustomer }: InvoiceSelectCustomerProps): JSX.Element {
  const appContext = useContext(AppContext);

  const [selectedCustomer, setSelectedCustomer] = useState<TreeCustomerFE | undefined>();
  const [invoiceCustomerTree, setInvoiceCustomerTree] = useState<TreeCustomerFE | undefined>();

  useEffect(() => {
    if (appContext.rootTreeCustomer && !selectedCustomer && !invoiceCustomerTree) {
      const rootCustomer = treeKeepOnlyCustomerWithIds(appContext.rootTreeCustomer, invoiceCustomerIds);
      setInvoiceCustomerTree(rootCustomer);
      setSelectedCustomer(rootCustomer);
    }
  }, [appContext.rootTreeCustomer]);

  useEffect(() => {
    updateSelectedCustomer(selectedCustomer);
  }, [selectedCustomer]);

  if (invoiceCustomerTree) {
    return (
      <CustomerSelector
        rootTreeCustomer={invoiceCustomerTree}
        canSelectCustomer={(): boolean => true}
        selectedCustomers={selectedCustomer ? [selectedCustomer] : []}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        updateSelectCustomers={(customers: TreeCustomerFE[]): void => {
          if (customers.length > 0) {
            setSelectedCustomer(customers[0] as TreeCustomerFE);
          } else {
            setSelectedCustomer(undefined);
          }
        }}
      />
    );
  } else {
    return <LoadingIndicator />;
  }
}
