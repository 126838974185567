import React from 'react';
import { useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, BarElement, ChartOptions, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';

export type CategoriesAndValues = {
  categories: string[];
  values: number[];
};

type Props = {
  data: CategoriesAndValues;
};

export default function HistogramBarGraph({ data }: Props): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const theme = useTheme();

  const labelOptions: LabelOptions = {
    color: 'white',
    display: (context: Context): boolean => {
      const index = context.dataIndex;
      const value = context.dataset.data[index];
      if (value !== 0) {
        return true;
      }
      return false;
    },
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: labelOptions,
    },
    scales: {
      y: {
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
    },
  };

  const barData: ChartData<'bar'> = {
    labels: data.categories,
    datasets: [
      {
        backgroundColor: theme.palette.info.main,
        data: data.values,
      },
    ],
  };

  return <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />;
}
