import React, { useContext, useEffect } from 'react';
import ExpandableRow from './ExpandableRow';
import { TreeContext } from './SearchableTree';
import { selectItem } from './selectItem';
import {
  instanceOfTreeDeviceFE,
  instanceOfTreeFleetFE,
  instanceOfTreeGatewayFE,
  TreeFleetFE,
  TreeItemFE,
} from '../../../model/frontendDataModels';
import GatewayList from './gatewayList/GatewayList';
import {
  treeFleetOnlineStatus,
  treeFleetHasWarning,
  treeFleetGateway,
  treeFleetIncludesDevice,
  treeFleetIncludesGateway,
} from '../../../utils/treeFleetFunctions';
import DeviceList from './deviceList/DeviceList';

type Props = {
  treeFleet: TreeFleetFE;
  depth: number;
  unassigned: boolean;
};

export default function FleetTree({ treeFleet, depth, unassigned }: Props): JSX.Element | null {
  const treeContext = useContext(TreeContext);

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(treeFleet.fleetId)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (
          (instanceOfTreeDeviceFE(treeItem) && treeFleetIncludesDevice(treeFleet, treeItem)) ||
          (instanceOfTreeGatewayFE(treeItem) && treeFleetGateway(treeFleet, treeItem.mui))
        ) {
          treeContext.expandTree(treeFleet.fleetId);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label={treeFleet.fleetName}
      iconName={treeFleet.lat && treeFleet.lng ? 'fleet' : 'fleetnolocation'}
      depth={depth}
      selected={treeContext.selectedTreeItems.findIndex(item => instanceOfTreeFleetFE(item) && item.fleetId === treeFleet.fleetId) !== -1}
      select={
        treeContext.canSelect(treeFleet)
          ? (event: React.MouseEvent): void => selectItem(treeFleet, treeContext.selectedTreeItems, treeContext.selectTreeItems, event)
          : undefined
      }
      expanded={treeContext.expandedTreeItemIds.includes(treeFleet.fleetId)}
      expandTree={(): void => treeContext.expandTree(treeFleet.fleetId)}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];
        treeContext.selectedTreeItems.forEach(treeItem => {
          const hasDevice = instanceOfTreeDeviceFE(treeItem) && treeFleetIncludesDevice(treeFleet, treeItem);
          const hasGateway = instanceOfTreeGatewayFE(treeItem) && treeFleetIncludesGateway(treeFleet, treeItem);

          if (!hasDevice && !hasGateway) {
            newSelectedTreeItems.push(treeItem);
          }
        });

        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(treeFleet.fleetId);
      }}
      onlineStatus={treeFleetOnlineStatus(treeFleet)}
      hasWarning={treeFleetHasWarning(treeFleet)}
    >
      {treeContext.includeDevices && treeFleet.chargers.length > 0 && (
        <DeviceList
          name='Chargers'
          suffix='chargers'
          iconName='charger'
          fleetId={treeFleet.fleetId}
          deviceList={treeFleet.chargers}
          depth={depth + 1}
          unassigned={unassigned}
        />
      )}

      {treeContext.includeDevices && treeFleet.batteries.length > 0 && (
        <DeviceList
          name='Batteries'
          suffix='batteries'
          iconName='battery'
          fleetId={treeFleet.fleetId}
          deviceList={treeFleet.batteries}
          depth={depth + 1}
          unassigned={unassigned}
        />
      )}
      {treeContext.includeDevices && treeFleet.gateways.length > 0 && (
        <GatewayList fleetId={treeFleet.fleetId} gatewayList={treeFleet.gateways} depth={depth + 1} unassigned={unassigned} />
      )}
    </ExpandableRow>
  );
}
