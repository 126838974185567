import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingIndicator from './LoadingIndicator';
import DefaultButton from './DefaultButton';

type FormDialogProps = {
  open: boolean;
  close: (ok: boolean) => Promise<void>;
  title: string;
  headerText?: string;
  okButton?: string;
  okDisabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
};

export default function FormDialog({ open, close, title, headerText, okButton, okDisabled, size, children }: FormDialogProps): JSX.Element {
  const [sendingData, setSendingData] = useState(false);

  async function handleClose(ok: boolean): Promise<void> {
    setSendingData(true);
    await close(ok);
    setSendingData(false);
  }

  return (
    <Dialog
      open={open}
      onClose={(_, reason: string): void => {
        if (reason !== 'backdropClick') {
          handleClose(false);
        }
      }}
      maxWidth={size ? size : 'sm'}
      fullWidth
      PaperProps={{
        style: { display: 'flex', flexDirection: 'column', borderRadius: '10px' },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {headerText && <DialogContentText>{headerText}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {!sendingData && (
          <DefaultButton
            onClick={(): void => {
              handleClose(false);
            }}
          >
            Cancel
          </DefaultButton>
        )}
        {okButton && !sendingData && (
          <DefaultButton
            onClick={(): void => {
              handleClose(true);
            }}
            disabled={okDisabled}
          >
            {okButton}
          </DefaultButton>
        )}
        {sendingData && <LoadingIndicator />}
      </DialogActions>
    </Dialog>
  );
}
