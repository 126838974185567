import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

type InputComponentProps = {
  label: string;
  children: React.ReactNode;
};

export default function InputComponent({ label, children }: InputComponentProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant='columnHeader' sx={{ height: '24px' }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>{children}</Box>
    </Box>
  );
}
