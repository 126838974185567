import React from 'react';
import ComponentBase from './componentBase/ComponentBase';
import SortableList from './SortableList';

type DeviceListViewProps = {
  chargerCount: number;
  batteryCount: number;
  gatewayCount: number;
};

export default function DeviceListView({ chargerCount, batteryCount, gatewayCount }: DeviceListViewProps): JSX.Element {
  const title = 'Device List';
  const help = undefined;

  const headers = ['Type', 'Devices'];
  const data = [
    ['Chargers', chargerCount],
    ['Batteries', batteryCount],
    ['Gateways', gatewayCount],
  ];

  return (
    <ComponentBase title={title} help={help}>
      <SortableList data={data} headers={headers} widths={[0.5, 0.5]} sx={{ borderTop: '2px solid black' }} />
    </ComponentBase>
  );
}
