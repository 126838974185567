import { LngLatType } from '../components/arrange/addEditDeleteFooter/common/addEditFleet/AddEditFleetMap';

type MapBoxTileQueryTilequery = {
  distance: number;
  geometry: string;
  layer: string;
};

type MapBoxTileQueryProperties = {
  TZID: string;
  tilequery: MapBoxTileQueryTilequery;
};

type MapBoxTileQueryGeometry = {
  type: string;
  coordinates: LngLatType;
};

type MapBoxTileQueryFeature = {
  type: string;
  id: number;
  geometry: MapBoxTileQueryGeometry;
  properties: MapBoxTileQueryProperties;
};

type MapBoxTileQueryResponse = {
  type: string;
  features: MapBoxTileQueryFeature[];
};

export default async function getTimeZone(location: LngLatType): Promise<string | undefined> {
  const tileset = 'examples.4ze9z6tv';
  const url = `https://api.mapbox.com/v4/${tileset}/tilequery/${location.lng},${location.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`;
  const res = await fetch(url);
  if (!res.ok) {
    // eslint-disable-next-line no-console
    console.log(`Error getting timezone - result: ${JSON.stringify(res)}`);
    return undefined;
  }
  const body = await res.json();
  if (body && body.type === 'FeatureCollection' && body.features && Array.isArray(body.features) && body.features.length > 0) {
    if (body.features[0].properties && body.features[0].properties.TZID) {
      const result = body as MapBoxTileQueryResponse;
      return result.features[0].properties.TZID;
    }
  }
  // eslint-disable-next-line no-console
  console.log(`Error getting timezone - body: ${JSON.stringify(body)}`);
  return undefined;
}
