import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { MaintenanceMessage as MessageInfo } from '../../model/backendDataModels';

type MaintenanceMessageProps = {
  info: MessageInfo | null;
  removeMessage: () => void;
};

export default function MaintenanceMessage(props: MaintenanceMessageProps): JSX.Element {
  if (props.info === null) {
    return <></>;
  }

  return (
    <Alert
      onClose={(): void => props.removeMessage()}
      severity='info'
      variant='filled'
      sx={{ width: '400px', color: '#D5D5D5', backgroundColor: '#151E23' }}
    >
      <AlertTitle>{props.info.title}</AlertTitle>
      {props.info.message}
    </Alert>
  );
}
