import React, { useState } from 'react';
import InputItem from '../../../common/InputItem';
import InputLongText from '../../../common/InputLongText';
import AddEditDialog from '../common/AddEditDialog';
import Box from '@mui/material/Box';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { AddFleetGroupData } from '../../../../services/fleetGroupManipulation';
import { instanceOfTreeCustomerFE, TreeCustomerFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';

type AddFleetGroupProps = {
  parent: TreeCustomerFE | TreeFleetGroupFE;
  open: boolean;
  close: () => void;
  addNewFleetGroup: (newFleetGroup: AddFleetGroupData) => Promise<void>;
};

export default function AddFleetGroup({ parent, open, close, addNewFleetGroup }: AddFleetGroupProps): JSX.Element | null {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  return (
    <AddEditDialog
      title='fleet group'
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          await addNewFleetGroup({
            name,
            description,
            parentId: instanceOfTreeCustomerFE(parent) ? parent.customerId : parent.fleetgroupId,
            isParentFleetgroup: !instanceOfTreeCustomerFE(parent),
          });
        }
        setName('');
        setDescription('');
        close();
      }}
      mode={'Add'}
      size='sm'
      parentName={instanceOfTreeCustomerFE(parent) ? parent.customerName : parent.fleetgroupName}
    >
      {parent && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <InputItem label='Name' value={name} updateValue={setName} />
          <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
        </Box>
      )}
      {!parent && <LoadingIndicator />}
    </AddEditDialog>
  );
}
