import React, { ReactNode } from 'react';
import InputComponent from '../../../../common/InputComponent';
import ListItemText from '@mui/material/ListItemText';
import { Checkbox, MenuItem, Select } from '@mui/material';

type AxisOptionsSelectProps = {
  label: string;
  unique: string;
  axisOptions: string[];
  selectedOptions: string[];
  updateSelectedOptions: (axisOptions: string[]) => void;
};

export default function AxisOptionsSelect({
  label,
  unique,
  axisOptions,
  selectedOptions,
  updateSelectedOptions,
}: AxisOptionsSelectProps): JSX.Element {
  return (
    <InputComponent label={label}>
      <Select
        sx={{ width: '200px' }}
        labelId={unique}
        id={unique}
        multiple
        displayEmpty
        value={selectedOptions}
        onChange={(event): void => {
          const {
            target: { value },
          } = event;
          updateSelectedOptions(typeof value === 'string' ? value.split(',') : value);
        }}
        renderValue={(selected): ReactNode => {
          if (selected.length === 0) {
            return <em>None</em>;
          }

          return <em>{selected.length} selected</em>;
        }}
      >
        {axisOptions &&
          axisOptions.map(opt => {
            return (
              <MenuItem key={opt} value={opt}>
                <Checkbox checked={selectedOptions.indexOf(opt) > -1} />
                <ListItemText primary={opt} />
              </MenuItem>
            );
          })}
      </Select>
    </InputComponent>
  );
}
