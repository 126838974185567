import React, { useState, useContext } from 'react';
import { WIDTH_OF_INFO_COMPONENT, COMPONENT_PADDING } from '../../../themes/theme';
import SelectedFleetOrGroupHeader from '../common/selectedFleetOrGroupHeader/SelectedFleetOrGroupHeader';
import FleetOrGroupMonitoring from '../common/fleetOrGroupMonitoring/FleetOrGroupMonitoring';
import InactiveFleetView from './InactiveFleetView';
import ActivateFleetPopup from '../common/ActivateFleetPopup';
import DeactivateFleetPopup from './DeactivateFleetPopup';
import LoadingIndicator from '../../common/LoadingIndicator';
import { treeCustomerTreeFleetInfo, treeCustomerUpdateTreeFleet } from '../../../utils/treeCustomerFunctions';
import { TreeFleetFE } from '../../../model/frontendDataModels';
import { activateFleet, deactivateFleet } from '../../../services/fleetManipulation';
import { LoginContext } from '../../../Login';
import { AppContext } from '../../../App';
import { BackendError } from '../../../utils/BackendError';
import { useUser } from '../../../dataHooks/adminHooks';
import { Box, useTheme } from '@mui/material';

type SelectedFleetViewProps = {
  selectedFleetId: string;
};

export default function SelectedFleetView({ selectedFleetId }: SelectedFleetViewProps): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const { data, error } = useUser();
  if (error) {
    appContext.addBackendError(error);
  }

  const treeFleetInfo = appContext.rootTreeCustomer ? treeCustomerTreeFleetInfo(appContext.rootTreeCustomer, selectedFleetId) : undefined;

  if (!treeFleetInfo) {
    return <LoadingIndicator />;
  }

  async function update(active: boolean): Promise<void> {
    if (!treeFleetInfo || !appContext.rootTreeCustomer) {
      return;
    }
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (active) {
      const result = await activateFleet(treeFleetInfo.treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleet: TreeFleetFE = {
          ...treeFleetInfo.treeFleet,
          activated: true,
        };
        appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
      }
    } else {
      const result = await deactivateFleet(treeFleetInfo.treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleet: TreeFleetFE = {
          ...treeFleetInfo.treeFleet,
          activated: false,
        };
        appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
      }
    }
  }

  const [expanded, setExpanded] = useState(false);
  const [activateFleetPopupOpen, setActivateFleetPopupOpen] = useState(false);
  const [deactivateFleetPopupOpen, setDeactivateFleetPopupOpen] = useState(false);

  async function toggleActivated(): Promise<void> {
    if (treeFleetInfo?.treeFleet.activated) {
      setDeactivateFleetPopupOpen(true);
    } else {
      setActivateFleetPopupOpen(true);
    }
  }

  const width = expanded ? 2 * WIDTH_OF_INFO_COMPONENT + 2 * COMPONENT_PADDING : WIDTH_OF_INFO_COMPONENT + COMPONENT_PADDING;

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        height: '100%',
        borderRight: '2px solid black',
        overflowX: 'auto',
        flexShrink: 0,
        width,
        minWidth: width,
        maxWidth: width,
      }}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      <SelectedFleetOrGroupHeader
        fleetOrFleetGroup={treeFleetInfo.treeFleet}
        treeFleetInfo={treeFleetInfo}
        expanded={expanded}
        changeExpanded={setExpanded}
        activated={treeFleetInfo.treeFleet.activated}
        toggleActivated={data && !data.roleId.toString().startsWith('2') ? toggleActivated : undefined}
      />
      {treeFleetInfo.treeFleet.activated ? (
        <FleetOrGroupMonitoring selectedFleetOrFleetGroup={treeFleetInfo.treeFleet} expanded={expanded} />
      ) : (
        <InactiveFleetView
          selectedFleet={treeFleetInfo.treeFleet}
          admin={data ? !data.roleId.toString().startsWith('2') : false}
          openActivateFleetPopup={(): void => setActivateFleetPopupOpen(true)}
        />
      )}

      {data && !data.roleId.toString().startsWith('2') && (
        <>
          <ActivateFleetPopup
            treeFleet={treeFleetInfo.treeFleet}
            open={activateFleetPopupOpen}
            close={async (ok: boolean): Promise<void> => {
              if (ok) {
                await update(true);
              }
              setActivateFleetPopupOpen(false);
            }}
          />
          <DeactivateFleetPopup
            open={deactivateFleetPopupOpen}
            close={async (ok: boolean): Promise<void> => {
              if (ok) {
                await update(false);
              }
              setDeactivateFleetPopupOpen(false);
            }}
          />
        </>
      )}
      {!treeFleetInfo.treeFleet.activated && (
        <Box
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 2,
            backdropFilter: 'blur(6px)',
          }}
        ></Box>
      )}
    </Box>
  );
}
