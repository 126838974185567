import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest, sendPostRequestWithReturnType } from '../utils/dataSenders';

export type AddCustomerData = {
  name: string;
  description: string;
  parentId: string;
  timezone: string;
};

type AddCustomerReturnType = {
  customerId: string;
};

export type EditCustomerData = {
  name: string;
  description: string;
  timezone: string;
};

export type CustomerChangeParentData = {
  parentId: string;
};

export async function addCustomer(
  data: AddCustomerData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<AddCustomerReturnType | undefined> {
  try {
    const url = '/customer/addNew';
    return await sendPostRequestWithReturnType<AddCustomerData, AddCustomerReturnType>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return undefined;
  }
}

export async function editCustomer(
  id: string,
  data: EditCustomerData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/customer/${id}/edit`;
    return await sendPostRequest<EditCustomerData>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deleteCustomer(id: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = `/customer/${id}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function customerChangeParent(
  id: string,
  data: CustomerChangeParentData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/customer/${id}/changeParent`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
