import React from 'react';
import Box from '@mui/material/Box';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ChartOptions,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { LineChartData } from '../../../model/backendDataModels';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';

type Props = {
  data: LineChartData;
};

export default function LineGraph({ data }: Props): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

  const theme = useTheme();

  const minPoint = data.values.reduce(
    (previousValue: number, currentValue: number | null) => (currentValue ? Math.min(previousValue, currentValue) : previousValue),
    Number.MAX_VALUE
  );
  const maxPoint = data.values.reduce(
    (previousValue: number, currentValue: number | null) => (currentValue ? Math.max(previousValue, currentValue) : previousValue),
    Number.MIN_VALUE
  );

  const labels = new Array(data.xmax).fill('').map((value, index) => index + 1);
  const lineData = {
    labels,
    datasets: [
      {
        data: data.values,
        borderColor: theme.palette.info.main,
        tension: 0.1,
      },
    ],
  };

  const minIndex = data.values.findIndex(v => v === minPoint);
  const maxIndex = data.values.findIndex(v => v === maxPoint);

  const labelOptions: LabelOptions = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formatter: (value: any, context: Context): string => {
      const index = context.dataIndex;
      if (index === maxIndex) {
        return 'max';
      }
      if (index === minIndex) {
        return 'min';
      }
      return '';
    },
    color: theme.palette.action.active,
    display: (context: Context): boolean => {
      const index = context.dataIndex;
      if (index === maxIndex || index === minIndex) {
        return true;
      }
      return false;
    },
    align: (context: Context) => {
      if (context.dataIndex === maxIndex) {
        return 'top';
      }
      return 'bottom';
    },
    offset: 10,
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.action.active,
    borderWidth: 1,
    padding: { left: 2, right: 2, top: 1, bottom: 0 },
    borderRadius: 4,
  };

  const lineOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: labelOptions,
    },
    scales: {
      y: {
        min: data.ymin,
        max: data.ymax,
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          callback: function (tickValue: number | string): string {
            return `${tickValue} ${data.yLabel}`;
          },
          color: theme.palette.action.active,
        },
      },
      x: {
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          callback: function (tickValue: number | string, index: number): string | undefined {
            return index + 1 === data.xmin || (index + 1 === data.xmax && index + 1 !== 31) || (index + 1) % 5 === 0
              ? (index + 1).toString()
              : undefined;
          },
          color: theme.palette.action.active,
        },
      },
    },
    elements: {
      line: {
        fill: false,
      },
      point: {
        radius: (context: ScriptableContext<'line'>): number => {
          const index = context.dataIndex;
          if (index === maxIndex || index === minIndex) {
            return 5;
          }
          return 1;
        },
        hoverRadius: 3,
        hitRadius: 3,
        backgroundColor: (context: ScriptableContext<'line'>): string => {
          const index = context.dataIndex;
          if (index === maxIndex) {
            return theme.palette.error.main;
          }
          return theme.palette.success.main;
        },
      },
    },
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: '300px' }}>
      <Line options={lineOptions} plugins={[ChartDataLabels]} data={lineData} />
    </Box>
  );
}
