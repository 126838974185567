import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { SxProps } from '../../../common/commonTypes';

type DeviceHeaderInfoProps = {
  label: string;
  text: React.ReactNode;
  textColor?: 'success' | 'error' | 'warning' | 'info' | 'inherit';
};

export default function DeviceHeaderInfo({ label, text, textColor = 'inherit', sx }: DeviceHeaderInfoProps & SxProps): JSX.Element {
  const theme = useTheme();
  let color = 'inherit';
  if (textColor === 'success') {
    color = theme.palette.success.main;
  } else if (textColor === 'error') {
    color = theme.palette.error.main;
  } else if (textColor === 'warning') {
    color = theme.palette.warning.main;
  } else if (textColor === 'info') {
    color = theme.palette.info.main;
  }

  return (
    <Box sx={{ marginLeft: '22px', ...sx, display: 'flex', flexDirection: 'column', maxWidth: '450px' }}>
      <Typography sx={{ fontStyle: 'italic' }} variant='columnHeader' noWrap>
        {label}
      </Typography>
      <Typography sx={{ color: color }} variant='sleekHeader'>
        {text}
      </Typography>
    </Box>
  );
}
