import React from 'react';
import Box from '@mui/material/Box';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import { Grid, Typography } from '@mui/material';
import FakeOverviewStatus from './FakeOverviewStatus';
import FakeDailyRateGraph from './FakeDailyRateGraph';
import FakeDailyQuantityCalendar from './FakeDailyQuantityCalendar';
import { LineChartData } from '../../../../model/backendDataModels';

const dailyQuantity = [
  66, 29, 83, 11, 56, 2, 20, 43, 58, 34, 53, 63, 26, 90, 15, 75, 10, 38, 66, 97, 86, 34, 5, 27, 46, 76, 92, 21, 47, 88, 59,
];

const dailyRates: LineChartData = {
  yLabel: '%',
  yLabelDisplay: true,
  xLabel: 'Date',
  xLabelDisplay: true,
  xmin: 0,
  xmax: dailyQuantity.length + 1,
  ymin: 0,
  ymax: 120,
  values: [32, 35, 44, 49, 40, 35, 30, 48, 56, 59, 63, 72, 82, 70, 65, 53, 51, 48, 35, 30, 32, 33, 40, 42, 45, 43, 40, 38, 33, 28],
};

export default function FakeOverview(): JSX.Element {
  return (
    <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
      <FakeOverviewStatus />
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        History
      </Typography>
      <Grid container spacing={`${COMPONENT_PADDING}px`}>
        <FakeDailyQuantityCalendar title='Daily Connects' fakeData={dailyQuantity} />
        <FakeDailyRateGraph title='Used Grid Energy' fakeData={dailyRates} />
      </Grid>
    </Box>
  );
}
