import {
  compareItems,
  instanceOfTreeGatewayFE,
  instanceOfTreeGatewayUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';

export function selectGateway(
  gateway: TreeGatewayFE | TreeGatewayUnassignedFE,
  selectedItems: TreeItemFE[],
  selectItems: (selectedItems: TreeItemFE[]) => void,
  gatewayList: (TreeGatewayFE | TreeGatewayUnassignedFE)[],
  event: React.MouseEvent
): void {
  const index = selectedItems.findIndex(selectedItem => compareItems(selectedItem, gateway));
  if (index === -1) {
    // not selected -> select it
    if (event.ctrlKey) {
      selectItems([gateway, ...selectedItems]);
    } else if (event.shiftKey) {
      // Only select multiple items if exactly one is already selected
      if (
        selectedItems.length === 1 &&
        (instanceOfTreeGatewayFE(selectedItems[0]) || instanceOfTreeGatewayUnassignedFE(selectedItems[0]))
      ) {
        // Also, make sure the previous selected is part of the current device list
        const prevIndex = gatewayList.findIndex(listItem => compareItems(listItem, selectedItems[0]));
        const thisIndex = gatewayList.findIndex(listItem => compareItems(listItem, gateway));
        if (prevIndex >= 0 && thisIndex >= 0) {
          const startIndex = Math.min(prevIndex, thisIndex);
          const endIndex = Math.max(prevIndex, thisIndex) + 1;
          selectItems(gatewayList.slice(startIndex, endIndex));
        }
      }
    } else {
      selectItems([gateway]);
    }
  } else {
    // already selected -> unselect it
    // except if we already had multiple things selected, then only select this item
    if (event.ctrlKey) {
      selectItems(selectedItems.filter((item, i) => i !== index));
    } else if (event.shiftKey) {
      // Do nothing if shift is pressed
    } else if (selectedItems.length > 1) {
      selectItems([gateway]);
    } else {
      selectItems([]);
    }
  }
}
