import * as React from 'react';
import { Box, Typography } from '@mui/material';

export default function CalendarWeekdaysHeader(): JSX.Element {
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      {daysOfWeek.map(day => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: 40,
            margin: '18px 5px 9px',
          }}
          key={day}
        >
          <Typography variant='columnHeader' sx={{ textTransform: 'capitalize' }}>
            {day}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
