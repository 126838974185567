import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { COMPONENT_PADDING } from '../../themes/theme';
import { ClickAwayListener, Fab, Zoom } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import UserSetting from './UserSetting';

export default function UserSettingFab(): JSX.Element {
  const [showPopup, setShowPopup] = useState(false);

  return (
    <ClickAwayListener onClickAway={(): void => setShowPopup(false)}>
      <Box>
        <Zoom in={showPopup}>
          <Box sx={{ paddingBottom: `${COMPONENT_PADDING}px`, justifyContent: 'end', display: showPopup ? 'flex' : 'none' }}>
            <UserSetting />
          </Box>
        </Zoom>
        <Box sx={{ justifyContent: 'end', display: 'flex' }}>
          <Fab style={{ color: 'white', background: '#7297AC' }} aria-label='Profile' onClick={(): void => setShowPopup(!showPopup)}>
            {showPopup ? <CloseIcon /> : <SettingsIcon />}
          </Fab>
        </Box>
      </Box>
    </ClickAwayListener>
  );
}
