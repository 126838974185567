import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../common/Icon';
import TabbedPage from '../common/TabbedPage';
import UserHandlingTab from './userHandlingTab/UserHandlingTab';
import InvoiceDetailsTab from './invoiceDetailsTab/InvoiceDetailsTab';
import { COMPONENT_PADDING } from '../../themes/theme';
import { useUser } from '../../dataHooks/adminHooks';
import { AppContext } from '../../App';
import LoadingIndicator from '../common/LoadingIndicator';
import { ROLE_ID_INVOICE_ADMIN, ROLE_ID_SYSTEM_ADMIN } from '../../model/backendDataModels';
import RegisterGetConnectedTab from './RegisterGetConnectedTab';

type Props = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function Admin({ changeHelpBox }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const { data, error } = useUser();
  if (error) {
    appContext.addBackendError(error);
  }

  useEffect(() => {
    changeHelpBox(undefined);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        flexShrink: 0,
        flexGrow: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: `17px ${COMPONENT_PADDING}px` }}>
        <Typography
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            alignItems: 'center',
          }}
          variant='sectionHeader'
        >
          <Icon name='menu_user' fontSize='medium' />
          <Box sx={{ marginLeft: '1em' }}>Admin</Box>
        </Typography>
      </Box>
      {!data && !error && <LoadingIndicator />}
      {data && !error && data.roleId !== ROLE_ID_SYSTEM_ADMIN && data.roleId !== ROLE_ID_INVOICE_ADMIN && (
        <TabbedPage
          pages={[
            {
              name: 'User Handling',
              body: <UserHandlingTab />,
            },
            {
              name: 'Register GET Connected',
              body: <RegisterGetConnectedTab />,
            },
          ]}
        />
      )}
      {data && !error && (data.roleId === ROLE_ID_SYSTEM_ADMIN || data.roleId === ROLE_ID_INVOICE_ADMIN) && (
        <TabbedPage
          pages={[
            {
              name: 'User Handling',
              body: <UserHandlingTab />,
            },
            { name: 'Invoice Details', body: <InvoiceDetailsTab roleId={data.roleId} /> },
            {
              name: 'Register GET Connected',
              body: <RegisterGetConnectedTab />,
            },
          ]}
        />
      )}
    </Box>
  );
}
