import React from 'react';
import { instanceOfTreeFleetFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { treeFleetBatteryCount, treeFleetChargerCount, treeFleetGatewayCount } from '../../../../utils/treeFleetFunctions';
import FleetOrGroupOverviewInfo from '../FleetOrGroupOverviewInfo';

function getChargerCountFromFleetGroup(fleetGroup: TreeFleetGroupFE): number {
  let count = 0;

  for (const children of fleetGroup.fleetgroups) {
    count += getChargerCountFromFleetGroup(children);
  }

  return count + fleetGroup.fleets.reduce((partialSum, fleet) => partialSum + treeFleetChargerCount(fleet), 0);
}

function getBatteryCountFromFleetGroup(fleetGroup: TreeFleetGroupFE): number {
  let count = 0;

  for (const children of fleetGroup.fleetgroups) {
    count += getBatteryCountFromFleetGroup(children);
  }

  return count + fleetGroup.fleets.reduce((partialSum, fleet) => partialSum + treeFleetBatteryCount(fleet), 0);
}

function getGatewayCountFromFleetGroup(fleetGroup: TreeFleetGroupFE): number {
  let count = 0;

  for (const children of fleetGroup.fleetgroups) {
    count += getGatewayCountFromFleetGroup(children);
  }

  return count + fleetGroup.fleets.reduce((partialSum, fleet) => partialSum + treeFleetGatewayCount(fleet), 0);
}

type OverviewTabProps = {
  selectedFleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
};

export default function OverviewTab({ selectedFleetOrFleetGroup }: OverviewTabProps): JSX.Element {
  const chargerCount = instanceOfTreeFleetFE(selectedFleetOrFleetGroup)
    ? treeFleetChargerCount(selectedFleetOrFleetGroup)
    : getChargerCountFromFleetGroup(selectedFleetOrFleetGroup);

  const batteryCount = instanceOfTreeFleetFE(selectedFleetOrFleetGroup)
    ? treeFleetBatteryCount(selectedFleetOrFleetGroup)
    : getBatteryCountFromFleetGroup(selectedFleetOrFleetGroup);

  const gatewayCount = instanceOfTreeFleetFE(selectedFleetOrFleetGroup)
    ? treeFleetGatewayCount(selectedFleetOrFleetGroup)
    : getGatewayCountFromFleetGroup(selectedFleetOrFleetGroup);

  return (
    <FleetOrGroupOverviewInfo
      chargerCount={chargerCount}
      batteryCount={batteryCount}
      gatewayCount={gatewayCount}
      selectedFleetOrFleetGroup={selectedFleetOrFleetGroup}
    />
  );
}
