import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import DailyStateCalendar from './common/dailyStateCalendar/DailyStateCalendar';
import DailyRateGraph from '../DailyRateGraph';
import DeviceMissedList from './common/deviceMissedList/DeviceMissedList';
import EventLog from './common/EventLog';
import StateCalendarHelpBox from './common/dailyStateCalendar/StateCalendarHelpBox';
import DeletableTabTitle from './common/DeletableTabTitle';
import { instanceOfTreeFleetGroupFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { CalendarStateData, FieldsAndNumberValues, FieldsAndValues, LineChartData } from '../../../../model/backendDataModels';
import {
  useChargingCompletionDaily,
  useChargingCompletionSum,
  useChargingLogs,
  useChargingMissed,
} from '../../../../dataHooks/fleetAndFleetgroupHooks';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../utils/BackendError';
import Box from '@mui/material/Box';
import Store from '../../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';

type ChargingTabProps = {
  selectedFleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
  deleteTab: () => Promise<void>;
};

export default function ChargingTab({ selectedFleetOrFleetGroup, deleteTab }: ChargingTabProps): JSX.Element {
  const { state } = useContext(Store);

  const date = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month');
  const today = moment(date);
  const correctedEndDate = moment(endDate).isAfter(today) ? today : endDate;

  function getChargingCompletionDailyDataHook(startDate: Date, endDate: Date): SWRResponse<CalendarStateData, BackendError> {
    return useChargingCompletionDaily(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  function getChargingCompletionSumDataHook(startDate: Date, endDate: Date): SWRResponse<LineChartData, BackendError> {
    return useChargingCompletionSum(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  function getChargingLogsDataHook(startDate: Date, endDate: Date): SWRResponse<FieldsAndValues, BackendError> {
    return useChargingLogs(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  function getChargingMissedDataHook(startDate: Date, endDate: Date): SWRResponse<FieldsAndNumberValues, BackendError> {
    return useChargingMissed(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <DeletableTabTitle title={'Charging'} deleteTab={deleteTab} />
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <DailyStateCalendar
            dataHook={getChargingCompletionDailyDataHook}
            title='Daily Charging Completion'
            help={
              <StateCalendarHelpBox
                success='No missed charge opportunities'
                warning='Less than 10% missed charge opportunities'
                error='More than 10% missed charge opportunities'
              />
            }
            startDate={startDate.toDate()}
            endDate={endDate.toDate()}
            onMonthSelected={(month: number): void => {
              setMonth(month);
            }}
            onYearSelected={(year: number): void => {
              setYear(year);
            }}
          />
          <DailyRateGraph
            dataHook={getChargingCompletionSumDataHook}
            title='Charging Routine Completion'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
          />
          <EventLog
            dataHook={getChargingLogsDataHook}
            title='Charging Log'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
          />
          <DeviceMissedList
            dataHook={getChargingMissedDataHook}
            title='Missed Charging Summary'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
