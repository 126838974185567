import React from 'react';
import { MsalProvider } from '@azure/msal-react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import Login from './Login';

const clientId = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID || '';
const redirectUri = process.env.REACT_APP_REDIRECT_URI;
const authority = `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_DIRECTORY_ID}` || '';

const configuration: Configuration = {
  auth: {
    authority,
    clientId,
    redirectUri,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const pca = new PublicClientApplication(configuration);

function Authentication(): JSX.Element {
  return (
    <MsalProvider instance={pca}>
      <Login />
    </MsalProvider>
  );
}

export default Authentication;
