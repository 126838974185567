import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Icon from '../../../common/Icon';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';
import FleetOrGroupHeaderInfo from './FleetOrGroupHeaderInfo';
import { instanceOfTreeFleetFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { treeFleetDeviceCount, TreeFleetInfo } from '../../../../utils/treeFleetFunctions';
import { treeFleetGroupDeviceCount } from '../../../../utils/treeFleetGroupFunctions';
import { LngLatType } from '../../../arrange/addEditDeleteFooter/common/addEditFleet/AddEditFleetMap';
import reverseGeocoding from '../../../../services/reverseGeocoding';

type FleetInfoProps = {
  treeFleetInfo: TreeFleetInfo;
};

function FleetInfo({ treeFleetInfo }: FleetInfoProps): JSX.Element {
  const [address, setAddress] = useState<string | null>(null);

  async function updateAddress(location: LngLatType): Promise<void> {
    const ad = await reverseGeocoding(location);
    setAddress(ad);
  }

  useEffect(() => {
    if (treeFleetInfo.treeFleet.lat && treeFleetInfo.treeFleet.lng) {
      updateAddress({ lat: treeFleetInfo.treeFleet.lat, lng: treeFleetInfo.treeFleet.lng });
    }
  }, [treeFleetInfo]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      <Typography variant='bigHeader'>{treeFleetInfo.treeFleet.fleetName}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px', marginBottom: '31px' }}>
        <FleetOrGroupHeaderInfo label='Location' text={address || 'N/A'} />
        {treeFleetInfo.parentFleetGroup && (
          <FleetOrGroupHeaderInfo label='Fleet Group' text={treeFleetInfo.parentFleetGroup.fleetgroupName} />
        )}
        {treeFleetInfo.parentCustomer && <FleetOrGroupHeaderInfo label='Customer' text={treeFleetInfo.parentCustomer.customerName} />}
        <FleetOrGroupHeaderInfo label='Total Devices' text={treeFleetDeviceCount(treeFleetInfo.treeFleet)} />
      </Box>
    </Box>
  );
}

type FleetGroupInfoProps = {
  treeFleetGroup: TreeFleetGroupFE;
};

function FleetGroupInfo({ treeFleetGroup }: FleetGroupInfoProps): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
      <Typography variant='bigHeader'>{treeFleetGroup.fleetgroupName}</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px', marginBottom: '31px' }}>
        {/*treeFleetGroup && <FleetOrGroupHeaderInfo label='Fleet Group' text={'Not implemented'} />*/}
        {/*!parentName.isParentAFleetgroup && <FleetOrGroupHeaderInfo label='Customer' text={fleetGroup.parentName} />*/}
        <FleetOrGroupHeaderInfo label='Total Devices' text={treeFleetGroupDeviceCount(treeFleetGroup)} />
      </Box>
    </Box>
  );
}

type SelectedFleetOrGroupHeaderProps = {
  fleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
  treeFleetInfo?: TreeFleetInfo;
  expanded: boolean;
  changeExpanded: (expanded: boolean) => void;
  activated?: boolean;
  toggleActivated?: () => void;
};

export default function SelectedFleetOrGroupHeader({
  fleetOrFleetGroup,
  treeFleetInfo,
  expanded,
  changeExpanded,
  activated,
  toggleActivated,
}: SelectedFleetOrGroupHeaderProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', paddingLeft: '24px' }}>
      <Box
        sx={{
          minHeight: '65px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Typography
          variant='sectionHeader'
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Icon name={instanceOfTreeFleetFE(fleetOrFleetGroup) ? 'fleet' : 'fleetgroup'} />
          <Box sx={{ marginLeft: '1em' }}>{instanceOfTreeFleetFE(fleetOrFleetGroup) ? 'Fleet' : 'Fleet Group'}</Box>
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <ExpandViewSwitch wideMode={expanded} onChange={(expanded): void => changeExpanded(expanded)} />
        </Box>
      </Box>
      {treeFleetInfo ? (
        <FleetInfo treeFleetInfo={treeFleetInfo} />
      ) : (
        <FleetGroupInfo treeFleetGroup={fleetOrFleetGroup as TreeFleetGroupFE} />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {treeFleetInfo && toggleActivated && (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontStyle: 'italic', textAlign: 'center' }} variant='columnHeader'>
              {treeFleetInfo.treeFleet.activated ? 'Active' : 'Inactive'}
            </Typography>
            <Switch color='info' checked={activated} onChange={(): void => toggleActivated()} sx={{ justifySelf: 'end' }} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
