export function sortData<T>(data: T[][], sortCol: number | undefined, sortAsc: boolean | undefined): T[][] {
  if (sortCol === undefined || sortAsc === undefined) {
    return data;
  }
  return data.sort((a: T[], b: T[]): number => {
    if (a[sortCol] < b[sortCol]) {
      return sortAsc ? -1 : 1;
    }
    if (a[sortCol] > b[sortCol]) {
      return sortAsc ? 1 : -1;
    }
    return 0;
  });
}
