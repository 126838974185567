import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

type FormDialogProps = {
  open: boolean;
  close: () => void;
  title: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
};

export default function InfoDialog({ open, close, title, size, children }: FormDialogProps): JSX.Element {
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={size ? size : false}
      PaperProps={{
        style: { display: 'flex', flexDirection: 'column', borderRadius: '10px' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Typography variant='graphHeader'>{title}</Typography>
          <Box sx={{ marginBottom: '18px', cursor: 'pointer' }} onClick={close}>
            X
          </Box>
        </Box>
        {children}
      </Box>
    </Dialog>
  );
}
