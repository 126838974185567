import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import {
  BluOverviewStatus,
  BmsOverviewStatus,
  BmuOverviewStatus,
  ChargerOverviewStatus,
  instanceOfBmsOverviewStatus,
  instanceOfBmuOverviewStatus,
} from '../../../../model/backendDataModels';
import TopRightHeader from './TopRightHeader';
import DeviceType from './DeviceType';
import IdentifierAndFleet from './IdentifierAndFleet';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import { SWRResponse } from 'swr';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { EmptyDeviceContext } from '../SelectedDeviceView';
import Identifier from './Identifier';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';

type Props = {
  selectedDevice: TreeDeviceFE;
  dataHook: (
    mui: string
  ) =>
    | SWRResponse<BmsOverviewStatus, BackendError>
    | SWRResponse<BmuOverviewStatus, BackendError>
    | SWRResponse<BluOverviewStatus, BackendError>
    | SWRResponse<ChargerOverviewStatus, BackendError>;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
};

export default function CommonHeader({ selectedDevice, dataHook, onFullscreenChanged, fullscreen }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const emptyDeviceContext = useContext(EmptyDeviceContext);
  const { data: status, error } = dataHook(selectedDevice.mui);
  if (error) {
    appContext.addBackendError(error);
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {status && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '3px' }}>
            <DeviceType status={status} type={selectedDevice.productCategory} />
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {emptyDeviceContext.isInitializedDevice && emptyDeviceContext.isActiveFleet && (
                <IdentifierAndFleet
                  identifierType={selectedDevice.productCategory === 'bms' ? 'Serial Number' : 'Device ID'}
                  identifier={instanceOfBmsOverviewStatus(status) ? status.serialNumber : status.deviceId}
                  fleetName={status.fleet}
                />
              )}
              {emptyDeviceContext.isInitializedDevice && emptyDeviceContext.isActiveFleet && instanceOfBmuOverviewStatus(status) && (
                <Identifier identifierType={'Battery Tag'} identifier={selectedDevice.deviceTag} />
              )}
            </Box>
          </Box>

          <Box sx={{ marginTop: '20px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '10px' }}>
              <ExpandViewSwitch wideMode={fullscreen} onChange={onFullscreenChanged} />
            </Box>

            <Box sx={{ marginRight: '30px' }}>
              {emptyDeviceContext.isInitializedDevice && emptyDeviceContext.isActiveFleet && <TopRightHeader status={status} />}
            </Box>
          </Box>
        </>
      )}
      {!status && <LoadingIndicator sx={{ justifyContent: 'center', marginRight: '150px' }} />}
    </Box>
  );
}
