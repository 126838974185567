import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoadingIndicator from '../../../../common/LoadingIndicator';

type DeletableTabTitleProps = {
  title: string;
  deleteTab: () => Promise<void>;
};

export default function DeletableTabTitle({ title, deleteTab }: DeletableTabTitleProps): JSX.Element {
  const [deletingTab, setdeletingTab] = useState<boolean>(false);

  async function deleteTabLocal(): Promise<void> {
    setdeletingTab(true);
    await deleteTab();
    setdeletingTab(false);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Typography variant='bigHeader' component='div'>
        {title}
      </Typography>
      {deletingTab && <LoadingIndicator />}
      {!deletingTab && (
        <IconButton
          aria-label='delete'
          color='info'
          onClick={(): void => {
            deleteTabLocal();
          }}
        >
          <DeleteForeverIcon />
        </IconButton>
      )}
    </Box>
  );
}
