import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { ListTabs } from '../../../../../model/backendDataModels';
import Routine from './Routine';

type Props = {
  listTabs: ListTabs;
  updateEqualizing: (active: boolean, equalizingCycles: number | null) => Promise<void>;
};

export default function Equalizing({ listTabs, updateEqualizing }: Props): JSX.Element {
  const [equalizingPerWeek, setEqualizingPerWeek] = useState<number>(listTabs.equalizingCycles || 1);

  return (
    <Routine
      text='Number of equalizing cycles per battery and week.'
      buttonText='Add equalizing routine'
      onClick={(): void => {
        updateEqualizing(true, equalizingPerWeek);
      }}
      buttonDisabled={listTabs.equalizing}
    >
      <TextField
        type='number'
        sx={{ bgcolor: '#232C30', borderRadius: 2, alignItems: 'center' }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 1, max: 14 }}
        value={equalizingPerWeek}
        onChange={(event): void => {
          const invalue = Number.parseInt(event.target.value);
          setEqualizingPerWeek(Math.max(Math.min(invalue, 14), 1));
        }}
        disabled={listTabs.equalizing}
      />
    </Routine>
  );
}
