export class BackendError extends Error {
  status: number;
  url: string;
  constructor(status: number, message: string, url: string) {
    super(message);
    this.name = 'BackendError';
    this.status = status;
    this.url = url;
  }
}
