import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import { AppContext } from '../../../../App';
import { useBluOverviewStatus } from '../../../../dataHooks/bluHooks';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';

type Props = {
  selectedDevice: TreeDeviceFE;
};

export default function BluOverviewStatus({ selectedDevice }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const { data: bluOverviewStatus, error } = useBluOverviewStatus(selectedDevice.mui);

  if (error) {
    appContext.addBackendError(error);
  }

  if (!bluOverviewStatus) {
    return (
      <>
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Status
        </Typography>

        {!error && <LoadingIndicator />}
      </>
    );
  }

  const timestampDate = moment(bluOverviewStatus.timestamp, moment.ISO_8601).tz(state.timezone);
  const timestamp = timestampDate.format('YYYY-MM-DD HH:mm:ss');
  const headerStyle = { margin: '0 22px 22px 0' };

  return (
    <>
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        Status
      </Typography>

      {!error && (
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
          <DeviceHeaderInfo
            sx={headerStyle}
            label='State'
            text={<Box sx={{ fontWeight: 'bold' }}>{bluOverviewStatus.state}</Box>}
            textColor='info'
          />
          <DeviceHeaderInfo sx={headerStyle} label='SOC' text={bluOverviewStatus.soc} />
          <DeviceHeaderInfo sx={headerStyle} label='SOH' text={bluOverviewStatus.soh} textColor='success' />
          <DeviceHeaderInfo sx={headerStyle} label='Active alarms' text={bluOverviewStatus.activeAlarms} />
          <DeviceHeaderInfo sx={headerStyle} label='Timestamp' text={timestamp} />
        </Box>
      )}
    </>
  );
}
