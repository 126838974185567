import React from 'react';
import FormDialog from './FormDialog';
import Typography from '@mui/material/Typography';

type Props = {
  nameToDelete: string;
  close: (ok: boolean) => Promise<void>;
  open: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children?: React.ReactNode;
};

export default function DeleteDialog({ nameToDelete, close, open, size, children }: Props): JSX.Element {
  return (
    <FormDialog open={open} close={close} title={`Delete ${nameToDelete}`} okButton={'Delete'} size={size}>
      <Typography display='block' variant='highlightText'>
        {'This action will deprovision the device from the cloud.'}
      </Typography>
      <Typography display='block' variant='highlightText'>{`Are you sure you want to delete ${nameToDelete}?`}</Typography>
      {children}
    </FormDialog>
  );
}
