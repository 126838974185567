import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  identifierType: string;
  identifier: string | number;
};

export default function Identifier({ identifierType, identifier }: Props): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '19px', marginLeft: '22px' }}>
      <Typography variant='columnHeader'>{identifierType}</Typography>
      <Typography variant='bigHeader' sx={{ marginTop: '-5px' }}>
        {identifier}
      </Typography>
    </Box>
  );
}
