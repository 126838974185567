import {
  compareItems,
  instanceOfTreeDeviceFE,
  instanceOfTreeDeviceUnassignedFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';

export function selectDevice(
  device: TreeDeviceFE | TreeDeviceUnassignedFE,
  selectedItems: TreeItemFE[],
  selectItems: (selectedItems: TreeItemFE[]) => void,
  deviceList: (TreeDeviceFE | TreeDeviceUnassignedFE)[],
  event: React.MouseEvent
): void {
  const index = selectedItems.findIndex(selectedItem => compareItems(selectedItem, device));
  if (index === -1) {
    // not selected -> select it
    if (event.ctrlKey) {
      selectItems([device, ...selectedItems]);
    } else if (event.shiftKey) {
      // Only select multiple items if exactly one is already selected
      if (selectedItems.length === 1 && (instanceOfTreeDeviceFE(selectedItems[0]) || instanceOfTreeDeviceUnassignedFE(selectedItems[0]))) {
        // Also, make sure the previous selected is part of the current device list
        const prevIndex = deviceList.findIndex(listItem => compareItems(listItem, selectedItems[0]));
        const thisIndex = deviceList.findIndex(listItem => compareItems(listItem, device));
        if (prevIndex >= 0 && thisIndex >= 0) {
          const startIndex = Math.min(prevIndex, thisIndex);
          const endIndex = Math.max(prevIndex, thisIndex) + 1;
          selectItems(deviceList.slice(startIndex, endIndex));
        }
      }
    } else {
      selectItems([device]);
    }
  } else {
    // already selected -> unselect it
    // except if we already had multiple things selected, then only select this item
    if (event.ctrlKey) {
      selectItems(selectedItems.filter((item, i) => i !== index));
    } else if (event.shiftKey) {
      // Do nothing if shift is pressed
    } else if (selectedItems.length > 1) {
      selectItems([device]);
    } else {
      selectItems([]);
    }
  }
}
