import { createContext, Dispatch } from 'react';
import { Actions, State } from '../reducers/Reducer';

type ContextProps = {
  state: State;
  dispatch: Dispatch<Actions>;
};

const Store = createContext({} as ContextProps);
export default Store;
