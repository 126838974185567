import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../common/Icon';
import { COMPONENT_PADDING } from '../../themes/theme';
import ContactInformationTab from './ContactInformationTab';
import { useMsal } from '@azure/msal-react';
import { LoginContext } from '../../Login';
import DefaultButton from '../common/DefaultButton';

type UserProfileProps = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function UserProfile(props: UserProfileProps): JSX.Element {
  const { instance } = useMsal();
  const loginContext = useContext(LoginContext);

  const logoutRequest = {
    account: loginContext.account,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI || '',
  };

  useEffect(() => {
    props.changeHelpBox(undefined);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 0px 3px 1px black',
        bgcolor: 'background.paper',
        width: 400,
        borderRight: '2px solid black',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: `${COMPONENT_PADDING / 2}px`,
          bgcolor: 'background.default',
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            whiteSpace: 'nowrap',
            alignItems: 'center',
          }}
          variant='sectionHeader'
        >
          <Icon name='menu_user' fontSize='medium' />
          <Box sx={{ marginLeft: '1em' }}>Your Profile</Box>
        </Typography>
        <DefaultButton
          onClick={(): void => {
            instance.logoutRedirect(logoutRequest);
          }}
        >
          Sign Out
        </DefaultButton>
      </Box>
      <ContactInformationTab />
    </Box>
  );
}
