import React from 'react';
import Box from '@mui/material/Box';
import { Chart as ChartJS, CategoryScale, LinearScale, Tooltip, ChartOptions, ScriptableContext, BarElement } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/material';
import { LineChartData } from '../../../model/backendDataModels';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';

type Props = {
  data: LineChartData;
};

export default function BarGraph({ data }: Props): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const theme = useTheme();

  const minPoint = data.values.reduce(
    (previousValue: number, currentValue: number | null) => (currentValue ? Math.min(previousValue, currentValue) : previousValue),
    Number.MAX_VALUE
  );
  const maxPoint = data.values.reduce(
    (previousValue: number, currentValue: number | null) => (currentValue ? Math.max(previousValue, currentValue) : previousValue),
    Number.MIN_VALUE
  );

  const labels = new Array(data.xmax).fill('').map((value, index) => index + 1);
  const barData = {
    labels,
    datasets: [
      {
        data: data.values,
        backgroundColor: theme.palette.info.main,
      },
    ],
  };

  const minIndex = data.values.findIndex(v => v === minPoint);
  const maxIndex = data.values.findIndex(v => v === maxPoint);

  const labelOptions: LabelOptions = {
    display: (): boolean => {
      return false;
    },
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      datalabels: labelOptions,
    },
    scales: {
      y: {
        min: data.ymin,
        max: data.ymax,
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          callback: function (tickValue: number | string): string {
            return `${tickValue} ${data.yLabel}`;
          },
          color: theme.palette.action.active,
        },
      },
    },
    elements: {
      bar: {},
      point: {
        radius: (context: ScriptableContext<'bar'>): number => {
          const index = context.dataIndex;
          if (index === maxIndex || index === minIndex) {
            return 5;
          }
          return 1;
        },
        hoverRadius: 3,
        hitRadius: 3,
        backgroundColor: (context: ScriptableContext<'bar'>): string => {
          const index = context.dataIndex;
          if (index === maxIndex) {
            return theme.palette.error.main;
          }
          return theme.palette.success.main;
        },
      },
    },
  };

  return (
    <Box sx={{ flexGrow: 1, minHeight: '300px' }}>
      <Bar options={barOptions} plugins={[ChartDataLabels]} data={barData} />
    </Box>
  );
}
