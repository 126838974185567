import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import SortableList from '../../../common/SortableList';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import LabelledInput from './LabelledInput';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { FieldsAndValues, instanceOfLogData, LogData } from '../../../../../model/backendDataModels';
import { addDays, subMonths, subDays } from 'date-fns';
import InputComponent from '../../../../common/InputComponent';
import InputRow from './InputRow';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../utils/BackendError';
import { CSVLink } from 'react-csv';
import { Pagination, Typography } from '@mui/material';
import { CommonLogSettings } from '../../../../../reducers/Reducer';
import ValidatedDatePicker from './ValidatedDatePicker';
import { AppContext } from '../../../../../App';
import DefaultButton from '../../../../common/DefaultButton';
import { sortData } from '../../../../../utils/dataSortUtils';
import moment from 'moment-timezone';
import Store from '../../../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';

const PAGE_SIZE = 100;

type VisibilityType = 'standard' | 'advanced' | 'csvOnly';
type HeaderNameAndVisibilityType = {
  header: string;
  visibility: VisibilityType;
};
export type HeaderNamesAndVisibilitiesObjectType = Record<string, HeaderNameAndVisibilityType>;

function getHeaders(logData: LogData, visibility: 'standard' | 'advanced'): string[] {
  let headers = logData.basic.fields;

  if (visibility !== 'standard') {
    headers = logData.basic.fields.concat(logData.advanced.fields);
  }

  return headers.map(field => field.name);
}

function mergeObjectData(fieldsAndValues: FieldsAndValues, timezone: string): FieldsAndValues {
  const mergedValues: string[][] = [];

  fieldsAndValues.values.forEach(value => {
    const values: string[] = [];

    fieldsAndValues.fields.forEach((field, index) => {
      if (field.type === 'isodate') {
        const date = moment(value[index], moment.ISO_8601).tz(timezone);
        const dateString = date.format('YYYY-MM-DD HH:mm:ss');

        values.push(dateString);
      } else {
        values.push(value[index]);
      }
    });

    mergedValues.push(values);
  });

  return {
    fields: fieldsAndValues.fields,
    values: mergedValues,
  };
}

function getRows(logData: LogData, visibility: 'standard' | 'advanced', timezone: string): string[][] {
  const mergedData: LogData = {
    basic: mergeObjectData(logData.basic, timezone),
    advanced: mergeObjectData(logData.advanced, timezone),
    other: mergeObjectData(logData.other, timezone),
  };

  if (visibility === 'standard') {
    return mergedData.basic.values;
  }

  return mergedData.basic.values.map(function (e, i) {
    return e.concat(mergedData.advanced.values[i]);
  });
}

function getCsvDataFromLogData(logData: LogData): string[][] {
  const headers = logData.basic.fields.concat(logData.advanced.fields).concat(logData.other.fields);
  const basicAdvanced = logData.basic.values.map(function (e, i) {
    return e.concat(logData.advanced.values[i]);
  });
  const allData = basicAdvanced.map(function (e, i) {
    return e.concat(logData.other.values[i]);
  });
  return [headers.map(field => field.name)].concat(allData);
}

function getCsvData(data: LogData | FieldsAndValues, timezone: string): string[][] {
  if (instanceOfLogData(data)) {
    return getCsvDataFromLogData(data as LogData);
  }
  const fieldsAndValues = mergeObjectData(data as FieldsAndValues, timezone);
  return [fieldsAndValues.fields.map(field => field.name)].concat(fieldsAndValues.values);
}

type Props = {
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<LogData, BackendError> | SWRResponse<FieldsAndValues, BackendError>;
  logSettings?: CommonLogSettings;
  updateLogSettings: (settings: CommonLogSettings) => void;
};

export default function CommonLogTab({ dataHook, logSettings, updateLogSettings }: Props): JSX.Element {
  const { state } = useContext(Store);
  const now = utcToZonedTime(new Date(), state.timezone);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [startDate, setStartDate] = useState<Date>(logSettings ? logSettings.startDate : subMonths(now, 1));
  const [endDate, setEndDate] = useState<Date>(logSettings ? logSettings.endDate : now);
  const [page, setPage] = useState(1);
  const [sortCol, setSortCol] = useState<number | undefined>();
  const [sortAsc, setSortAsc] = useState<boolean | undefined>();

  const appContext = useContext(AppContext);

  const { data: logData, error } = dataHook(startDate, endDate);

  useEffect(() => {
    setPage(1);
  }, [logData]);

  useEffect(() => {
    updateLogSettings({ startDate, endDate });
  }, [startDate, endDate]);

  if (error) {
    appContext.addBackendError(error);
  }

  const headers = logData
    ? instanceOfLogData(logData)
      ? getHeaders(logData, showAdvanced ? 'advanced' : 'standard')
      : logData.fields.map(field => field.name)
    : undefined;
  const allRows = logData
    ? instanceOfLogData(logData)
      ? getRows(logData, showAdvanced ? 'advanced' : 'standard', state.timezone)
      : mergeObjectData(logData, state.timezone).values
    : undefined;

  const pages = allRows ? Math.ceil(allRows.length / PAGE_SIZE) : 1;

  const csvData = logData ? getCsvData(logData, state.timezone) : undefined;
  const rows = allRows ? sortData<string>(allRows, sortCol, sortAsc).slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE) : undefined;

  let noData = false;
  if (logData) {
    if (instanceOfLogData(logData) && logData.basic.values.length === 0) {
      noData = true;
    }
    if (!instanceOfLogData(logData) && logData.values.length === 0) {
      noData = true;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderTop: '2px solid black',
        padding: `${COMPONENT_PADDING}px`,
        height: '100%',
      }}
    >
      <InputRow>
        <LabelledInput
          label='Start date'
          input={
            <ValidatedDatePicker
              value={startDate}
              updateValue={(date: Date): void => {
                setStartDate(date);
              }}
              maxDate={subDays(endDate, 1)}
            />
          }
        />
        <LabelledInput
          label='End date'
          input={
            <ValidatedDatePicker
              value={endDate}
              updateValue={(date: Date): void => {
                setEndDate(date);
              }}
              minDate={addDays(startDate, 1)}
              maxDate={now}
            />
          }
        />

        {logData && !error && instanceOfLogData(logData) && (
          <InputComponent label='Advanced'>
            <Switch onChange={(event, checked): void => setShowAdvanced(checked)} color='info' />
          </InputComponent>
        )}

        {csvData && !error && (
          <InputComponent label=''>
            <CSVLink data={csvData} filename='logs.csv'>
              <DefaultButton
                sx={{
                  height: '40px',
                }}
                onClick={(): void => void 0}
              >
                Export CSV
              </DefaultButton>
            </CSVLink>
          </InputComponent>
        )}
      </InputRow>
      {noData && <Typography variant='sleekHeader'>No Data</Typography>}
      {rows && !noData && headers && !error && (
        <SortableList
          headers={headers}
          data={rows}
          changeDataParameters={(sortCol: number, sortAsc: boolean): void => {
            setSortCol(sortCol);
            setSortAsc(sortAsc);
          }}
        />
      )}
      {(!rows || !headers) && !noData && !error && <LoadingIndicator />}

      {logData && !noData && !error && page > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Pagination
            count={pages}
            page={page}
            onChange={(event, page): void => {
              setPage(page);
            }}
          />
        </Box>
      )}
    </Box>
  );
}
