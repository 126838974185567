import { SWRResponse } from 'swr';
import { TreeCustomer } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useTreeCustomer(): SWRResponse<TreeCustomer, BackendError> {
  const fullUrl = getFullUrl('/customer', { includeUnassigned: true });

  return getUseSwr<TreeCustomer>(fullUrl);
}
