import React, { useState, useContext } from 'react';
import ComponentBase from '../../../componentBase/ComponentBase';
import ProgressBar from './ProgressBar';
import SortableList from '../../../SortableList';
import { FieldsAndNumberValues } from '../../../../../../model/backendDataModels';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../../../../utils/BackendError';
import LoadingIndicator from '../../../../../common/LoadingIndicator';
import { AppContext } from '../../../../../../App';
import { Box, Pagination } from '@mui/material';
import { sortData } from '../../../../../../utils/dataSortUtils';
const MAX_ITEMS_PER_PAGE = 10;

type DeviceMissedListProps = {
  dataHook: (startDate: Date, endDate: Date) => SWRResponse<FieldsAndNumberValues, BackendError>;
  title: string;
  startDate: Date;
  endDate: Date;
  help?: React.ReactNode;
};

export default function DeviceMissedList({ dataHook, title, startDate, endDate, help }: DeviceMissedListProps): JSX.Element {
  const appContext = useContext(AppContext);

  const [sortCol, setSortCol] = useState(2);
  const [sortAsc, setSortAsc] = useState(false);
  const [page, setPage] = useState(1);

  const { data, error } = dataHook(startDate, endDate);

  if (error) {
    appContext.addBackendError(error);
  }

  const formattedData = data
    ? data.values.map((values: number[]) => {
        const percent = Math.round((values[1] / values[2]) * 100);
        return [
          values[0],
          `${values[1]}/${values[2]}`,
          percent,
          <>
            <ProgressBar percent={percent} />
          </>,
        ];
      })
    : undefined;

  const sortedData = formattedData ? sortData<string | number | JSX.Element>(formattedData, sortCol, sortAsc) : undefined;

  const paginatedData = sortedData
    ? sortedData.slice((page - 1) * MAX_ITEMS_PER_PAGE, Math.min(sortedData.length, page * MAX_ITEMS_PER_PAGE))
    : undefined;
  const totalNumberOfPages = sortedData ? Math.ceil(sortedData.length / MAX_ITEMS_PER_PAGE) : 0;

  const headers = data ? data.fields.concat([{ name: '', type: 'string' }]) : undefined;

  return (
    <ComponentBase title={title} help={help} sx={{ display: 'flex', flexDirection: 'column' }}>
      {paginatedData && paginatedData.length > 0 && headers && !error && (
        <>
          <SortableList
            data={paginatedData}
            headers={headers.map(field => field.name)}
            widths={[0.35, 0.33, 0.14, 0.18]}
            changeDataParameters={(sortCol: number, sortAsc: boolean): void => {
              setSortCol(sortCol);
              setSortAsc(sortAsc);
            }}
            sortableColumns={[0, 2]}
          />

          {page > 0 && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {totalNumberOfPages > 1 && (
                <Pagination
                  count={totalNumberOfPages}
                  page={page}
                  onChange={(event, page): void => {
                    setPage(page);
                  }}
                />
              )}
            </Box>
          )}
        </>
      )}
      <Box sx={{ alignContent: 'center', flexGrow: 1 }}>{!data && !error && <LoadingIndicator />}</Box>
    </ComponentBase>
  );
}
