import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../Icon';
import { useTheme } from '@mui/material/styles';

type DateChangeButtonProps = {
  onClick: () => void;
  direction: 'left' | 'right';
  disabled?: boolean;
};

export default function DateChangeButton({ onClick, direction, disabled }: DateChangeButtonProps): JSX.Element {
  const transformStr: string = direction === 'right' ? 'rotate(180deg)' : '';
  const theme = useTheme();

  return (
    <IconButton sx={{ padding: '0px', opacity: disabled ? 0.3 : 1 }} onClick={onClick} disabled={disabled}>
      <Icon
        name='cal_arrow'
        sx={{ color: theme.palette.action.active, backgroundColor: theme.palette.background.default, transform: transformStr }}
      />
    </IconButton>
  );
}
