import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ActivateFleetPopup from '../common/ActivateFleetPopup';
import FakeOverview from './fakeOverview/FakeOverview';
import { TreeFleetFE } from '../../../model/frontendDataModels';
import DefaultButton from '../../common/DefaultButton';
import { useUser } from '../../../dataHooks/adminHooks';
import { AppContext } from '../../../App';

type InactiveFleetDeviceViewProps = {
  treeFleet: TreeFleetFE;
  update: (active: boolean) => void;
};

export default function InactiveFleetDeviceView({ treeFleet, update }: InactiveFleetDeviceViewProps): JSX.Element {
  const appContext = useContext(AppContext);
  const { data, error } = useUser();
  if (error) {
    appContext.addBackendError(error);
  }

  const [activateFleetPopupOpen, setActivateFleetPopupOpen] = useState(false);
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
      }}
    >
      <FakeOverview />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backdropFilter: 'blur(6px)',
        }}
      >
        <Typography variant='graphHeader' sx={{ color: theme.palette.info.main }}>
          This device belongs to an inactive fleet
        </Typography>
        <Typography
          component='div'
          variant='graphHeader'
          sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', fontWeight: 'normal' }}
        >
          Do you want to activate&nbsp; <Box sx={{ fontWeight: 'bold' }}>{treeFleet.fleetName}</Box>
        </Typography>
        {data && !data.roleId.toString().startsWith('2') && (
          <>
            <DefaultButton
              sx={{
                marginTop: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              onClick={(): void => setActivateFleetPopupOpen(true)}
            >
              Activate Fleet
            </DefaultButton>
            <ActivateFleetPopup
              treeFleet={treeFleet}
              open={activateFleetPopupOpen}
              close={async (ok: boolean): Promise<void> => {
                if (ok) {
                  await update(true);
                }
                setActivateFleetPopupOpen(false);
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
}
