import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import { useChargerOverviewStatus } from '../../../../dataHooks/chargerHooks';
import { AppContext } from '../../../../App';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';

type ChargerOverviewStatusProps = {
  selectedDevice: TreeDeviceFE;
};

export default function ChargerOverviewStatus({ selectedDevice }: ChargerOverviewStatusProps): JSX.Element {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const { data: deviceInfo, error } = useChargerOverviewStatus(selectedDevice.mui);

  if (error) {
    appContext.addBackendError(error);
  }

  if (!deviceInfo) {
    return (
      <>
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Status
        </Typography>

        {!error && <LoadingIndicator />}
      </>
    );
  }

  const timestampDate = moment(deviceInfo.timestamp, moment.ISO_8601).tz(state.timezone);
  const timestamp = timestampDate.format('YYYY-MM-DD HH:mm:ss');
  const headerStyle = { margin: '0 22px 22px 0' };

  return (
    <>
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        Status
      </Typography>

      {!error && (
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
          <DeviceHeaderInfo
            sx={headerStyle}
            label='State'
            text={<Box sx={{ fontWeight: 'bold' }}>{deviceInfo.state}</Box>}
            textColor='info'
          />
          <DeviceHeaderInfo sx={headerStyle} label='Charging curve' text={deviceInfo.chargingCurve} />
          <DeviceHeaderInfo sx={headerStyle} label='Active alarms' text={deviceInfo.activeAlarms} />
          <DeviceHeaderInfo sx={headerStyle} label='Warning text' text={deviceInfo.warningText} />
          <DeviceHeaderInfo sx={headerStyle} label='Timestamp' text={timestamp} />
        </Box>
      )}
    </>
  );
}
