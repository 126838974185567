import React from 'react';
import Box from '@mui/material/Box';
import {
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../../../model/frontendDataModels';
import AddButtons from './addButtons/AddButtons';
import EditButton from './editButton/EditButton';
import DeleteButton from './deleteButton/DeleteButton';

type AddEditDeleteFooterProps = {
  selectedTreeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>;
  selectedDevices?: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[];
  changeSelectedTreeItem: (treeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>) => void;
};

export default function AddEditDeleteFooter({
  selectedTreeItem,
  selectedDevices,
  changeSelectedTreeItem,
}: AddEditDeleteFooterProps): JSX.Element {
  return (
    <Box sx={{ width: '100%', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <AddButtons selectedTreeItem={selectedTreeItem} />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
          <EditButton selectedTreeItem={selectedTreeItem} changeSelectedTreeItem={changeSelectedTreeItem} />
          <DeleteButton
            selectedTreeItem={selectedTreeItem}
            selectedDevices={selectedDevices}
            removeSelectedTreeItem={(): void => changeSelectedTreeItem(undefined)}
          />
        </Box>
      </Box>
    </Box>
  );
}
