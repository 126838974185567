import React from 'react';
import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material';
import InputComponent from '../../../common/InputComponent';

type InputSelectProps = {
  label: string;
  value: string;
  items: string[];
  updateValue: (value: string) => void;
  disabled?: true;
};

export default function InputSelect({ label, value, items, updateValue, disabled }: InputSelectProps): JSX.Element {
  const theme = useTheme();
  return (
    <InputComponent label={label}>
      <Select
        value={value}
        sx={{
          backgroundColor: theme.palette.background.default,
          borderRadius: '5px',
          '&:before': {
            borderRadius: '5px',
          },
        }}
        onChange={(event: SelectChangeEvent<string>): void => {
          updateValue(event.target.value);
        }}
        disabled={disabled}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item} sx={{ height: '40px' }}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </InputComponent>
  );
}
