import React from 'react';
import FormDialog from '../../../common/FormDialog';
import Typography from '@mui/material/Typography';

type AddEditDialogProps = {
  title: string;
  close: (ok: boolean) => Promise<void>;
  open: boolean;
  mode: 'Add' | 'Edit';
  okDisabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  parentName: string;
  children?: React.ReactNode;
};

export default function AddEditDialog({
  title,
  parentName,
  close,
  open,
  mode,
  okDisabled,
  size,
  children,
}: AddEditDialogProps): JSX.Element {
  return (
    <FormDialog open={open} close={close} title={`${mode} ${title}`} okButton={mode} okDisabled={okDisabled} size={size}>
      <Typography variant='bigText'>{`Parent: ${parentName}`}</Typography>
      {children}
    </FormDialog>
  );
}
