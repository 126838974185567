import { sendPostRequest } from '../utils/dataSenders';

type RegisterGWData = {
  customerId: string;
};

export async function registerGW(serialNumber: string, data: RegisterGWData, token: string): Promise<boolean> {
  const url = `/gateway/${serialNumber}/register`;
  return await sendPostRequest<RegisterGWData>({ url, data, token });
}
