import React from 'react';
import { Box, useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, BarElement, ChartOptions, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-adapter-date-fns';

type TestChartProps = {
  dates: Date[];
  values: number[];
};

export default function BundlesChart({ dates, values }: TestChartProps): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const yMax = Math.max(...values);

  const theme = useTheme();

  const labelOptions: LabelOptions = {
    color: 'white',
    display: false,
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: labelOptions,
    },
    scales: {
      y: {
        beginAtZero: true,
        grace: 1,
        max: yMax + 1,
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
          stepSize: 1,
          precision: 0,
          count: yMax + 2,
        },
      },
      x: {
        type: 'time',
        grid: {
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
    },
  };

  const barData: ChartData<'bar'> = {
    labels: dates,
    datasets: [
      {
        backgroundColor: theme.palette.info.main,
        data: values,
      },
    ],
  };

  return (
    <Box sx={{ height: 400 }}>
      <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />
    </Box>
  );
}
