import { DataObject } from '../model/backendDataModels';
import { BackendError } from './BackendError';
import { BackendArguments, getFullUrl } from './getFullUrl';

type RequestParameters<Type> = {
  method: string;
  url: string;
  data?: Type;
  args?: BackendArguments;
  token: string;
};

async function sendRequestWithReturnType<Type, RType>({
  method,
  url,
  data,
  args,
  token,
}: RequestParameters<Type>): Promise<RType | undefined> {
  const fullUrl = getFullUrl(url, args);
  const res = await fetch(fullUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: data ? JSON.stringify(data) : undefined,
  });
  const body = (await res.json()) as DataObject<RType>;
  if (!body) {
    throw new BackendError(res.status, res.statusText, url);
  }
  if (body.status !== 'success') {
    // eslint-disable-next-line no-console
    console.log(`Throwing error! ${body.message}`);
    throw new BackendError(res.status, body.message || body.status, url);
  }
  return body.data;
}

async function sendRequest<Type>({ method, url, data, args, token }: RequestParameters<Type>): Promise<Response> {
  const fullUrl = getFullUrl(url, args);
  const res = await fetch(fullUrl, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  return res;
}

type PostRequestParameters<Type> = {
  url: string;
  data: Type;
  args?: BackendArguments;
  token: string;
};

export async function sendPostRequest<Type>(parameters: PostRequestParameters<Type>): Promise<boolean> {
  // eslint-disable-next-line no-console
  console.log('sendPostRequest');
  await sendRequestWithReturnType<Type, unknown>({ ...parameters, method: 'POST' });
  // eslint-disable-next-line no-console
  console.log('sendPostRequest success');
  return true;
}

export async function sendPostRequestWithReturnType<Type, RType>(parameters: PostRequestParameters<Type>): Promise<RType | undefined> {
  // eslint-disable-next-line no-console
  console.log(`sendPostRequestWithReturnType: ${JSON.stringify(parameters)}`);
  const result = await sendRequestWithReturnType<Type, RType>({ ...parameters, method: 'POST' });
  // eslint-disable-next-line no-console
  console.log(`sendPostRequestWithReturnType success: ${JSON.stringify(result)}`);
  return result;
}

type EmptyPostRequestParameters = {
  url: string;
  args?: BackendArguments;
  token: string;
};

export async function sendEmptyPostRequest(parameters: EmptyPostRequestParameters): Promise<boolean> {
  // eslint-disable-next-line no-console
  console.log(`sendEmptyPostRequest: ${JSON.stringify(parameters)}`);
  await sendRequestWithReturnType<unknown, unknown>({ ...parameters, method: 'POST' });
  // eslint-disable-next-line no-console
  console.log('sendEmptyPostRequest success');
  return true;
}

type GetRequestParameters = {
  url: string;
  args?: BackendArguments;
  token: string;
};

export async function sendGetRequest(parameters: GetRequestParameters): Promise<Response> {
  return await sendRequest<unknown>({ ...parameters, method: 'GET' });
}

type DeleteRequestParameters = {
  url: string;
  args?: BackendArguments;
  token: string;
};

export async function sendDeleteRequest(parameters: DeleteRequestParameters): Promise<boolean> {
  // eslint-disable-next-line no-console
  console.log(`sendDeleteRequest: ${JSON.stringify(parameters)}`);
  await sendRequestWithReturnType<unknown, unknown>({ ...parameters, method: 'DELETE' });
  // eslint-disable-next-line no-console
  console.log('sendDeleteRequest success');
  return true;
}

type DeleteRequestWithInfoParameters<Type> = {
  url: string;
  data: Type;
  args?: BackendArguments;
  token: string;
};

export async function sendDeleteRequestWithInfo<Type>(parameters: DeleteRequestWithInfoParameters<Type>): Promise<boolean> {
  // eslint-disable-next-line no-console
  console.log(`sendDeleteRequest: ${JSON.stringify(parameters)}`);
  await sendRequestWithReturnType<Type, unknown>({ ...parameters, method: 'DELETE' });
  // eslint-disable-next-line no-console
  console.log('sendDeleteRequest success');
  return true;
}
