import React, { useState } from 'react';
import { WIDTH_OF_INFO_COMPONENT, COMPONENT_PADDING } from '../../../themes/theme';
import SelectedFleetOrGroupHeader from '../common/selectedFleetOrGroupHeader/SelectedFleetOrGroupHeader';
import FleetOrGroupMonitoring from '../common/fleetOrGroupMonitoring/FleetOrGroupMonitoring';
import { TreeFleetGroupFE } from '../../../model/frontendDataModels';
import { Box, useTheme } from '@mui/material';

type SelectedFleetGroupViewProps = {
  selectedFleetGroup: TreeFleetGroupFE;
};

export default function SelectedFleetGroupView({ selectedFleetGroup }: SelectedFleetGroupViewProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);

  const theme = useTheme();

  const width = expanded ? 2 * WIDTH_OF_INFO_COMPONENT + 2 * COMPONENT_PADDING : WIDTH_OF_INFO_COMPONENT + COMPONENT_PADDING;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '2px solid black',
        overflowX: 'auto',
        flexShrink: 0,
        width,
        minWidth: width,
        maxWidth: width,
      }}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.standard,
        }),
      }}
    >
      <SelectedFleetOrGroupHeader fleetOrFleetGroup={selectedFleetGroup} expanded={expanded} changeExpanded={setExpanded} />
      <FleetOrGroupMonitoring selectedFleetOrFleetGroup={selectedFleetGroup} expanded={expanded} />
    </Box>
  );
}
