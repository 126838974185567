import React from 'react';
import { Button } from '@mui/material';
import { SxProps } from './commonTypes';

type Props = {
  onClick?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function DefaultButton({ onClick, disabled, children, sx }: Props & SxProps): JSX.Element {
  return (
    <Button sx={sx} variant='contained' onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
}
