import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { TreeGatewayFE } from '../../../../model/frontendDataModels';
import GatewayIdentifier from './GatewayIdentifier';
import GatewayType from './GatewayType';
import { EmptyGatewayContext } from '../SelectedGatewayView';
import ExpandViewSwitch from '../../../common/ExpandViewSwitch';

type Props = {
  selectedGateway: TreeGatewayFE;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
};

export default function GatewayHeader({ selectedGateway, onFullscreenChanged, fullscreen }: Props): JSX.Element {
  const EmptyGateWayContext = useContext(EmptyGatewayContext);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '3px' }}>
        <GatewayType status={selectedGateway} />
        {EmptyGateWayContext.isActiveFleet && <GatewayIdentifier identifierType={'Device ID'} identifier={selectedGateway.deviceId} />}
      </Box>

      <Box sx={{ marginTop: '20px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginRight: '10px' }}>
          <ExpandViewSwitch wideMode={fullscreen} onChange={onFullscreenChanged} />
        </Box>
      </Box>
    </Box>
  );
}
