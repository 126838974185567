import React from 'react';
import { useTheme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

type ProgressBarProps = {
  percent: number;
};

export default function ProgressBar({ percent }: ProgressBarProps): JSX.Element {
  const theme = useTheme();

  return (
    <LinearProgress
      variant='determinate'
      value={percent}
      color={'info'}
      sx={{ borderRadius: 4, height: '8px', backgroundColor: theme.palette.secondary.main }}
    />
  );
}
