import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FormDialog from '../../common/FormDialog';

type DeactivateFleetPopupProps = {
  close: (ok: boolean) => Promise<void>;
  open: boolean;
};

export default function DeactivateFleetPopup({ close, open }: DeactivateFleetPopupProps): JSX.Element {
  return (
    <FormDialog open={open} close={close} title='Confimation required' okButton='Deactivate'>
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant='tableText'>This will deactivate the monthly subscription.</Typography>
      </Box>
    </FormDialog>
  );
}
