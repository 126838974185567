import React from 'react';
import { Box, Typography } from '@mui/material';
import DefaultButton from '../../../../common/DefaultButton';

type Props = {
  text: string;
  buttonText: string;
  onClick: () => void;
  buttonDisabled: boolean;
  children?: React.ReactNode;
};

export default function Routine({ text, buttonText, onClick, buttonDisabled, children }: Props): JSX.Element {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: 'solid',
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Typography variant='bigText' sx={{ marginTop: '10px', marginBottom: '10px' }}>
        {text}
      </Typography>
      {children}
      <DefaultButton sx={{ marginTop: '10px', marginBottom: '10px' }} onClick={onClick} disabled={buttonDisabled}>
        {buttonText}
      </DefaultButton>
    </Box>
  );
}
