import { BackendError } from '../utils/BackendError';
import { sendDeleteRequest, sendPostRequest, sendPostRequestWithReturnType } from '../utils/dataSenders';

type AddFleetGroupReturnType = {
  fleetgroupId: string;
};

export type AddFleetGroupData = {
  name: string;
  description: string;
  isParentFleetgroup: boolean;
  parentId: string;
};

export type EditFleetGroupData = {
  name: string;
  description: string;
};

export type FleetGroupChangeParentData = {
  parentId: string;
  isParentFleetgroup: boolean;
};

export async function addFleetGroup(
  data: AddFleetGroupData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<AddFleetGroupReturnType | undefined> {
  try {
    const url = '/fleetgroup/addNew';
    return await sendPostRequestWithReturnType<AddFleetGroupData, AddFleetGroupReturnType>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return undefined;
  }
}

export async function editFleetGroup(
  id: string,
  data: EditFleetGroupData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/fleetgroup/${id}/edit`;
    return await sendPostRequest<EditFleetGroupData>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deleteFleetGroup(id: string, token: string, addBackendError: (newError: BackendError) => void): Promise<boolean> {
  try {
    const url = `/fleetgroup/${id}/delete`;
    return await sendDeleteRequest({ url, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function fleetGroupChangeParent(
  id: string,
  data: FleetGroupChangeParentData,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = `/fleetgroup/${id}/changeParent`;
    return await sendPostRequest({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
