import React, { CSSProperties } from 'react';
import { Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SxProps } from '../../../../../common/commonTypes';
import { SxProps as MuiSxProps } from '@mui/material';
import moment from 'moment';

function Padding({ sx }: SxProps): JSX.Element {
  return (
    <Box
      sx={{
        borderRadius: '100%',
        display: 'flex',
        width: '40px',
        height: '40px',
        ...sx,
      }}
    />
  );
}

function getStateColor(state: number | null): string {
  const theme = useTheme();

  switch (state) {
    case 0:
      return theme.palette.success.main;
    case 1:
      return theme.palette.warning.main;
    case 2:
      return theme.palette.error.main;
    case -1:
      return theme.palette.info.main;
    default:
      return theme.palette.info.main;
  }
}

function getSpecialSx(
  date: Date,
  state: number | null,
  selected: boolean,
  outsideMonth?: true
): { specialSx: MuiSxProps; label: number | undefined } {
  if (outsideMonth) {
    return { specialSx: { borderStyle: 'dashed', borderWidth: 'thin', color: 'white' }, label: date && date.getDate() };
  } else if (state === null) {
    return { specialSx: { borderStyle: 'solid', borderWidth: 'thin', color: 'white' }, label: date && date.getDate() };
  } else {
    const today = new Date();
    let stateColor = getStateColor(state);

    if (date > today) {
      return { specialSx: { borderStyle: 'solid', borderWidth: 'thin', color: 'white' }, label: date && date.getDate() };
    } else if (moment(date).startOf('day').isSame(moment(today).startOf('day'))) {
      stateColor = getStateColor(-1);
    }

    return {
      specialSx: {
        borderStyle: 'solid',
        borderWidth: 'thick',
        color: '#1F282D',
        backgroundColor: selected ? stateColor : 'transparent',
        boxShadow: `0px 0px 0px 5px ${stateColor}`,
      },
      label: date && date.getDate(),
    };
  }
}

function getLabelSpecialSx(daySelected: boolean, state: number | null): CSSProperties {
  if (state && daySelected) {
    return {
      color: 'white',
      //color: getStateColor(state),
    };
  } else {
    return {
      color: 'white',
    };
  }
}

type DayLabelProps = {
  icon: JSX.Element;
  label?: number;
  date: Date | null;
  selectedDate?: Date | null;
  calendarState: number | null;
  onDateSelected?: (date: Date | null) => void;
};

function DayLabel({ icon, label, date, selectedDate, calendarState, onDateSelected }: DayLabelProps): JSX.Element {
  const selected: boolean =
    (selectedDate && date && selectedDate.getMonth() === date.getMonth() && selectedDate.getDate() === date.getDate()) ?? false;
  const specialSx = getLabelSpecialSx(selected, calendarState);
  const disabled = onDateSelected === undefined;

  return (
    <Button disabled={disabled} onClick={(): void => onDateSelected && onDateSelected(selected ? null : date ?? null)}>
      <Box sx={{ display: 'flex', position: 'relative', margin: '5px' }}>
        {icon}
        {label && (
          <div
            style={{
              ...specialSx,
              display: 'flex',
              position: 'absolute',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              width: '40px',
              height: '40px',
            }}
          >
            {label}
          </div>
        )}
      </Box>
    </Button>
  );
}

type CalendarStateDayProps = {
  date: Date;
  calendarState: number | null;
  outsideMonth?: true;
  selectedDate?: Date | null;
  onDateSelected?: (date: Date | null) => void;
};

export default function CalendarStateDay({
  date,
  calendarState,
  outsideMonth,
  selectedDate,
  onDateSelected,
}: CalendarStateDayProps): JSX.Element {
  const selected: boolean =
    (selectedDate && date && selectedDate.getMonth() === date.getMonth() && selectedDate.getDate() === date.getDate()) ?? false;
  const { specialSx, label } = getSpecialSx(date, calendarState, selected, outsideMonth);

  return (
    <DayLabel
      icon={<Padding sx={{ ...specialSx }} />}
      label={label}
      date={date}
      selectedDate={selectedDate}
      onDateSelected={
        onDateSelected
          ? (date: Date | null): void => {
              onDateSelected(outsideMonth || calendarState === null ? null : date);
            }
          : undefined
      }
      calendarState={calendarState}
    />
  );
}
