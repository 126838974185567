import { KeyedMutator } from 'swr';
import { ListTabs } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { sendPostRequest } from '../utils/dataSenders';

export async function addAndModifyTabs(
  type: 'fleet' | 'fleetgroup',
  id: string,
  newInfo: ListTabs,
  token: string,
  mutate: KeyedMutator<ListTabs>,
  addBackendError: (newError: BackendError) => void
): Promise<void> {
  try {
    const url = `/${type}/${id}/addAndModifyTabs`;
    const result = await sendPostRequest<ListTabs>({ url, data: newInfo, token });
    if (result) {
      mutate(newInfo, false);
    }
  } catch (err: unknown) {
    addBackendError(err as BackendError);
  }
}
