import React from 'react';
import { TreeCustomerFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { EditFleetData } from '../../../../services/fleetManipulation';
import AddEditFleet from '../common/addEditFleet/AddEditFleet';

type EditFleetProps = {
  treeFleet: TreeFleetFE;
  parent: TreeCustomerFE | TreeFleetGroupFE;
  open: boolean;
  close: () => void;
  editFleet: (treeFleet: TreeFleetFE, fleetData: EditFleetData) => Promise<boolean>;
};

export default function EditFleet({ treeFleet, parent, open, close, editFleet }: EditFleetProps): JSX.Element {
  return <AddEditFleet treeFleet={treeFleet} parent={parent} mode={'Edit'} open={open} close={close} editFleet={editFleet} />;
}
