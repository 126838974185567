import React from 'react';
import Icon from './Icon';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

type ExpandViewSwitchProps = {
  wideMode: boolean;
  onChange: (wideMode: boolean) => void;
};

export default function ExpandViewSwitch({ wideMode, onChange }: ExpandViewSwitchProps): JSX.Element {
  const theme = useTheme();
  return (
    <Button
      sx={{ alignItem: 'center', color: theme.palette.action.active }}
      onClick={(): void => {
        onChange(!wideMode);
      }}
    >
      <Icon name={wideMode ? 'minimize' : 'expand'} />
    </Button>
  );
}
