import React, { useState } from 'react';
import InputLongText from '../../../common/InputLongText';
import Box from '@mui/material/Box';
import InputItem from '../../../common/InputItem';
import AddEditDialog from '../common/AddEditDialog';
import { TreeCustomerFE } from '../../../../model/frontendDataModels';
import { AddCustomerData } from '../../../../services/customerManipulation';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { timeZonesNames } from '@vvo/tzdb';

type AddCustomerProps = {
  parent: TreeCustomerFE;
  open: boolean;
  close: () => void;
  addNewCustomer: (newCustomer: AddCustomerData) => Promise<void>;
};

export default function AddCustomer({ parent, open, close, addNewCustomer }: AddCustomerProps): JSX.Element {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [timezone, setTimezone] = useState<string>(timeZonesNames[0]);

  return (
    <AddEditDialog
      title='customer'
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok && timezone) {
          await addNewCustomer({ name, description, parentId: parent.customerId, timezone });
        }
        setName('');
        setDescription('');
        setTimezone(timeZonesNames[0]);
        close();
      }}
      mode={'Add'}
      size='sm'
      parentName={parent.customerName}
      okDisabled={!timezone || name === ''}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
        <Box sx={{ paddingTop: 3 }}>
          <InputAutocomplete
            label='Time Zone'
            value={timezone}
            items={timeZonesNames}
            updateValue={(v: string | null): void => {
              if (v) {
                setTimezone(v);
              }
            }}
          />
        </Box>
      </Box>
    </AddEditDialog>
  );
}
