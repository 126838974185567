import * as React from 'react';
import { Box } from '@mui/material';
import { SxProps } from '../../../common/commonTypes';
import CalendarQuantityDay from './CalendarQuantityDay';
import CalendarWeekdaysHeader from '../../common/CalendarWeekdaysHeader';
import calendarCreateMonth from '../../common/calendarCreateMonth';
import { CalendarStateData } from '../../../../model/backendDataModels';

type QuantityCalendarProps = {
  year: number;
  month: number;
  dailyConnects: CalendarStateData;
};

export default function QuantityCalendar({ year, month, dailyConnects, sx }: QuantityCalendarProps & SxProps): JSX.Element {
  return (
    <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', alignItems: 'space-between', paddingLeft: '40px', paddingRight: '40px' }}>
      <CalendarWeekdaysHeader />

      {calendarCreateMonth(year, month).map((week, weekIndex) => (
        <Box key={weekIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          {week.map((point, dayIndex) => {
            const dateOfDay = new Date(year, month, point.day);
            const key = `${weekIndex}.${dayIndex}`;

            return dateOfDay.getMonth() === month ? (
              <CalendarQuantityDay date={dateOfDay} quantity={dailyConnects[point.day - 1]} key={key} />
            ) : (
              <CalendarQuantityDay date={undefined} quantity={undefined} key={key} />
            );
          })}
        </Box>
      ))}
    </Box>
  );
}
