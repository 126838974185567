import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import FleetView from './components/fleetView/FleetView';
import StoreProvider from './store/StoreProvider';
import { COMPONENT_PADDING, getTheme } from './themes/theme';
import LogFilesPage from './components/fleetView/selectedDeviceView/logFilesTab/LogFilesPage';
import Arrange from './components/arrange/Arrange';
import { useTreeCustomer } from './dataHooks/useTreeCustomer';
import {
  TreeCustomerFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from './model/frontendDataModels';
import { convertTreeCustomerFromFE, convertTreeCustomerToFE } from './utils/customerTreeConversion';
import { BackendError } from './utils/BackendError';
import Admin from './components/admin/Admin';
import SettingsFab from './components/userSetting/UserSettingFab';
import UserProfile from './components/userProfile/UserProfile';
import MaintenanceMessage from './components/userMessage/MaintenanceMessage';
import ErrorMessage from './components/userMessage/ErrorMessage';
import DeckView from './components/DeckView';
import { useMaintenance } from './dataHooks/useMaintenance';
import { MaintenanceMessage as MaintenanceInfo } from './model/backendDataModels';
import { AppRoot } from './AppRoot';
import { InitializingView } from './components/fleetView/InitializingView';

type AppContextValues = {
  rootTreeCustomer: TreeCustomerFE | undefined;
  updateRootTreeCustomer: (newRootTreeCustomer: TreeCustomerFE) => void;
  addBackendError: (newError: BackendError) => void;
};

export const AppContext = React.createContext<AppContextValues>({
  rootTreeCustomer: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  updateRootTreeCustomer: (newRootTreeCustomer: TreeCustomerFE) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  addBackendError: (newError: BackendError) => {},
});

function App(): JSX.Element {
  const [rootTreeCustomer, setRootTreeCustomer] = useState<TreeCustomerFE | undefined>(undefined);
  const [selectedTreeItem, setSelectedTreeItem] = useState<
    Exclude<TreeItemFE, TreeCustomerFE | TreeFleetUnassignedFE | TreeDeviceUnassignedFE | TreeGatewayUnassignedFE> | undefined
  >(undefined);
  const [maintenanceMessage, setMaintenanceMessage] = useState<MaintenanceInfo | null>(null);
  const [rootTreeCustomerUpdates, setRootTreeCustomerUpdates] = useState<TreeCustomerFE[]>([]);
  const [backendErrors, setBackendErrors] = useState<BackendError[]>([]);
  const [justRemovedBackendError, setJustRemovedBackendError] = useState<BackendError | undefined>(undefined);
  const [helpBox, setHelpBox] = useState<React.ReactNode>(undefined);
  const [helpBoxVisible, setHelpBoxVisible] = useState(false);

  const { data: backendTree, error, mutate } = useTreeCustomer();
  const { data: maintenance, error: maintenanceError } = useMaintenance(backendTree);

  const helpBoxProps = {
    changeHelpBox: setHelpBox,
    hideHelpBox: (): void => setHelpBoxVisible(false),
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <AppRoot
          selectedTreeItem={selectedTreeItem}
          rootTreeCustomer={rootTreeCustomer}
          helpBox={helpBox}
          helpBoxVisible={helpBoxVisible}
          setSelectedTreeItem={setSelectedTreeItem}
          setHelpBoxVisible={setHelpBoxVisible}
        />
      ),
      children: [
        {
          path: '/',
          element: <InitializingView />,
        },
        {
          path: '/view',
          element: <FleetView selectedTreeItem={selectedTreeItem} setSelectedTreeItem={setSelectedTreeItem} {...helpBoxProps} />,
        },
        {
          path: '/arrange',
          element: <Arrange {...helpBoxProps} />,
        },
        {
          path: '/admin',
          element: <Admin {...helpBoxProps} />,
        },
        {
          path: '/profile',
          element: <UserProfile {...helpBoxProps} />,
        },
        {
          path: '/logfiles/:mui',
          element: <LogFilesPage {...helpBoxProps} />,
        },
      ],
    },
  ]);

  if (error) {
    addBackendError(error);
  }

  if (maintenanceError) {
    addBackendError(maintenanceError);
  }

  useEffect(() => {
    if (backendTree) {
      setRootTreeCustomer(convertTreeCustomerToFE(backendTree));
    }
  }, [backendTree]);

  useEffect(() => {
    if (rootTreeCustomerUpdates.length > 0) {
      // eslint-disable-next-line no-console
      console.log('Updating tree!');
      const newFrontendTree = rootTreeCustomerUpdates.pop();
      if (newFrontendTree) {
        const newBackendTree = convertTreeCustomerFromFE(newFrontendTree);
        mutate(newBackendTree, false);
      }
      setRootTreeCustomerUpdates(rootTreeCustomerUpdates);
    }
  }, [rootTreeCustomerUpdates]);

  useEffect(() => {
    if (justRemovedBackendError) {
      setTimeout(() => {
        setJustRemovedBackendError(undefined);
      }, 1000);
    }
  }, [justRemovedBackendError]);

  useEffect(() => {
    const maintenanceMessage = maintenance?.message ?? null;

    if (maintenanceMessage !== null) {
      setMaintenanceMessage(maintenanceMessage);
    }
  }, [maintenance, setMaintenanceMessage]);

  function updateRootTreeCustomer(newRootTreeCustomer: TreeCustomerFE): void {
    setRootTreeCustomerUpdates(rootTreeCustomerUpdates.concat([newRootTreeCustomer]));
  }

  function compareBackendErrors(first: BackendError, second: BackendError): boolean {
    return first.message === second.message && first.url === second.url && first.status === second.status;
  }

  function addBackendError(newError: BackendError): void {
    // eslint-disable-next-line no-console
    console.log('BackendError: ', newError);

    if (
      ((justRemovedBackendError && !compareBackendErrors(newError, justRemovedBackendError)) || !justRemovedBackendError) &&
      backendErrors.findIndex(error => compareBackendErrors(error, newError)) === -1
    ) {
      setBackendErrors([...backendErrors, newError]);
    }
  }

  function removeBackendError(): void {
    for (const error of backendErrors) {
      setBackendErrors([]);
      setJustRemovedBackendError(error);
    }
  }

  return (
    <ThemeProvider theme={getTheme}>
      <CssBaseline />
      <StoreProvider>
        <AppContext.Provider
          value={{
            rootTreeCustomer,
            updateRootTreeCustomer,
            addBackendError,
          }}
        >
          <RouterProvider router={router} />
          <DeckView
            sx={{
              position: 'absolute',
              right: `${COMPONENT_PADDING * 2}px`,
              bottom: `${COMPONENT_PADDING * 2}px`,
            }}
          >
            {backendErrors.length > 0 ? <ErrorMessage messages={backendErrors} removeMessage={removeBackendError} /> : null}
            {maintenanceMessage ? (
              <MaintenanceMessage info={maintenanceMessage} removeMessage={(): void => setMaintenanceMessage(null)} />
            ) : null}
            <SettingsFab />
          </DeckView>
        </AppContext.Provider>
      </StoreProvider>
    </ThemeProvider>
  );
}

export default App;
