import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';

export default function FakeOverviewStatus(): JSX.Element {
  return (
    <>
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        Status
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
        <DeviceHeaderInfo
          sx={{ margin: '0 22px 22px 0' }}
          label='State'
          text={<Box sx={{ fontWeight: 'bold' }}>Charging</Box>}
          textColor='info'
        />
        <DeviceHeaderInfo sx={{ margin: '0 22px 22px 0' }} label='Battery type' text='Li-ION' />
        <DeviceHeaderInfo sx={{ margin: '0 22px 22px 0' }} label='Active alarms' text='0' />
      </Box>
    </>
  );
}
