import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from './commonTypes';

export default function LoadingIndicator({ sx }: SxProps): JSX.Element {
  return (
    <Box sx={{ marginTop: '1em', ...sx, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <CircularProgress />
    </Box>
  );
}
