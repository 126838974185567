import React, { useContext, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import LoadingIndicator from '../../../common/LoadingIndicator';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import { AppContext } from '../../../../App';
import { useBmsOverviewStatus } from '../../../../dataHooks/bmsHooks';
import { EmptyDeviceContext } from '../SelectedDeviceView';
import moment from 'moment-timezone';
import Store from '../../../../store/Store';

type Props = {
  selectedDevice: TreeDeviceFE;
};

export default function BmsOverviewStatus({ selectedDevice }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);
  const emptyDeviceContext = useContext(EmptyDeviceContext);

  const { data: bmsOverviewStatus, error } = useBmsOverviewStatus(selectedDevice.mui);

  if (error) {
    appContext.addBackendError(error);
  }

  useEffect(() => {
    emptyDeviceContext.setIsInitializedDevice(true);
  }, []);

  if (!bmsOverviewStatus) {
    return (
      <>
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Status
        </Typography>

        {!error && <LoadingIndicator />}
      </>
    );
  }

  const timestampDate = moment(bmsOverviewStatus.timestamp, moment.ISO_8601).tz(state.timezone);
  const timestamp = timestampDate.format('YYYY-MM-DD HH:mm:ss');
  const headerStyle = { margin: '0 22px 22px 0' };

  return (
    <>
      <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
        Status
      </Typography>

      {!error && (
        <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '19px' }}>
          <DeviceHeaderInfo sx={headerStyle} label='Totals Cycles' text={bmsOverviewStatus.totalsCycle} />
          <DeviceHeaderInfo sx={headerStyle} label='SoH' text={bmsOverviewStatus.soh} textColor='success' />
          <DeviceHeaderInfo sx={headerStyle} label='Discharged Ah' text={bmsOverviewStatus.dischargedAh} />
          <DeviceHeaderInfo sx={headerStyle} label='Regen Ah' text={bmsOverviewStatus.regenAh} />
          <DeviceHeaderInfo sx={headerStyle} label='Charged Ah' text={bmsOverviewStatus.chargedAh} />
          <DeviceHeaderInfo sx={headerStyle} label='Deep Discharges' text={bmsOverviewStatus.deepDischarges} />
          <DeviceHeaderInfo sx={headerStyle} label='Timestamp' text={timestamp} />
        </Box>
      )}
    </>
  );
}
