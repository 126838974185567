import React from 'react';
import Grid from '@mui/material/Grid';
import DataInfoTitle from './DataInfoTitle';
import DataInfoHelpText from './DataInfoHelpText';
import { WIDTH_OF_INFO_COMPONENT } from '../../../../themes/theme';
import { SxProps } from '../../../common/commonTypes';

type ComponentBaseProps = {
  title: string;
  help: React.ReactNode;
  doubleSize?: boolean;
  children: React.ReactNode;
};

export default function ComponentBase({ title, help, children, doubleSize, sx }: ComponentBaseProps & SxProps): JSX.Element {
  return (
    <Grid item width={doubleSize ? undefined : WIDTH_OF_INFO_COMPONENT} sx={sx} xs={doubleSize ? 12 : undefined}>
      <DataInfoTitle title={title} icon={help ? <DataInfoHelpText body={help} /> : undefined} />
      {children}
    </Grid>
  );
}
