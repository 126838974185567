import React, { useState, useEffect } from 'react';
import InputItem from '../../../common/InputItem';
import InputLongText from '../../../common/InputLongText';
import AddEditDialog from '../common/AddEditDialog';
import Box from '@mui/material/Box';
import { instanceOfTreeCustomerFE, TreeCustomerFE, TreeFleetGroupFE } from '../../../../model/frontendDataModels';
import { EditFleetGroupData } from '../../../../services/fleetGroupManipulation';

type EditFleetGroupProps = {
  parent?: TreeCustomerFE | TreeFleetGroupFE;
  treeFleetGroup: TreeFleetGroupFE;
  open: boolean;
  close: () => void;
  updateFleetGroup: (treeFleetGroup: TreeFleetGroupFE, newData: EditFleetGroupData) => Promise<boolean>;
};

export default function EditFleetGroup({ parent, treeFleetGroup, open, close, updateFleetGroup }: EditFleetGroupProps): JSX.Element {
  const [name, setName] = useState(treeFleetGroup.fleetgroupName);
  const [description, setDescription] = useState(treeFleetGroup.description);

  useEffect(() => {
    setName(treeFleetGroup.fleetgroupName);
    setDescription(treeFleetGroup.description);
  }, [treeFleetGroup]);

  return (
    <AddEditDialog
      title='fleet group'
      parentName={parent ? (instanceOfTreeCustomerFE(parent) ? parent.customerName : parent.fleetgroupName) : '-'}
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok) {
          const result = await updateFleetGroup(treeFleetGroup, { name, description });
          if (!result) {
            setName(treeFleetGroup.fleetgroupName);
            setDescription(treeFleetGroup.description);
          }
        } else {
          setName(treeFleetGroup.fleetgroupName);
          setDescription(treeFleetGroup.description);
        }
        close();
      }}
      mode={'Edit'}
      size='sm'
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
      </Box>
    </AddEditDialog>
  );
}
