import React, { useContext, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import DeviceMissedList from '../common/deviceMissedList/DeviceMissedList';
import EventLog from '../common/EventLog';
import DeletableTabTitle from '../common/DeletableTabTitle';
import { instanceOfTreeFleetGroupFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../../model/frontendDataModels';
import { BackendError } from '../../../../../utils/BackendError';
import { SWRResponse } from 'swr';
import { useEquilizingLogs, useEquilizingMissed } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import WeeklyEqCompGraph from './WeeklyEqCompGraph';
import { FieldsAndNumberValues, FieldsAndValues } from '../../../../../model/backendDataModels';
import Store from '../../../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';
import Box from '@mui/material/Box';

type EqualizingTabProps = {
  selectedFleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
  deleteTab: () => Promise<void>;
};

export default function EqualizingTab({ selectedFleetOrFleetGroup, deleteTab }: EqualizingTabProps): JSX.Element {
  const { state } = useContext(Store);

  const date = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('month');
  const endDate = moment(startDate).add(1, 'month');

  const today = moment(date);
  const correctedEndDate = moment(endDate).isAfter(today) ? today : endDate;

  function getEqualizingLogsDataHook(startDate: Date, endDate: Date): SWRResponse<FieldsAndValues, BackendError> {
    return useEquilizingLogs(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  function getEquilizingMissedDataHook(startDate: Date, endDate: Date): SWRResponse<FieldsAndNumberValues, BackendError> {
    return useEquilizingMissed(
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? 'fleetgroup' : 'fleet',
      instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId,
      startDate,
      endDate
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <DeletableTabTitle title={'Equalizing'} deleteTab={deleteTab} />
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <WeeklyEqCompGraph
            selectedFleetOrFleetGroup={selectedFleetOrFleetGroup}
            title='EQ Completion'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
            onMonthSelected={setMonth}
            onYearSelected={setYear}
          />
          <DeviceMissedList
            dataHook={getEquilizingMissedDataHook}
            title='Equalization Completion Log'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
          />
          <EventLog
            dataHook={getEqualizingLogsDataHook}
            title='EQ Log'
            startDate={startDate.toDate()}
            endDate={correctedEndDate.toDate()}
          />
        </Grid>
      </Paper>
    </Box>
  );
}
