import React from 'react';
import { Box, Typography } from '@mui/material';
import CustomerTree from './CustomerTree';
import Icon from '../Icon';
import { TreeCustomerFE } from '../../../model/frontendDataModels';

type CustomerTreeProps = {
  rootTreeCustomer: TreeCustomerFE;
  includeUnassigned: boolean;
  onlyCustomers: boolean;
};

export default function TopCustomerTree({ rootTreeCustomer, includeUnassigned, onlyCustomers }: CustomerTreeProps): JSX.Element {
  return (
    <Box
      sx={{
        overflow: 'auto',
      }}
    >
      <Typography
        variant='columnHeader'
        sx={{
          margin: '0px 24px 4px 59px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        Name
        <Icon name='online' sx={{ fontSize: '16px' }} />
      </Typography>
      <CustomerTree treeCustomer={rootTreeCustomer} depth={0} includeUnassigned={includeUnassigned} onlyCustomers={onlyCustomers} />
    </Box>
  );
}
