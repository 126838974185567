import { getWeeksInMonth } from 'date-fns';

type Day = { day: number };

export default function calendarCreateMonth(year: number, month: number): Day[][] {
  const monthDays: Day[][] = [];
  const weeksInMonth = getWeeksInMonth(new Date(year, month), { weekStartsOn: 1 });

  let dayOfFirstWeek = new Date(year, month).getDay(); // 0 for sunday; 1 for monday etc.
  if (dayOfFirstWeek === 0) {
    dayOfFirstWeek = 7;
  }
  let day = 2 - dayOfFirstWeek;
  for (let weekIndex = 0; weekIndex < weeksInMonth; weekIndex++) {
    monthDays[weekIndex] = [];
    for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
      monthDays[weekIndex][dayIndex] = { day: day };
      day++;
    }
  }
  return monthDays;
}
