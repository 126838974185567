import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
  identifierType: 'Serial Number' | 'Device ID';
  identifier: string | number;
  fleetName: string;
};

export default function IdentifierAndFleet({ identifierType, identifier, fleetName }: Props): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '19px', marginLeft: '22px' }}>
      <Typography variant='columnHeader'>{identifierType}</Typography>
      <Typography variant='bigHeader' sx={{ marginTop: '-5px' }}>
        {identifier}
      </Typography>
      <Typography variant='sleekHeader' sx={{ marginTop: '-10px' }}>
        {fleetName}
      </Typography>
    </Box>
  );
}
