import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

type labelledInputProps = {
  label?: string;
  input: ReactNode;
  sx?: SxProps;
};

export default function LabelledInput({ label, input, sx }: labelledInputProps): JSX.Element {
  return (
    <Box sx={{ height: '80px', marginRight: '30px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant='columnHeader' sx={{ height: '24px', ...sx }}>
        {label}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1 }}>{input}</Box>
    </Box>
  );
}
