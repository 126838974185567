import React, { useContext, useEffect } from 'react';
import ExpandableRow from './ExpandableRow';
import FleetGroupTree from './FleetGroupTree';
import FleetTree from './FleetTree';
import { TreeContext } from './SearchableTree';
import { selectItem } from './selectItem';
import { instanceOfTreeCustomerFE, instanceOfTreeFleetUnassignedFE, TreeCustomerFE, TreeItemFE } from '../../../model/frontendDataModels';
import { treeCustomerIncludes, treeCustomerHasWarning } from '../../../utils/treeCustomerFunctions';
import FleetTreeUnassigned from './FleetTreeUnassigned';

type CustomerTreeProps = {
  treeCustomer: TreeCustomerFE;
  depth: number;
  includeUnassigned: boolean;
  onlyCustomers: boolean;
};

export default function CustomerTree({ treeCustomer, depth, includeUnassigned, onlyCustomers }: CustomerTreeProps): JSX.Element | null {
  const treeContext = useContext(TreeContext);

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(treeCustomer.customerId)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (treeCustomerIncludes(treeCustomer, treeItem)) {
          treeContext.expandTree(treeCustomer.customerId);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label={treeCustomer.customerName}
      iconName='menu_user'
      depth={depth}
      selected={
        treeContext.selectedTreeItems.findIndex(item => instanceOfTreeCustomerFE(item) && item.customerId === treeCustomer.customerId) !==
        -1
      }
      select={
        treeContext.canSelect(treeCustomer)
          ? (event: React.MouseEvent): void => selectItem(treeCustomer, treeContext.selectedTreeItems, treeContext.selectTreeItems, event)
          : undefined
      }
      expanded={treeContext.expandedTreeItemIds.includes(treeCustomer.customerId)}
      expandTree={(): void => treeContext.expandTree(treeCustomer.customerId)}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];
        treeContext.selectedTreeItems.forEach(treeItem => {
          if (!treeCustomerIncludes(treeCustomer, treeItem)) {
            newSelectedTreeItems.push(treeItem);
          }
        });
        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(treeCustomer.customerId);
      }}
      hasWarning={treeCustomerHasWarning(treeCustomer)}
    >
      {!onlyCustomers &&
        treeCustomer.fleets.map(treeFleet => {
          if (instanceOfTreeFleetUnassignedFE(treeFleet)) {
            if (includeUnassigned) {
              return <FleetTreeUnassigned key={`${treeCustomer.customerId}unassigned`} treeFleet={treeFleet} depth={depth + 1} />;
            }
          } else {
            return <FleetTree key={treeFleet.fleetId} treeFleet={treeFleet} depth={depth + 1} unassigned={includeUnassigned} />;
          }
        })}
      {treeCustomer.children.map(treeCustomer => (
        <CustomerTree
          key={treeCustomer.customerId}
          treeCustomer={treeCustomer}
          depth={depth + 1}
          includeUnassigned={includeUnassigned}
          onlyCustomers={onlyCustomers}
        />
      ))}
      {!onlyCustomers &&
        treeCustomer.fleetgroups.map(treeFleetGroup => (
          <FleetGroupTree
            key={treeFleetGroup.fleetgroupId}
            treeFleetGroup={treeFleetGroup}
            depth={depth + 1}
            unassigned={includeUnassigned}
          />
        ))}
    </ExpandableRow>
  );
}
