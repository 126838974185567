import * as React from 'react';
import Typography from '@mui/material/Typography';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import { format, subMonths, subYears } from 'date-fns';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import DateChangeButton from './DateChangeButton';

type PickerProps = {
  label: string | number;
  width: number;
  onClickLeft: () => void;
  onClickRight: () => void;
  disabledLeft?: boolean;
  disabledRight?: boolean;
};

function Picker({ label, width, onClickLeft, onClickRight, disabledLeft, disabledRight }: PickerProps): JSX.Element {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '24px',
        justifyContent: 'space-between',
        width: `${width}px`,
      }}
    >
      <DateChangeButton onClick={onClickLeft} direction={'left'} disabled={disabledLeft} />
      <Typography variant='highlightText' sx={{ color: theme.palette.info.main }}>
        {label}
      </Typography>
      <DateChangeButton onClick={onClickRight} direction={'right'} disabled={disabledRight} />
    </Box>
  );
}

type MonthAndYearPickerProps = {
  year: number;
  month: number;
  onYearSelected: (year: number) => void;
  onMonthSelected: (month: number) => void;
  yearOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
};

export default function MonthAndYearPicker({
  year,
  month,
  onYearSelected,
  onMonthSelected,
  yearOnly,
  minDate,
  maxDate,
}: MonthAndYearPickerProps): JSX.Element {
  const theme = useTheme();
  const date = new Date(year, month, 1);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: yearOnly ? 'flex-end' : 'space-between' }}>
        {!yearOnly && (
          <Picker
            label={format(date, 'LLLL')}
            width={142}
            onClickLeft={(): void => {
              onMonthSelected(addMonths(date, -1).getMonth());
            }}
            onClickRight={(): void => {
              onMonthSelected(addMonths(date, 1).getMonth());
            }}
            disabledLeft={minDate ? minDate > subMonths(date, 1) : false}
            disabledRight={maxDate ? maxDate < addMonths(date, 1) : false}
          />
        )}
        <Picker
          label={date.getFullYear()}
          width={106}
          onClickLeft={(): void => {
            onYearSelected(addYears(date, -1).getFullYear());
          }}
          onClickRight={(): void => {
            onYearSelected(addYears(date, 1).getFullYear());
          }}
          disabledLeft={minDate ? minDate > subYears(date, 1) : false}
          disabledRight={maxDate ? maxDate < addYears(date, 1) : false}
        />
      </Box>
      <Divider
        sx={{
          background: theme.palette.background.default,
          borderBottomWidth: '2px',
        }}
      />
    </Box>
  );
}
