import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from '../../../common/Icon';
import { CommonOverviewStatus } from '../../../../model/backendDataModels';

type Props = {
  status: CommonOverviewStatus;
  type: 'charger' | 'bmu' | 'bms';
};

export default function DeviceType({ status, type }: Props): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: '19px',
        marginLeft: '22px',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='sectionHeader'
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Icon name={type} />
        <Box sx={{ marginLeft: '1em' }}>{`Device / ${status.productType}`}</Box>
      </Typography>
    </Box>
  );
}
