import React from 'react';
import Button from '@mui/material/Button';

type MonitoringButtonProps = {
  title: string;
  disabled: boolean;
  onClick: () => void;
};

export default function MonitoringButton({ title, disabled, onClick }: MonitoringButtonProps): JSX.Element {
  return (
    <Button sx={{ width: '100%' }} variant='outlined' onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
}
