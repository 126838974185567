import { SWRResponse } from 'swr';
import { CalendarStateData, ChargerOverviewStatus, LineChartData } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useChargerDailyConnects(mui: string, year: number, month: number): SWRResponse<CalendarStateData, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/dailyconnect`, {
    year,
    month,
  });
  return getUseSwr<CalendarStateData>(fullUrl);
}

export function useChargerUsedMains(mui: string, year: number, month: number): SWRResponse<LineChartData, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/mainsenergy`, { year, month });
  return getUseSwr<LineChartData>(fullUrl);
}

export function useChargerOverviewStatus(mui: string): SWRResponse<ChargerOverviewStatus, BackendError> {
  const fullUrl = getFullUrl(`/charger/${mui}/overview/status`);

  return getUseSwr<ChargerOverviewStatus>(fullUrl);
}
