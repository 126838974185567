import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FormDialog from '../../../../common/FormDialog';

type Props = {
  bundles: number;
  totalActiveDevices: number;
  open: boolean;
  close: (ok: boolean) => Promise<void>;
};

export function RemoveBundlePopup({ bundles, totalActiveDevices, close, open }: Props): JSX.Element | null {
  const diff = totalActiveDevices - (bundles - 1) * 50;

  return (
    <FormDialog
      open={open}
      close={async (ok: boolean): Promise<void> => {
        await close(ok);
      }}
      title='Reduce number of bundles'
      okButton='Continue'
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant='tableText'>
          {`Reduce the number of bundles to a total of ${bundles - 1}. The bundle will be terminated at the end of the month.`}
          {diff > 0 && `Deactivate ${diff} devices by the end of the month or the subscription will automatically be renewed.`}
        </Typography>
      </Box>
    </FormDialog>
  );
}
