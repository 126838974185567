import React, { useContext } from 'react';
import { SWRResponse } from 'swr';
import { useProtectiveLogs } from '../../../../dataHooks/bmsHooks';
import { FieldsAndValues } from '../../../../model/backendDataModels';
import { CommonLogSettings } from '../../../../reducers/Reducer';
import Store from '../../../../store/Store';
import { BackendError } from '../../../../utils/BackendError';
import CommonLogTab from './common/CommonLogTab';

type Props = {
  mui: string;
};

export default function ProtectiveLogTab({ mui }: Props): JSX.Element {
  const { state, dispatch } = useContext(Store);
  function getProtectiveLogDataHook(from: Date, to: Date): SWRResponse<FieldsAndValues, BackendError> {
    return useProtectiveLogs(mui, from, to);
  }

  return (
    <CommonLogTab
      dataHook={getProtectiveLogDataHook}
      logSettings={state.logFilesSettings.protectiveLogSettings}
      updateLogSettings={(settings: CommonLogSettings): void => {
        dispatch({
          type: 'SET_PROTECTIVE_LOG_SETTINGS',
          payload: settings,
        });
      }}
    />
  );
}
