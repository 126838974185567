import React, { ReactNode } from 'react';
import { Box, SxProps } from '@mui/material';

type DeckViewProps = {
  sx: SxProps;
  children: ReactNode[];
};

export default function DeckView(props: DeckViewProps): JSX.Element {
  return <Box sx={props.sx}>{props.children.find(value => value !== null)}</Box>;
}
