import React, { ChangeEvent, Fragment, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, useTheme, Pagination } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { InvoiceDataInvAdmin } from '../../../../model/backendDataModels';
import { addMonths, differenceInDays, parse } from 'date-fns';

const ROWS_PER_PAGE = 10;

type InvoiceDetailsTableProps = {
  invoiceDataInvAdmins: InvoiceDataInvAdmin[];
};

export default function InvoiceDetailsTable({ invoiceDataInvAdmins }: InvoiceDetailsTableProps): JSX.Element {
  const theme = useTheme();

  const [page, setPage] = useState(1);

  const totalNumberOfPages = invoiceDataInvAdmins.length === 1 ? 0 : Math.ceil(invoiceDataInvAdmins.length / ROWS_PER_PAGE);

  const paginatedInvoiceDetailsArray =
    totalNumberOfPages > 1 ? invoiceDataInvAdmins.slice((page - 1) * ROWS_PER_PAGE, page * ROWS_PER_PAGE) : [...invoiceDataInvAdmins];

  return (
    <>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                background: theme.palette.primary.main,
                textAlign: 'left',
              }}
            >
              <Typography variant='columnHeader'>Customer Name</Typography>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                background: theme.palette.primary.main,
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant='columnHeader'>Active devices</Typography>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                background: theme.palette.primary.main,
                textAlign: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant='columnHeader'>Demo devices</Typography>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                borderBottom: 'none',
                whiteSpace: 'nowrap',
                background: theme.palette.primary.main,
                textAlign: 'center',
              }}
            >
              <Typography variant='columnHeader'>Bundles</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedInvoiceDetailsArray.map((invoiceDetailsData, invoiceDetailsDataIndex) => {
            const dateBeginningOfMonth = new Date();
            dateBeginningOfMonth.setDate(1);
            const bundlesNextMonth = invoiceDetailsData.bundles
              ? invoiceDetailsData.bundles.filter(bundle => bundle.endDate === undefined).length
              : 0;
            const startDatesThisMonth = invoiceDetailsData.bundles
              ? invoiceDetailsData.bundles
                  .filter(b => b.startDate)
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  .map(b => parse(b.startDate!, 'yyyy-MM-dd', new Date()))
                  .filter(b => b > dateBeginningOfMonth)
              : [];

            const dateBeginningOfNextMonth = addMonths(dateBeginningOfMonth, 1);
            const daysArray = startDatesThisMonth.map(d => differenceInDays(dateBeginningOfNextMonth, d));
            const daysInMonth = differenceInDays(dateBeginningOfNextMonth, dateBeginningOfMonth);
            const bundlesThisMonth = daysArray.reduce((partialSum, value) => partialSum + value, 0) / daysInMonth;
            const bundles = Math.round((bundlesNextMonth + bundlesThisMonth) * 100) / 100;

            return (
              <Fragment key={invoiceDetailsDataIndex}>
                {invoiceDataInvAdmins.length === 1 &&
                  invoiceDetailsData.customers &&
                  invoiceDetailsData.customers.map((customerInfo, customerInfoIndex) => {
                    return (
                      <TableRow key={customerInfoIndex}>
                        <TableCell
                          sx={{
                            borderBottom: '1px solid #30444E',
                            textAlign: 'left',
                          }}
                        >
                          <Typography whiteSpace='nowrap' variant='tableText'>
                            {customerInfo.customerName}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: '1px solid #30444E',
                            textAlign: 'center',
                          }}
                        >
                          <Typography whiteSpace='nowrap' variant='tableText'>
                            {customerInfo.activeDevices}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: '1px solid #30444E',
                          }}
                        />
                        <TableCell
                          sx={{
                            borderBottom: '1px solid #30444E',
                          }}
                        />
                      </TableRow>
                    );
                  })}
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #30444E',
                      textAlign: 'left',
                    }}
                  >
                    <Typography whiteSpace='nowrap' variant='tableText'>
                      {invoiceDetailsData.customerName}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #30444E',
                      textAlign: 'center',
                    }}
                  >
                    {invoiceDetailsData.customers && (
                      <Typography whiteSpace='nowrap' variant='tableText'>
                        {`${invoiceDetailsData.customers.reduce((partialSum, c) => partialSum + c.activeDevices, 0)} / ${
                          invoiceDetailsData.totalDevices
                        }`}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #30444E',
                      textAlign: 'center',
                    }}
                  >
                    {invoiceDetailsData.activeDemoDevices && (
                      <Typography whiteSpace='nowrap' variant='tableText'>
                        {`${invoiceDetailsData.activeDemoDevices} / ${invoiceDetailsData.totalDemoDevices}`}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: '1px solid #30444E',
                      textAlign: 'center',
                    }}
                  >
                    <Typography whiteSpace='nowrap' variant='tableText'>
                      {bundles}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
      {totalNumberOfPages > 1 && (
        <Pagination
          count={totalNumberOfPages}
          onChange={(event: ChangeEvent<unknown>, value: number): void => {
            setPage(value);
          }}
        />
      )}
    </>
  );
}
