import React, { useContext } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { COMPONENT_PADDING } from '../../themes/theme';
import { LoginContext } from '../../Login';
import { Box } from '@mui/material';

export default function ContactInformationTab(): JSX.Element {
  const loginContext = useContext(LoginContext);

  return (
    <Paper elevation={0} sx={{ width: '100%', padding: `${COMPONENT_PADDING}px` }}>
      <Typography variant='graphHeader'>Contact Information</Typography>
      <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='columnHeader' sx={{ height: '24px' }}>
            Name
          </Typography>
          <Typography variant='tableText' sx={{ height: '24px' }}>
            {loginContext.account ? loginContext.account.name : 'N/A'}
          </Typography>
        </Box>
        <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='columnHeader' sx={{ height: '24px' }}>
            Email/Username
          </Typography>
          <Typography variant='tableText' sx={{ height: '24px' }}>
            {loginContext.account ? loginContext.account.username : 'N/A'}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
