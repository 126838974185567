import React, { useContext, useState } from 'react';
import { Alarms } from '../../../model/backendDataModels';
import MonthAndYearPicker from '../../common/monthAndYearPicker/MonthAndYearPicker';
import ComponentBase from '../common/componentBase/ComponentBase';
import { SWRResponse } from 'swr';
import { BackendError } from '../../../utils/BackendError';
import LoadingIndicator from '../../common/LoadingIndicator';
import { AppContext } from '../../../App';
import BarGraph from '../common/fleetOrGroupMonitoring/alarmsTab/BarGraph';
import { Box } from '@mui/material';
import Store from '../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';
import moment from 'moment';

type AlarmWithQuantity = {
  name: string;
  quantity: number;
};

function getMerged(alarmsData: Alarms): AlarmWithQuantity[] {
  const merged: AlarmWithQuantity[] = alarmsData.alarmNames.map(function (e, i) {
    return { name: e, quantity: alarmsData.quantity[i] };
  });

  return merged;
}

function getSorted(alarmsData: AlarmWithQuantity[]): AlarmWithQuantity[] {
  return alarmsData.sort((n1, n2) => {
    return n1.name > n2.name ? 1 : -1;
  });
}

type AlarmsGraphProps = {
  type: 'bms' | 'bmu';
  mui: string;
  dataHook: (type: 'bms' | 'bmu', mui: string, startDate: Date, endDate: Date) => SWRResponse<Alarms, BackendError>;
  title: string;
  help?: React.ReactNode;
};

export default function AlarmsGraph({ type, mui, dataHook, title, help }: AlarmsGraphProps): JSX.Element | null {
  const appContext = useContext(AppContext);
  const { state } = useContext(Store);

  const date = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState<number>(date.getFullYear());
  const [month, setMonth] = useState<number>(date.getMonth());

  const startDate = moment(new Date(year, month, 1)).startOf('day');
  const endDate = moment(startDate).add(1, 'month');

  const { data, error } = dataHook(type, mui, startDate.toDate(), endDate.toDate());

  if (error) {
    appContext.addBackendError(error);
  }

  const sortedAndMerged = data ? getSorted(getMerged(data)) : undefined;

  return (
    <ComponentBase title={title} help={help}>
      <MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} />

      {sortedAndMerged && !error && (
        <Box sx={{ minHeight: '300px', paddingTop: '1em' }}>
          <BarGraph
            data={{
              categories: sortedAndMerged.map((alarmWithQuantity: AlarmWithQuantity) => {
                return alarmWithQuantity.name;
              }),
              values: sortedAndMerged.map((alarmWithQuantity: AlarmWithQuantity) => {
                return alarmWithQuantity.quantity;
              }),
            }}
          />
        </Box>
      )}
      {!sortedAndMerged && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
