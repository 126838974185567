import useSWR, { SWRResponse } from 'swr';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { MaintenanceInfo, TreeCustomer } from '../model/backendDataModels';
import { useContext } from 'react';
import { LoginContext } from '../Login';
import { dataFetcher } from '../utils/dataFetcher';
import { getSwrRevalidationOptions } from '../utils/getSwrRevalidationOptions';

export function useMaintenance(backendTree: TreeCustomer | undefined): SWRResponse<MaintenanceInfo, BackendError> {
  const fullUrl = getFullUrl(`/customer/${backendTree?.customerId ?? ''}/maintenance`);
  const loginContext = useContext(LoginContext);
  const dataFetcherToUse = loginContext.accessToken ? dataFetcher<MaintenanceInfo>(loginContext.accessToken) : null;

  return useSWR<MaintenanceInfo, BackendError>(
    () => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const fullUrl = getFullUrl(`/customer/${backendTree!.customerId}/maintenance`);
      const urlToUse = loginContext.accessToken ? fullUrl : null;

      return urlToUse;
    },
    dataFetcherToUse,
    getSwrRevalidationOptions(fullUrl)
  );
}
