import { sendGetRequest } from '../utils/dataSenders';

export async function generateLogExport(
  selectedType: string,
  selectedId: string,
  startDate: Date,
  endDate: Date,
  token: string,
  timezone: string
): Promise<Response> {
  const url = `/${selectedType}/${selectedId}/report/export`;
  const args = {
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    timezone: timezone,
  };
  return sendGetRequest({ url: url, args: args, token: token });
}
