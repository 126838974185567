import React from 'react';
import Box from '@mui/material/Box';
import DeviceHeaderInfo from '../common/DeviceHeaderInfo';
import { CommonOverviewStatus } from '../../../../model/backendDataModels';

type Props = {
  status: CommonOverviewStatus;
};

export default function TopRightHeader({ status }: Props): JSX.Element {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '11px' }}>
      <DeviceHeaderInfo label='Product' text={status.productType.toUpperCase()} />
      <DeviceHeaderInfo label='Voltage' text={`${status.voltage}`} />
      <DeviceHeaderInfo label='Capacity' text={`${status.capacity}`} />
      <DeviceHeaderInfo textColor='success' label='Temperature' text={status.temperature} />
    </Box>
  );
}
