import React, { useEffect, useState } from 'react';
import InputLongText from '../../../common/InputLongText';
import Box from '@mui/material/Box';
import InputItem from '../../../common/InputItem';
import AddEditDialog from '../common/AddEditDialog';
import { TreeCustomerFE } from '../../../../model/frontendDataModels';
import { EditCustomerData } from '../../../../services/customerManipulation';
import InputAutocomplete from '../common/addEditFleet/InputAutocomplete';
import { timeZonesNames } from '@vvo/tzdb';

type EditCustomerProps = {
  parent?: TreeCustomerFE;
  treeCustomer: TreeCustomerFE;
  open: boolean;
  close: () => void;
  updateCustomer: (treeCustomer: TreeCustomerFE, newData: EditCustomerData) => Promise<boolean>;
};

export default function EditCustomer({ parent, treeCustomer, open, close, updateCustomer }: EditCustomerProps): JSX.Element {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [timezone, setTimezone] = useState<string>(treeCustomer.timezone);

  useEffect(() => {
    setName(treeCustomer.customerName);
    setDescription(treeCustomer.description);
    setTimezone(treeCustomer.timezone);
  }, [treeCustomer]);

  return (
    <AddEditDialog
      title='customer'
      parentName={parent ? parent.customerName : '-'}
      open={open}
      close={async (ok: boolean): Promise<void> => {
        if (ok && timezone) {
          const result = await updateCustomer(treeCustomer, { name, description, timezone });
          if (!result) {
            setName(treeCustomer.customerName);
            setDescription(treeCustomer.description);
          }
        } else {
          setName(treeCustomer.customerName);
          setDescription(treeCustomer.description);
        }
        close();
      }}
      mode={'Edit'}
      size='sm'
      okDisabled={!timezone || name === ''}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <InputItem label='Name' value={name} updateValue={setName} />
        <InputLongText label='Description' value={description || ''} updateValue={setDescription} />
        <Box sx={{ paddingTop: 3 }}>
          <InputAutocomplete
            label='Time Zone'
            value={timezone || ''}
            items={timeZonesNames}
            updateValue={(v: string | null): void => {
              if (v) {
                setTimezone(v);
              }
            }}
          />
        </Box>
      </Box>
    </AddEditDialog>
  );
}
