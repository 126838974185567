import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SxProps } from '../../../common/commonTypes';

type FleetOrGroupHeaderInfoProps = {
  label: string;
  text: React.ReactNode;
};

export default function FleetOrGroupHeaderInfo({ label, text, sx }: FleetOrGroupHeaderInfoProps & SxProps): JSX.Element {
  return (
    <Box sx={{ marginRight: '22px', ...sx, display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontStyle: 'italic' }} variant='columnHeader'>
        {label}
      </Typography>
      <Typography variant='tableText'>{text}</Typography>
    </Box>
  );
}
