import React, { useState } from 'react';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputComponent from './InputComponent';

type InputItemProps = {
  label: string;
  value: string;
  updateValue: (value: string) => void;
  type?: 'password' | 'number' | 'text' | 'tel' | 'email';
};

export default function InputItem({ label, value, updateValue, type }: InputItemProps): JSX.Element {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputComponent label={label}>
      <Input
        sx={{
          backgroundColor: 'black',
          paddingLeft: '13px',
          height: '40px',
          borderRadius: '5px',
          '&:before': {
            borderRadius: '5px',
          },
        }}
        type={type && !showPassword ? type : 'text'}
        onChange={(s: React.ChangeEvent<HTMLInputElement>): void => {
          updateValue(s.target.value);
        }}
        placeholder={value}
        value={value}
        endAdornment={
          type === 'password' && (
            <InputAdornment position='end' sx={{ marginRight: '10px' }}>
              <IconButton onClick={(): void => setShowPassword(!showPassword)} onMouseDown={(): void => setShowPassword(!showPassword)}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }
      />
    </InputComponent>
  );
}
