import React, { useContext, useState } from 'react';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { AddBundlePopup } from './AddBundlePopup';
import { RemoveBundlePopup } from './RemoveBundlePopup';
import { InvoiceDataInvAdmin, InvoiceDataSysAdmin, ROLE_ID_SYSTEM_ADMIN } from '../../../../../model/backendDataModels';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import { AppContext } from '../../../../../App';
import { useUser } from '../../../../../dataHooks/adminHooks';
import { addBundle, deleteBundle } from '../../../../../services/subscriptionManagement';
import { LoginContext } from '../../../../../Login';
import { KeyedMutator } from 'swr';
import { parse } from 'date-fns';

type BundleManagementProps = {
  customerInfo: InvoiceDataInvAdmin;
  mutate: KeyedMutator<InvoiceDataInvAdmin | InvoiceDataSysAdmin>;
};

export default function BundleManagement({ customerInfo, mutate }: BundleManagementProps): JSX.Element {
  const theme = useTheme();

  const appContext = useContext(AppContext);
  const loginContext = useContext(LoginContext);

  const { data, error } = useUser();
  if (error) {
    appContext.addBackendError(error);
  }

  const [updating, setUpdating] = useState(false);

  const [addBundlePopupOpen, setAddBundlePopupOpen] = useState(false);
  const [removeBundlePopupOpen, setRemoveBundlePopupOpen] = useState(false);

  if (!customerInfo.bundles || !Array.isArray(customerInfo.bundles)) {
    return <></>;
  }

  const numberOfBundles = customerInfo.bundles.filter(b => b.endDate === undefined).length;

  const endDatesInFuture =
    customerInfo.bundles.filter(b => {
      if (b.endDate) {
        const endDate = parse(b.endDate, 'yyyy-MM-dd', new Date());
        return endDate >= new Date();
      }
      return false;
    }).length > 0;

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography whiteSpace='nowrap'>Manage your subscriptions</Typography>
      {updating && <LoadingIndicator />}
      {!updating && (
        <Stack direction='column' spacing={1}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button
              sx={{ alignItem: 'center', color: theme.palette.action.active }}
              onClick={(): void => {
                setRemoveBundlePopupOpen(true);
              }}
              disabled={numberOfBundles < 1}
            >
              -
            </Button>
            <Typography whiteSpace='nowrap'>{numberOfBundles}</Typography>
            <Button
              sx={{ alignItem: 'center', color: theme.palette.action.active }}
              onClick={(): void => {
                setAddBundlePopupOpen(true);
              }}
            >
              +
            </Button>
          </Box>
          {endDatesInFuture && (
            <Typography whiteSpace='nowrap'>The number shown represents the number of bundles from next month.</Typography>
          )}
        </Stack>
      )}
      <AddBundlePopup
        open={addBundlePopupOpen}
        bundles={numberOfBundles}
        close={async function (ok: boolean): Promise<void> {
          if (ok) {
            setUpdating(true);
            await addBundle(
              data.roleId === ROLE_ID_SYSTEM_ADMIN ? { quantity: 1, customerId: customerInfo.customerId } : { quantity: 1 },
              loginContext.accessToken || '',
              appContext.addBackendError
            );
            await mutate();
            setUpdating(false);
          }
          setAddBundlePopupOpen(false);
        }}
      />
      <RemoveBundlePopup
        open={removeBundlePopupOpen}
        totalActiveDevices={customerInfo.customers.reduce((partialSum, c) => partialSum + c.activeDevices, 0)}
        bundles={numberOfBundles}
        close={async function (ok: boolean): Promise<void> {
          if (ok) {
            setUpdating(true);
            await deleteBundle(
              data.roleId === ROLE_ID_SYSTEM_ADMIN ? { quantity: 1, customerId: customerInfo.customerId } : { quantity: 1 },
              loginContext.accessToken || '',
              appContext.addBackendError
            );
            await mutate();
            setUpdating(false);
          }
          setRemoveBundlePopupOpen(false);
        }}
      />
    </Box>
  );
}
