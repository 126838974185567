import {
  TreeCustomerFE,
  instanceOfTreeDeviceFE,
  instanceOfTreeFleetGroupFE,
  instanceOfTreeCustomerFE,
  TreeItemFE,
  TreeFleetFE,
  instanceOfTreeFleetFE,
  TreeFleetGroupFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  instanceOfTreeFleetUnassignedFE,
  instanceOfTreeDeviceUnassignedFE,
  compareItems,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  instanceOfTreeGatewayFE,
  instanceOfTreeGatewayUnassignedFE,
} from '../model/frontendDataModels';
import {
  treeFleetIncludesDevice,
  treeFleetHasWarning,
  TreeFleetInfo,
  treeFleetUnassignedIncludes,
  treeFleetDeleteDevices,
  treeFleetAddTreeDevices,
  treeFleetDevice,
  treeFleetGateway,
  treeFleetUnassignedGateway,
  treeFleetIncludesGateway,
} from './treeFleetFunctions';
import {
  treeFleetGroupAddTreeFleetGroup,
  treeFleetGroupAddTreeFleet,
  TreeFleetGroupDeleteTreeFleet,
  treeFleetGroupDeleteTreeFleetGroup,
  treeFleetGroupTreeFleet,
  treeFleetGroupAllFleets,
  treeFleetGroupDevice,
  treeFleetGroupParentTo,
  treeFleetGroupFleetInfo,
  treeFleetGroupIncludes,
  treeFleetGroupHasWarning,
  treeFleetGroupUpdateTreeFleetGroup,
  treeFleetGroupUpdateTreeFleet,
  treeFleetGroupDeleteDevices,
  treeFleetGroupAddTreeDevices,
  treeFleetGroupFindTreeFleetByDevice,
  treeFleetGroupFindById,
  treeFleetGroupFindFleetById,
  treeFleetGroupFindTreeFleetByGateway,
} from './treeFleetGroupFunctions';

export function treeCustomerDevice(treeCustomer: TreeCustomerFE, mui: string): TreeDeviceFE | undefined {
  for (const fleet of treeCustomer.fleets.filter((fleet): fleet is TreeFleetFE => instanceOfTreeFleetFE(fleet))) {
    const device = treeFleetDevice(fleet, mui);
    if (device) {
      return device;
    }
  }
  for (const fg of treeCustomer.fleetgroups) {
    const device = treeFleetGroupDevice(fg, mui);
    if (device) {
      return device;
    }
  }
  for (const child of treeCustomer.children) {
    const device = treeCustomerDevice(child, mui);
    if (device) {
      return device;
    }
  }
  return undefined;
}

export function treeCustomerFindById(treeCustomer: TreeCustomerFE, id: string): TreeCustomerFE | undefined {
  if (treeCustomer.customerId === id) {
    return treeCustomer;
  }
  for (const child of treeCustomer.children) {
    const customer = treeCustomerFindById(child, id);
    if (customer) {
      return customer;
    }
  }
  return undefined;
}

export function treeCustomerFindFleetGroupById(treeCustomer: TreeCustomerFE, id: string): TreeFleetGroupFE | undefined {
  for (const fg of treeCustomer.fleetgroups) {
    const fleetGroup = treeFleetGroupFindById(fg, id);
    if (fleetGroup) {
      return fleetGroup;
    }
  }
  for (const child of treeCustomer.children) {
    const customer = treeCustomerFindFleetGroupById(child, id);
    if (customer) {
      return customer;
    }
  }
  return undefined;
}

export function treeCustomerFindFleetById(treeCustomer: TreeCustomerFE, id: string): TreeFleetFE | undefined {
  for (const f of treeCustomer.fleets) {
    if (instanceOfTreeFleetFE(f) && f.fleetId === id) {
      return f;
    }
  }
  for (const fg of treeCustomer.fleetgroups) {
    const fleet = treeFleetGroupFindFleetById(fg, id);
    if (fleet) {
      return fleet;
    }
  }
  for (const child of treeCustomer.children) {
    const customer = treeCustomerFindFleetById(child, id);
    if (customer) {
      return customer;
    }
  }
  return undefined;
}

export function treeCustomerGetAllCustomers(treeCustomer: TreeCustomerFE): TreeCustomerFE[] {
  let returnArray = [treeCustomer];
  for (const child of treeCustomer.children) {
    const childArray = treeCustomerGetAllCustomers(child);
    returnArray = returnArray.concat(childArray);
  }
  return returnArray;
}

export function treeCustomerIncludes(treeCustomer: TreeCustomerFE, treeItem: TreeItemFE): boolean {
  if (instanceOfTreeCustomerFE(treeItem)) {
    if (treeCustomer.children.find(c => c.customerId === treeItem.customerId || treeCustomerIncludes(c, treeItem))) {
      return true;
    }
  } else if (instanceOfTreeFleetGroupFE(treeItem)) {
    if (treeCustomer.children.find(c => treeCustomerIncludes(c, treeItem))) {
      return true;
    }
    if (treeCustomer.fleetgroups.find(fg => fg.fleetgroupId === treeItem.fleetgroupId || treeFleetGroupIncludes(fg, treeItem))) {
      return true;
    }
  } else if (instanceOfTreeFleetFE(treeItem)) {
    if (treeCustomer.children.find(c => treeCustomerIncludes(c, treeItem))) {
      return true;
    }
    if (treeCustomer.fleetgroups.find(fg => treeFleetGroupIncludes(fg, treeItem))) {
      return true;
    }
    if (treeCustomer.fleets.find(f => instanceOfTreeFleetFE(f) && f.fleetId === treeItem.fleetId)) {
      return true;
    }
  } else if (instanceOfTreeGatewayFE(treeItem)) {
    if (treeCustomer.children.find(c => treeCustomerIncludes(c, treeItem))) {
      return true;
    }
    if (treeCustomer.fleetgroups.find(fg => treeFleetGroupIncludes(fg, treeItem))) {
      return true;
    }
    if (treeCustomer.fleets.find(f => instanceOfTreeFleetFE(f) && treeFleetGateway(f, treeItem.mui))) {
      return true;
    }
  } else {
    if (treeCustomer.children.find(c => treeCustomerIncludes(c, treeItem))) {
      return true;
    }
    if (treeCustomer.fleetgroups.find(fg => treeFleetGroupIncludes(fg, treeItem))) {
      return true;
    }
    if (
      treeCustomer.fleets.find(
        f =>
          (instanceOfTreeFleetFE(f) && instanceOfTreeDeviceFE(treeItem) && treeFleetIncludesDevice(f, treeItem)) ||
          (instanceOfTreeFleetUnassignedFE(f) && instanceOfTreeDeviceUnassignedFE(treeItem) && treeFleetUnassignedIncludes(f, treeItem)) ||
          (instanceOfTreeFleetFE(f) && instanceOfTreeGatewayFE(treeItem) && treeFleetGateway(f, treeItem.mui)) ||
          (instanceOfTreeFleetUnassignedFE(f) && instanceOfTreeGatewayUnassignedFE(treeItem) && treeFleetUnassignedGateway(f, treeItem.mui))
      )
    ) {
      return true;
    }
  }

  return false;
}

export function treeCustomerHasWarning(treeCustomer: TreeCustomerFE): boolean {
  if (treeCustomer.children.findIndex(c => treeCustomerHasWarning(c)) !== -1) {
    return true;
  }
  if (treeCustomer.fleetgroups.findIndex(fg => treeFleetGroupHasWarning(fg)) !== -1) {
    return true;
  }
  return treeCustomer.fleets.findIndex(f => instanceOfTreeFleetFE(f) && treeFleetHasWarning(f)) !== -1;
}

export function treeCustomerTreeFleet(treeCustomer: TreeCustomerFE, fleetId: string): TreeFleetFE | null {
  const treeFleet = treeCustomer.fleets.find(f => instanceOfTreeFleetFE(f) && f.fleetId === fleetId) as TreeFleetFE;
  if (treeFleet) {
    return treeFleet;
  }
  for (const fg of treeCustomer.fleetgroups) {
    const treeFleet = treeFleetGroupTreeFleet(fg, fleetId);
    if (treeFleet) {
      return treeFleet;
    }
  }
  for (const c of treeCustomer.children) {
    const treeFleet = treeCustomerTreeFleet(c, fleetId);
    if (treeFleet) {
      return treeFleet;
    }
  }
  return null;
}

export function treeCustomerAllFleets(treeCustomer: TreeCustomerFE): TreeFleetFE[] {
  let allTreeFleets: TreeFleetFE[] = [];
  treeCustomer.fleets.forEach(f => instanceOfTreeFleetFE(f) && allTreeFleets.push(f));
  treeCustomer.fleetgroups.forEach(fg => {
    const fgFleets = treeFleetGroupAllFleets(fg);
    allTreeFleets = allTreeFleets.concat(fgFleets);
  });
  treeCustomer.children.forEach(c => {
    const fgFleets = treeCustomerAllFleets(c);
    allTreeFleets = allTreeFleets.concat(fgFleets);
  });

  return allTreeFleets;
}

export function treeCustomerTreeFleetInfo(treeCustomer: TreeCustomerFE, fleetId: string): TreeFleetInfo | undefined {
  const treeFleet = treeCustomer.fleets.find(f => instanceOfTreeFleetFE(f) && f.fleetId === fleetId) as TreeFleetFE;
  if (treeFleet) {
    return {
      treeFleet,
      parentCustomer: treeCustomer,
    };
  }
  for (const treeFleetGroup of treeCustomer.fleetgroups) {
    const treeFleetInfo = treeFleetGroupFleetInfo(treeFleetGroup, fleetId);
    if (treeFleetInfo) {
      return treeFleetInfo;
    }
  }
  for (const treeCustomerChild of treeCustomer.children) {
    const treeFleetInfo = treeCustomerTreeFleetInfo(treeCustomerChild, fleetId);
    if (treeFleetInfo) {
      return treeFleetInfo;
    }
  }
  return undefined;
}

export function treeCustomerParentTo(
  rootTreeCustomer: TreeCustomerFE,
  treeItem: TreeCustomerFE | TreeFleetGroupFE | TreeFleetFE | TreeFleetUnassignedFE | TreeFleetUnassignedFE
): TreeCustomerFE | TreeFleetGroupFE | undefined {
  if (instanceOfTreeCustomerFE(treeItem)) {
    if (rootTreeCustomer.customerId === treeItem.customerId) {
      return undefined;
    }

    if (rootTreeCustomer.children.find(c => c.customerId === treeItem.customerId)) {
      return rootTreeCustomer;
    }

    return rootTreeCustomer.children.find(c => treeCustomerParentTo(c, treeItem));
  }
  if (instanceOfTreeFleetGroupFE(treeItem)) {
    if (rootTreeCustomer.fleetgroups.find(fg => fg.fleetgroupId === treeItem.fleetgroupId)) {
      return rootTreeCustomer;
    }
    const fleetGroup = rootTreeCustomer.fleetgroups.find(fg => treeFleetGroupParentTo(fg, treeItem));

    return fleetGroup || rootTreeCustomer.children.find(c => treeCustomerParentTo(c, treeItem));
  }

  if (instanceOfTreeFleetFE(treeItem)) {
    if (rootTreeCustomer.fleets.find(fleet => instanceOfTreeFleetFE(fleet) && fleet.fleetId === treeItem.fleetId)) {
      return rootTreeCustomer;
    }

    const fleetGroup = rootTreeCustomer.fleetgroups.find(fg => treeFleetGroupParentTo(fg, treeItem));
    if (fleetGroup) {
      return fleetGroup;
    }

    let returnValue = undefined;
    for (let i = 0; i < rootTreeCustomer.children.length && !returnValue; i = i + 1) {
      returnValue = treeCustomerParentTo(rootTreeCustomer.children[i], treeItem);
    }

    return returnValue;
  }

  if (instanceOfTreeFleetUnassignedFE(treeItem)) {
    if (
      rootTreeCustomer.fleets.find(
        fleet =>
          instanceOfTreeFleetUnassignedFE(fleet) &&
          fleet.batteries === treeItem.batteries &&
          fleet.chargers === treeItem.chargers &&
          fleet.gateways === treeItem.gateways &&
          fleet.fleetName === treeItem.fleetName
      )
    ) {
      return rootTreeCustomer;
    }

    return rootTreeCustomer.children.find(c => treeCustomerParentTo(c, treeItem));
  }

  return undefined;
}

export function treeCustomerFindTreeFleetByDevice(rootTreeCustomer: TreeCustomerFE, treeDevice: TreeDeviceFE): TreeFleetFE | null {
  const fleet = rootTreeCustomer.fleets
    .filter((f): f is TreeFleetFE => instanceOfTreeFleetFE(f))
    .find(f => treeFleetIncludesDevice(f, treeDevice));
  if (fleet) {
    return fleet;
  }

  for (const fg of rootTreeCustomer.fleetgroups) {
    const fleet = treeFleetGroupFindTreeFleetByDevice(fg, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  for (const c of rootTreeCustomer.children) {
    const fleet = treeCustomerFindTreeFleetByDevice(c, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  return null;
}

export function treeCustomerFindTreeFleetByGateway(rootTreeCustomer: TreeCustomerFE, treeDevice: TreeGatewayFE): TreeFleetFE | null {
  const fleet = rootTreeCustomer.fleets
    .filter((f): f is TreeFleetFE => instanceOfTreeFleetFE(f))
    .find(f => treeFleetIncludesGateway(f, treeDevice));
  if (fleet) {
    return fleet;
  }

  for (const fg of rootTreeCustomer.fleetgroups) {
    const fleet = treeFleetGroupFindTreeFleetByGateway(fg, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  for (const c of rootTreeCustomer.children) {
    const fleet = treeCustomerFindTreeFleetByGateway(c, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  return null;
}

export function treeCustomerAddTreeCustomer(
  rootTreeCustomer: TreeCustomerFE,
  treeCustomer: TreeCustomerFE,
  parentCustomerId: string
): TreeCustomerFE {
  if (rootTreeCustomer.customerId === parentCustomerId) {
    return {
      ...rootTreeCustomer,
      children: rootTreeCustomer.children.concat([treeCustomer]),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerAddTreeCustomer(c, treeCustomer, parentCustomerId)),
  };
}

export function treeCustomerAddTreeFleetGroup(
  rootTreeCustomer: TreeCustomerFE,
  treeFleetGroup: TreeFleetGroupFE,
  parentId: string
): TreeCustomerFE {
  if (rootTreeCustomer.customerId === parentId) {
    return {
      ...rootTreeCustomer,
      fleetgroups: rootTreeCustomer.fleetgroups.concat([treeFleetGroup]),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerAddTreeFleetGroup(c, treeFleetGroup, parentId)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupAddTreeFleetGroup(fg, treeFleetGroup, parentId)),
  };
}

export function treeCustomerAddTreeFleet(rootTreeCustomer: TreeCustomerFE, treeFleet: TreeFleetFE, parentId: string): TreeCustomerFE {
  if (rootTreeCustomer.customerId === parentId) {
    return {
      ...rootTreeCustomer,
      fleets: rootTreeCustomer.fleets.concat([treeFleet]),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerAddTreeFleet(c, treeFleet, parentId)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupAddTreeFleet(fg, treeFleet, parentId)),
  };
}

export function treeCustomerAddTreeDevices(
  rootTreeCustomer: TreeCustomerFE,
  treeDevices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[],
  treeFleet: TreeFleetFE | TreeFleetUnassignedFE
): TreeCustomerFE {
  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerAddTreeDevices(c, treeDevices, treeFleet)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupAddTreeDevices(fg, treeDevices, treeFleet)),
    fleets: rootTreeCustomer.fleets.map(f => (compareItems(f, treeFleet) ? treeFleetAddTreeDevices(f, treeDevices) : f)),
  };
}

export function treeCustomerUpdateTreeCustomer(treeCustomer: TreeCustomerFE, changedTreeCustomer: TreeCustomerFE): TreeCustomerFE {
  if (treeCustomer.customerId === changedTreeCustomer.customerId) {
    return changedTreeCustomer;
  }

  return {
    ...treeCustomer,
    children: treeCustomer.children.map(c => treeCustomerUpdateTreeCustomer(c, changedTreeCustomer)),
  };
}

export function treeCustomerUpdateTreeFleetGroup(treeCustomer: TreeCustomerFE, changedTreeFleetGroup: TreeFleetGroupFE): TreeCustomerFE {
  if (treeCustomer.fleetgroups.find(fg => fg.fleetgroupId === changedTreeFleetGroup.fleetgroupId)) {
    return {
      ...treeCustomer,
      fleetgroups: treeCustomer.fleetgroups.map(fg =>
        fg.fleetgroupId === changedTreeFleetGroup.fleetgroupId ? changedTreeFleetGroup : fg
      ),
    };
  }

  return {
    ...treeCustomer,
    children: treeCustomer.children.map(c => treeCustomerUpdateTreeFleetGroup(c, changedTreeFleetGroup)),
    fleetgroups: treeCustomer.fleetgroups.map(fg => treeFleetGroupUpdateTreeFleetGroup(fg, changedTreeFleetGroup)),
  };
}

export function treeCustomerUpdateTreeFleet(rootTreeCustomer: TreeCustomerFE, treeFleet: TreeFleetFE): TreeCustomerFE {
  const oldTreeFleetIndex = rootTreeCustomer.fleets.findIndex(f => instanceOfTreeFleetFE(f) && f.fleetId === treeFleet.fleetId);
  if (oldTreeFleetIndex !== -1) {
    const fleets = [...rootTreeCustomer.fleets];
    fleets[oldTreeFleetIndex] = treeFleet;
    return {
      ...rootTreeCustomer,
      fleets,
    };
  }

  const fleetgroups = rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupUpdateTreeFleet(fg, treeFleet));
  const children = rootTreeCustomer.children.map(c => treeCustomerUpdateTreeFleet(c, treeFleet));

  return {
    ...rootTreeCustomer,
    fleetgroups,
    children,
  };
}

export function treeCustomerDeleteTreeCustomer(rootTreeCustomer: TreeCustomerFE, customerToDelete: TreeCustomerFE): TreeCustomerFE {
  if (rootTreeCustomer.children.find(c => c.customerId === customerToDelete.customerId)) {
    return {
      ...rootTreeCustomer,
      children: rootTreeCustomer.children.filter(c => c.customerId !== customerToDelete.customerId),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerDeleteTreeCustomer(c, customerToDelete)),
  };
}

export function treeCustomerDeleteTreeFleetGroup(rootTreeCustomer: TreeCustomerFE, fleetGroupToDelete: TreeFleetGroupFE): TreeCustomerFE {
  if (rootTreeCustomer.fleetgroups.find(fg => fg.fleetgroupId === fleetGroupToDelete.fleetgroupId)) {
    return {
      ...rootTreeCustomer,
      fleetgroups: rootTreeCustomer.fleetgroups.filter(fg => fg.fleetgroupId !== fleetGroupToDelete.fleetgroupId),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerDeleteTreeFleetGroup(c, fleetGroupToDelete)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupDeleteTreeFleetGroup(fg, fleetGroupToDelete)),
  };
}

export function treeCustomerDeleteTreeFleet(rootTreeCustomer: TreeCustomerFE, fleetToDelete: TreeFleetFE): TreeCustomerFE {
  if (rootTreeCustomer.fleets.find(f => instanceOfTreeFleetFE(f) && f.fleetId === fleetToDelete.fleetId)) {
    return {
      ...rootTreeCustomer,
      fleets: rootTreeCustomer.fleets.filter(f => !(instanceOfTreeFleetFE(f) && f.fleetId === fleetToDelete.fleetId)),
    };
  }

  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerDeleteTreeFleet(c, fleetToDelete)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => TreeFleetGroupDeleteTreeFleet(fg, fleetToDelete)),
  };
}

export function treeCustomerDeleteDevices(
  rootTreeCustomer: TreeCustomerFE,
  devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[]
): TreeCustomerFE {
  return {
    ...rootTreeCustomer,
    children: rootTreeCustomer.children.map(c => treeCustomerDeleteDevices(c, devices)),
    fleetgroups: rootTreeCustomer.fleetgroups.map(fg => treeFleetGroupDeleteDevices(fg, devices)),
    fleets: rootTreeCustomer.fleets.map(f => treeFleetDeleteDevices(f, devices)),
  };
}

export function treeCustomerMoveTreeCustomer(
  rootTreeCustomer: TreeCustomerFE,
  toMove: TreeCustomerFE,
  newParent: TreeCustomerFE
): TreeCustomerFE {
  const removed = treeCustomerDeleteTreeCustomer(rootTreeCustomer, toMove);
  return treeCustomerAddTreeCustomer(removed, toMove, newParent.customerId);
}

export function treeCustomerMoveTreeFleetGroup(
  rootTreeCustomer: TreeCustomerFE,
  toMove: TreeFleetGroupFE,
  newParent: TreeCustomerFE | TreeFleetGroupFE
): TreeCustomerFE {
  const removed = treeCustomerDeleteTreeFleetGroup(rootTreeCustomer, toMove);
  return treeCustomerAddTreeFleetGroup(
    removed,
    toMove,
    instanceOfTreeCustomerFE(newParent) ? newParent.customerId : newParent.fleetgroupId
  );
}

export function treeCustomerMoveTreeFleet(
  rootTreeCustomer: TreeCustomerFE,
  toMove: TreeFleetFE,
  newParent: TreeCustomerFE | TreeFleetGroupFE
): TreeCustomerFE {
  const removed = treeCustomerDeleteTreeFleet(rootTreeCustomer, toMove);
  return treeCustomerAddTreeFleet(removed, toMove, instanceOfTreeCustomerFE(newParent) ? newParent.customerId : newParent.fleetgroupId);
}

export function treeCustomerMoveTreeDevices(
  rootTreeCustomer: TreeCustomerFE,
  devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[],
  newParent: TreeFleetFE | TreeFleetUnassignedFE
): TreeCustomerFE {
  const removed = treeCustomerDeleteDevices(rootTreeCustomer, devices);
  return treeCustomerAddTreeDevices(removed, devices, newParent);
}
