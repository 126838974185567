import React, { useState } from 'react';
import FleetDeviceSearch from './FleetDeviceSearch';
import TopCustomerTree from './TopCustomerTree';
import { TreeCustomerFE, TreeItemFE } from '../../../model/frontendDataModels';

type TreeContextValues = {
  selectedTreeItems: TreeItemFE[];
  selectTreeItems: (selectedTreeItems: TreeItemFE[]) => void;
  canSelect: (item: TreeItemFE) => boolean;
  wideMode: boolean;
  expandTree: (expandableTreeItem: string) => void;
  collapseTree: (expandableTreeItem: string) => void;
  expandedTreeItemIds: string[];
  includeDevices: boolean;
};

export const TreeContext = React.createContext<TreeContextValues>({
  selectedTreeItems: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  selectTreeItems: () => {},
  canSelect: () => false,
  wideMode: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  expandTree: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  collapseTree: () => {},
  expandedTreeItemIds: [],
  includeDevices: true,
});

type Props = {
  rootTreeCustomer: TreeCustomerFE;
  canSelect: (treeItem: TreeItemFE) => boolean;
  selectedTreeItems: TreeItemFE[];
  selectTreeItems: (selectedTreeItems: TreeItemFE[]) => void;
  onlyCustomers: boolean;
  includeDevices: boolean;
  includeUnassigned: boolean;
  wideMode: boolean;
};

function SearchableTree({
  rootTreeCustomer,
  canSelect,
  selectedTreeItems,
  selectTreeItems,
  onlyCustomers,
  includeDevices,
  includeUnassigned,
  wideMode,
}: Props): JSX.Element {
  const [expandedTreeItemIds, setExpandedTreeItemIds] = useState<string[]>([]);

  function expandTree(expandableTreeItem: string): void {
    if (!expandedTreeItemIds.includes(expandableTreeItem)) {
      setExpandedTreeItemIds(expandedTreeItemIds.concat([expandableTreeItem]));
    }
  }

  function collapseTree(expandableTreeItem: string): void {
    setExpandedTreeItemIds(expandedTreeItemIds.filter(elem => elem !== expandableTreeItem));
  }

  return (
    <>
      <FleetDeviceSearch
        selectedTreeItems={selectedTreeItems}
        selectTreeItems={selectTreeItems}
        includeUnassigned={includeUnassigned === true}
        canSelect={canSelect}
      />
      <TreeContext.Provider
        value={{
          selectedTreeItems,
          selectTreeItems,
          canSelect,
          wideMode,
          expandTree,
          collapseTree,
          expandedTreeItemIds,
          includeDevices,
        }}
      >
        <TopCustomerTree rootTreeCustomer={rootTreeCustomer} includeUnassigned={includeUnassigned} onlyCustomers={onlyCustomers} />
      </TreeContext.Provider>
    </>
  );
}

export default SearchableTree;
