import React, { useContext, useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import InfoDialog from '../common/InfoDialog';
import LoadingIndicator from '../common/LoadingIndicator';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeDeviceFE,
  instanceOfTreeDeviceUnassignedFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  instanceOfTreeFleetUnassignedFE,
  instanceOfTreeGatewayFE,
  instanceOfTreeGatewayUnassignedFE,
  TreeCustomerFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../../model/frontendDataModels';
import { customerChangeParent } from '../../services/customerManipulation';
import {
  treeCustomerMoveTreeCustomer,
  treeCustomerMoveTreeDevices,
  treeCustomerMoveTreeFleet,
  treeCustomerMoveTreeFleetGroup,
} from '../../utils/treeCustomerFunctions';
import { fleetGroupChangeParent } from '../../services/fleetGroupManipulation';
import { deviceChangeParent } from '../../services/deviceManipulation';
import { fleetChangeParent } from '../../services/fleetManipulation';
import { LoginContext } from '../../Login';
import { AppContext } from '../../App';
import { BackendError } from '../../utils/BackendError';
import ArrangeButton from './addEditDeleteFooter/common/ArrangeButton';

function isDevice(item: TreeItemFE): boolean {
  return (
    instanceOfTreeDeviceFE(item) ||
    instanceOfTreeDeviceUnassignedFE(item) ||
    instanceOfTreeGatewayFE(item) ||
    instanceOfTreeGatewayUnassignedFE(item)
  );
}

type UserInfoData = {
  title: string;
  body: string;
};

type TransferButtonProps = {
  leftSelectedTreeItems: Exclude<TreeItemFE, TreeFleetUnassignedFE>[];
  rightSelectedTreeItem: Exclude<TreeItemFE, TreeDeviceFE | TreeDeviceUnassignedFE> | undefined;
  clearLeftSelectedTreeItems: () => void;
};

export default function TransferButton({
  leftSelectedTreeItems,
  rightSelectedTreeItem,
  clearLeftSelectedTreeItems,
}: TransferButtonProps): JSX.Element {
  const [userInfo, setUserInfo] = useState<UserInfoData | undefined>(undefined);
  const [awaitingResult, setAwaitingResult] = useState<boolean>(false);

  const [moveCustomerPossible, setMoveCustomerPossible] = useState<boolean>(false);
  const [moveFleetGroupPossible, setMoveFleetGroupPossible] = useState<boolean>(false);
  const [moveFleetPossible, setMoveFleetPossible] = useState<boolean>(false);
  const [moveDevicesPossible, setMoveDevicesPossible] = useState<boolean>(false);

  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    setMoveCustomerPossible(
      appContext.rootTreeCustomer !== undefined &&
        leftSelectedTreeItems.length === 1 &&
        rightSelectedTreeItem !== undefined &&
        instanceOfTreeCustomerFE(leftSelectedTreeItems[0]) &&
        instanceOfTreeCustomerFE(rightSelectedTreeItem) &&
        leftSelectedTreeItems[0].customerId !== rightSelectedTreeItem.customerId &&
        leftSelectedTreeItems[0].customerId !== appContext.rootTreeCustomer.customerId &&
        rightSelectedTreeItem.children.findIndex(
          c => instanceOfTreeCustomerFE(leftSelectedTreeItems[0]) && c.customerId === leftSelectedTreeItems[0].customerId
        ) === -1
    );

    setMoveFleetGroupPossible(
      appContext.rootTreeCustomer !== undefined &&
        leftSelectedTreeItems.length === 1 &&
        rightSelectedTreeItem !== undefined &&
        instanceOfTreeFleetGroupFE(leftSelectedTreeItems[0]) &&
        ((instanceOfTreeFleetGroupFE(rightSelectedTreeItem) &&
          leftSelectedTreeItems[0].fleetgroupId !== rightSelectedTreeItem.fleetgroupId &&
          rightSelectedTreeItem.fleetgroups.findIndex(
            fg => instanceOfTreeFleetGroupFE(leftSelectedTreeItems[0]) && fg.fleetgroupId === leftSelectedTreeItems[0].fleetgroupId
          ) === -1) ||
          instanceOfTreeCustomerFE(rightSelectedTreeItem))
    );

    setMoveFleetPossible(
      appContext.rootTreeCustomer !== undefined &&
        leftSelectedTreeItems.length === 1 &&
        rightSelectedTreeItem !== undefined &&
        instanceOfTreeFleetFE(leftSelectedTreeItems[0]) &&
        (instanceOfTreeCustomerFE(rightSelectedTreeItem) || instanceOfTreeFleetGroupFE(rightSelectedTreeItem)) &&
        rightSelectedTreeItem.fleets.findIndex(
          f => instanceOfTreeFleetFE(leftSelectedTreeItems[0]) && instanceOfTreeFleetFE(f) && f.fleetId === leftSelectedTreeItems[0].fleetId
        ) === -1
    );

    setMoveDevicesPossible(
      appContext.rootTreeCustomer !== undefined &&
        rightSelectedTreeItem !== undefined &&
        (instanceOfTreeFleetFE(rightSelectedTreeItem) || instanceOfTreeFleetUnassignedFE(rightSelectedTreeItem)) &&
        leftSelectedTreeItems.length > 0 &&
        leftSelectedTreeItems.findIndex(d => !isDevice(d)) === -1
    );
  }, [appContext.rootTreeCustomer, leftSelectedTreeItems, rightSelectedTreeItem]);

  async function moveCustomer(customer: TreeCustomerFE, newParent: TreeCustomerFE): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: '${customer.customerName} has NOT been moved',
      };
    }
    const result = await customerChangeParent(
      customer.customerId,
      { parentId: newParent.customerId },
      loginContext.accessToken,
      appContext.addBackendError
    );
    if (result && appContext.rootTreeCustomer) {
      appContext.updateRootTreeCustomer(treeCustomerMoveTreeCustomer(appContext.rootTreeCustomer, customer, newParent));
      clearLeftSelectedTreeItems();
    }
    return {
      title: result ? 'Success' : 'Failure',
      body: result ? `${customer.customerName} has been moved to ${newParent.customerName}` : `${customer.customerName} has NOT been moved`,
    };
  }

  async function moveFleetGroup(fleetGroup: TreeFleetGroupFE, newParent: TreeCustomerFE | TreeFleetGroupFE): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: '${customer.customerName} has NOT been moved',
      };
    }
    const result = await fleetGroupChangeParent(
      fleetGroup.fleetgroupId,
      {
        parentId: instanceOfTreeCustomerFE(newParent) ? newParent.customerId : newParent.fleetgroupId,
        isParentFleetgroup: instanceOfTreeFleetGroupFE(newParent),
      },
      loginContext.accessToken,
      appContext.addBackendError
    );
    if (result && appContext.rootTreeCustomer) {
      appContext.updateRootTreeCustomer(treeCustomerMoveTreeFleetGroup(appContext.rootTreeCustomer, fleetGroup, newParent));
      clearLeftSelectedTreeItems();
    }
    return {
      title: result ? 'Success' : 'Failure',
      body: result
        ? `${fleetGroup.fleetgroupName} has been moved to ${
            instanceOfTreeCustomerFE(newParent) ? newParent.customerName : newParent.fleetgroupName
          }`
        : `${fleetGroup.fleetgroupName} has NOT been moved`,
    };
  }

  async function moveFleet(fleet: TreeFleetFE, newParent: TreeCustomerFE | TreeFleetGroupFE): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: '${customer.customerName} has NOT been moved',
      };
    }
    const result = await fleetChangeParent(
      fleet.fleetId,
      {
        parentId: instanceOfTreeCustomerFE(newParent) ? newParent.customerId : newParent.fleetgroupId,
        isParentFleetgroup: instanceOfTreeFleetGroupFE(newParent),
      },
      loginContext.accessToken,
      appContext.addBackendError
    );
    if (result && appContext.rootTreeCustomer) {
      appContext.updateRootTreeCustomer(treeCustomerMoveTreeFleet(appContext.rootTreeCustomer, fleet, newParent));
      clearLeftSelectedTreeItems();
    }
    return {
      title: result ? 'Success' : 'Failure',
      body: result
        ? `${fleet.fleetName} has been moved to ${instanceOfTreeCustomerFE(newParent) ? newParent.customerName : newParent.fleetgroupName}`
        : `${fleet.fleetName} has NOT been moved`,
    };
  }

  async function moveDevices(
    devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[],
    newParent: TreeFleetFE | TreeFleetUnassignedFE
  ): Promise<UserInfoData> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return {
        title: 'Failure',
        body: 'Devices was not moved',
      };
    }

    const initial: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[][] = [];
    const deviceLists = devices.reduce((accList, current) => {
      const list = accList.find(l => l.length > 0 && l[0].productCategory === current.productCategory);
      if (list) {
        list.push(current);
      } else {
        accList.push([current]);
      }

      return accList;
    }, initial);

    const results = await Promise.all(
      deviceLists.map(async deviceList => {
        const result = await deviceChangeParent(
          deviceList[0].productCategory,
          deviceList.map(device => device.mui),
          {
            parentId: instanceOfTreeFleetFE(newParent) ? newParent.fleetId : '',
          },
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          loginContext.accessToken!,
          appContext.addBackendError
        );
        if (result) {
          return deviceList;
        } else {
          return [];
        }
      })
    );

    const movedDevices = results.flat();

    if (movedDevices.length > 0 && appContext.rootTreeCustomer) {
      appContext.updateRootTreeCustomer(treeCustomerMoveTreeDevices(appContext.rootTreeCustomer, movedDevices, newParent));
      clearLeftSelectedTreeItems();
    }
    return {
      title: movedDevices.length === devices.length ? 'Success' : 'Failure',
      body:
        movedDevices.length === devices.length
          ? `${movedDevices.length} devices has been moved to ${newParent.fleetName}`
          : `${movedDevices.length} of ${devices.length} devices was moved to ${
              instanceOfTreeFleetFE(newParent) ? newParent.fleetName : `Unassigned of ${newParent.customerId}`
            }`,
    };
  }

  async function transfer(): Promise<UserInfoData> {
    if (moveCustomerPossible) {
      return await moveCustomer(leftSelectedTreeItems[0] as TreeCustomerFE, rightSelectedTreeItem as TreeCustomerFE);
    }
    if (moveFleetGroupPossible) {
      return await moveFleetGroup(leftSelectedTreeItems[0] as TreeFleetGroupFE, rightSelectedTreeItem as TreeCustomerFE | TreeFleetGroupFE);
    }
    if (moveFleetPossible) {
      return await moveFleet(leftSelectedTreeItems[0] as TreeFleetFE, rightSelectedTreeItem as TreeCustomerFE | TreeFleetGroupFE);
    }
    if (moveDevicesPossible) {
      return await moveDevices(leftSelectedTreeItems as (TreeDeviceFE | TreeDeviceUnassignedFE)[], rightSelectedTreeItem as TreeFleetFE);
    }
    return {
      title: 'Failure',
      body: 'Unknown error!',
    };
  }

  return (
    <Paper sx={{ padding: '20px', width: '100%', borderTop: '2px solid black', borderBottom: '2px solid black' }}>
      {!awaitingResult && (
        <ArrangeButton
          title='→ Transfer →'
          onClick={async (): Promise<void> => {
            setAwaitingResult(true);
            setUserInfo(await transfer());
            setAwaitingResult(false);
          }}
          disabled={!moveCustomerPossible && !moveFleetGroupPossible && !moveFleetPossible && !moveDevicesPossible}
        />
      )}
      {awaitingResult && <LoadingIndicator />}

      {userInfo && (
        <InfoDialog open={!!userInfo} close={(): void => setUserInfo(undefined)} title={userInfo.title}>
          <Typography variant='tableText'>{userInfo.body}</Typography>
        </InfoDialog>
      )}
    </Paper>
  );
}
