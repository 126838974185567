import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { InvoiceDataInvAdmin, InvoiceDataSysAdmin, ROLE_ID_INVOICE_ADMIN, ROLE_ID_SYSTEM_ADMIN } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import { useInvoiceData } from '../../../dataHooks/adminHooks';
import { ShowInvoiceDetails } from './showInvoiceDetails/ShowInvoiceDetails';
import LoadingIndicator from '../../common/LoadingIndicator';
import InvoiceSelectCustomer from './InvoiceSelectCustomer';
import { TreeCustomerFE } from '../../../model/frontendDataModels';

function InvoiceDetailsTabInvoiceAdmin(): JSX.Element {
  const appContext = useContext(AppContext);

  const { data, error, mutate } = useInvoiceData();
  if (error) {
    appContext.addBackendError(error);
  }
  if (!data) {
    return <></>;
  }

  return <ShowInvoiceDetails invoiceDataInvAdmins={[data as InvoiceDataInvAdmin]} mutate={mutate} />;
}

function InvoiceDetailsTabSystemAdmin(): JSX.Element {
  const appContext = useContext(AppContext);

  const [selectedCustomer, setSelectedCustomer] = useState<TreeCustomerFE | undefined>();
  const [customerIdsToView, setCustomerIdsToView] = useState<string[]>([]);

  useEffect(() => {
    if (selectedCustomer && appContext.rootTreeCustomer && data) {
      if (selectedCustomer.customerId === appContext.rootTreeCustomer.customerId) {
        setCustomerIdsToView((data as InvoiceDataSysAdmin).map(id => id.customerId));
      } else {
        setCustomerIdsToView([selectedCustomer.customerId]);
      }
    } else {
      setCustomerIdsToView([]);
    }
  }, [selectedCustomer]);

  const { data, error, mutate } = useInvoiceData();
  if (error) {
    appContext.addBackendError(error);
  }

  if (!data) {
    return <></>;
  }
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          flexShrink: 0,
        }}
      >
        {!data && !error && <LoadingIndicator />}
        {data && !error && (
          <InvoiceSelectCustomer
            invoiceCustomerIds={(data as InvoiceDataSysAdmin).map(id => id.customerId)}
            updateSelectedCustomer={setSelectedCustomer}
          />
        )}
      </Box>
      {customerIdsToView.length > 0 && (
        <ShowInvoiceDetails
          invoiceDataInvAdmins={(data as InvoiceDataSysAdmin).filter(id => customerIdsToView.includes(id.customerId))}
          mutate={mutate}
        />
      )}
    </>
  );
}

type InvoiceDetailsTabProps = {
  roleId: typeof ROLE_ID_INVOICE_ADMIN | typeof ROLE_ID_SYSTEM_ADMIN;
};

export default function InvoiceDetailsTab({ roleId }: InvoiceDetailsTabProps): JSX.Element {
  const appContext = useContext(AppContext);
  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderTop: '2px solid black',
      }}
    >
      {roleId === ROLE_ID_INVOICE_ADMIN && appContext.rootTreeCustomer && <InvoiceDetailsTabInvoiceAdmin />}
      {roleId === ROLE_ID_SYSTEM_ADMIN && <InvoiceDetailsTabSystemAdmin />}
    </Box>
  );
}
