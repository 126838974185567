import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useEffect, useContext, useState, useRef } from 'react';
import { instanceOfTreeFleetGroupFE, TreeFleetGroupFE, TreeFleetFE } from '../../model/frontendDataModels';
import Box from '@mui/material/Box';
import { treeCustomerAllFleets } from '../../utils/treeCustomerFunctions';
import { treeFleetGroupAllFleets } from '../../utils/treeFleetGroupFunctions';
import { AppContext } from '../../App';
import mapboxgl from 'mapbox-gl';
import Map, { Marker, NavigationControl, useMap } from 'react-map-gl';
import { treeFleetHasWarning } from '../../utils/treeFleetFunctions';
import { useDebounceCallback, useResizeObserver } from 'usehooks-ts';

type Size = {
  width?: number;
  height?: number;
};

function fleetMapSvgIcon(color: string): string {
  const svgStr = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="36" height="60">

  <g transform="matrix(3.7974687 0 0 3.7974687 -0 1.9936695)">
    <g>
      <g>
        <g>
            <path d="M9.48 4.81L9.48 4.67C 9.4413395 2.0521703 7.2878294 -0.03866005 4.6699996 0C 2.0521703 0.03866005 -0.03866005
            2.1921704 0 4.81C 0 8.4 4.74 14.75 4.74 14.75C 4.74 14.75 9.48 8.65 9.48 4.8100004L9.48 4.8100004L9.48 4.81zM4.74 6.56C
            3.7127504 6.5600004 2.88 5.7272496 2.88 4.7C 2.8800006 3.6727502 3.712751 2.8400002 4.7400007 2.8400002C 5.76725 2.8400006
            6.6000004 3.6727505 6.6 4.7C 6.6000004 5.193303 6.4040365 5.6664014 6.0552187 6.0152187C 5.706401 6.3640366 5.233303 6.5600004
            4.74 6.56" stroke="none" fill="${color}" fill-rule="nonzero" />
        </g>
      </g>
    </g>
  </g>
  </svg>`;
  return `data:image/svg+xml;base64,${btoa(svgStr)}`;
}

type SelectableMarker = {
  lat: number;
  lng: number;
  select: () => void;
  imgSrc: string;
};

type MapHandlerProps = {
  selectableMarkers: SelectableMarker[];
  width: number;
  height: number;
};

function MapHandler({ selectableMarkers, width, height }: MapHandlerProps): JSX.Element {
  const map = useMap();

  function updateMap(): void {
    if (map.current) {
      try {
        if (selectableMarkers.length > 1) {
          const locations = selectableMarkers.map(selectableMarker => {
            return { lat: selectableMarker.lat, lng: selectableMarker.lng };
          });
          if (locations.length > 1) {
            const bounds = mapboxgl.LngLatBounds.convert([
              Math.min(...locations.map(l => l.lng)),
              Math.min(...locations.map(l => l.lat)),
              Math.max(...locations.map(l => l.lng)),
              Math.max(...locations.map(l => l.lat)),
            ]);

            map.current.fitBounds(bounds, { maxZoom: 15, padding: 80 });
          }
        } else if (selectableMarkers.length === 1) {
          map.current.flyTo({ center: { lat: selectableMarkers[0].lat, lon: selectableMarkers[0].lng }, zoom: 15 });
        } else {
          map.current.flyTo({ center: { lat: 52.871956, lon: -1.419883 }, zoom: 0 });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(JSON.stringify(error));
      }
    }
  }

  useEffect(() => {
    if (map.current) {
      map.current.resize();
      updateMap();
    }
  }, [width, height]);

  useEffect(() => {
    updateMap();
  }, [selectableMarkers]);

  const markers = selectableMarkers.map((selectableMarker, index) => {
    return (
      <Marker
        key={index}
        longitude={selectableMarker.lng}
        latitude={selectableMarker.lat}
        anchor='bottom'
        onClick={(): void => {
          selectableMarker.select();
        }}
      >
        <img src={selectableMarker.imgSrc} />
      </Marker>
    );
  });

  return <>{markers}</>;
}

type MapViewProps = {
  selectedTreeItem: TreeFleetGroupFE | TreeFleetFE | undefined;
  selectFleet: (treeFleet: TreeFleetFE) => void;
};

export default function MapView({ selectedTreeItem, selectFleet }: MapViewProps): JSX.Element {
  const appContext = useContext(AppContext);
  const ref = useRef<HTMLDivElement>(null);
  const [{ width, height }, setSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  const onResize = useDebounceCallback(setSize, 0);

  useResizeObserver({
    ref,
    onResize,
  });

  const [selectableMarkers, setSelectableMarkers] = useState<SelectableMarker[]>([]);

  useEffect(() => {
    if (appContext.rootTreeCustomer) {
      let fleetsWithLocation: TreeFleetFE[] = [];
      if (selectedTreeItem) {
        if (instanceOfTreeFleetGroupFE(selectedTreeItem)) {
          fleetsWithLocation = treeFleetGroupAllFleets(selectedTreeItem).filter(fleet => fleet.lat && fleet.lng);
        } else {
          if (selectedTreeItem.lat && selectedTreeItem.lng) {
            fleetsWithLocation = [selectedTreeItem];
          }
        }
      } else {
        fleetsWithLocation = treeCustomerAllFleets(appContext.rootTreeCustomer).filter(fleet => fleet.lat && fleet.lng);
      }

      setSelectableMarkers(
        fleetsWithLocation.map(fleet => {
          return {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            lat: fleet.lat!,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            lng: fleet.lng!,
            select: (): void => selectFleet(fleet),
            imgSrc: fleetMapSvgIcon(fleet.activated ? (treeFleetHasWarning(fleet) ? '#DC2F2F' : '#00BA70') : '#D5D5D5'),
          };
        })
      );
    }
  }, [selectedTreeItem, appContext.rootTreeCustomer]);

  const mapBoxUsername = process.env.REACT_APP_MAPBOX_USERNAME || '';
  const mapBoxStyleId = process.env.REACT_APP_MAPBOX_STYLE_ID || '';
  const mapBoxAccessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '';

  const mapBoxUrl = `https://api.mapbox.com/styles/v1/${mapBoxUsername}/${mapBoxStyleId}?access_token=${mapBoxAccessToken}`;

  return (
    <Box ref={ref} sx={{ flexGrow: 1 }}>
      <Map reuseMaps style={{ height: '100vh' }} mapStyle={mapBoxUrl}>
        <MapHandler selectableMarkers={selectableMarkers} width={width ?? 0} height={height ?? 0} />
        <NavigationControl />
      </Map>
    </Box>
  );
}
