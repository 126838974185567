import React, { useState, useContext } from 'react';
import { AppContext } from '../../../../App';
import { LoginContext } from '../../../../Login';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';
import { deleteCustomer } from '../../../../services/customerManipulation';
import { deleteDevice } from '../../../../services/deviceManipulation';
import { deleteFleetGroup } from '../../../../services/fleetGroupManipulation';
import { deleteFleet } from '../../../../services/fleetManipulation';
import { BackendError } from '../../../../utils/BackendError';
import {
  treeCustomerDeleteTreeCustomer,
  treeCustomerDeleteTreeFleetGroup,
  treeCustomerDeleteTreeFleet,
  treeCustomerDeleteDevices,
} from '../../../../utils/treeCustomerFunctions';
import DeleteDialog from '../../../common/DeleteDialog';
import ArrangeButton from '../common/ArrangeButton';

type Props = {
  selectedTreeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>;
  selectedDevices?: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[];
  removeSelectedTreeItem: () => void;
};

export default function DeleteButton({ selectedTreeItem, selectedDevices, removeSelectedTreeItem }: Props): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const appContext = useContext(AppContext);

  if (!appContext.rootTreeCustomer) {
    return null;
  }

  async function deleteTreeItem(item: TreeItemFE): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (instanceOfTreeCustomerFE(item)) {
      if (await deleteCustomer(item.customerId, loginContext.accessToken, appContext.addBackendError)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appContext.updateRootTreeCustomer(treeCustomerDeleteTreeCustomer(appContext.rootTreeCustomer!, item));
        removeSelectedTreeItem();
      }
    }
    if (instanceOfTreeFleetGroupFE(item)) {
      if (await deleteFleetGroup(item.fleetgroupId, loginContext.accessToken, appContext.addBackendError)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appContext.updateRootTreeCustomer(treeCustomerDeleteTreeFleetGroup(appContext.rootTreeCustomer!, item));
        removeSelectedTreeItem();
      }
    }
    if (instanceOfTreeFleetFE(item)) {
      if (await deleteFleet(item.fleetId, loginContext.accessToken, appContext.addBackendError)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        appContext.updateRootTreeCustomer(treeCustomerDeleteTreeFleet(appContext.rootTreeCustomer!, item));
        removeSelectedTreeItem();
      }
    }
  }

  async function deleteTreeDevices(
    devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[]
  ): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    const allResults = await Promise.all(
      devices.map(async d => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return { device: d, result: await deleteDevice(d.productCategory, d.mui, loginContext.accessToken!, appContext.addBackendError) };
      })
    );

    const devicesRemoved: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[] = [];

    allResults.forEach(r => {
      if (r.result) {
        devicesRemoved.push(r.device);
      }
    });
    if (devicesRemoved.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      appContext.updateRootTreeCustomer(treeCustomerDeleteDevices(appContext.rootTreeCustomer!, devicesRemoved));
    }
    removeSelectedTreeItem();
  }

  return (
    <>
      <ArrangeButton
        title='Delete'
        onClick={(): void => {
          if (selectedTreeItem || selectedDevices) {
            setDeletePopupOpen(true);
          }
        }}
        disabled={
          !(
            selectedTreeItem &&
            (instanceOfTreeCustomerFE(selectedTreeItem) ||
              (instanceOfTreeFleetGroupFE(selectedTreeItem) &&
                selectedTreeItem.fleetgroups.length === 0 &&
                selectedTreeItem.fleets.length === 0) ||
              (instanceOfTreeFleetFE(selectedTreeItem) &&
                selectedTreeItem.batteries.length === 0 &&
                selectedTreeItem.chargers.length === 0 &&
                selectedTreeItem.gateways.length === 0 &&
                !selectedTreeItem.demo))
          ) && !selectedDevices
        }
      />
      {selectedTreeItem &&
        (instanceOfTreeCustomerFE(selectedTreeItem) ||
          instanceOfTreeFleetGroupFE(selectedTreeItem) ||
          instanceOfTreeFleetFE(selectedTreeItem)) && (
          <DeleteDialog
            nameToDelete={
              instanceOfTreeCustomerFE(selectedTreeItem)
                ? selectedTreeItem.customerName
                : instanceOfTreeFleetGroupFE(selectedTreeItem)
                ? selectedTreeItem.fleetgroupName
                : selectedTreeItem.fleetName
            }
            close={async (ok: boolean): Promise<void> => {
              if (ok) {
                await deleteTreeItem(selectedTreeItem);
              }
              setDeletePopupOpen(false);
            }}
            open={deletePopupOpen}
          />
        )}
      {selectedDevices && selectedDevices.length > 0 && (
        <DeleteDialog
          nameToDelete={
            selectedDevices.length > 1
              ? `${selectedDevices.length} devices`
              : selectedDevices[0].deviceId || selectedDevices[0].serialNumber || selectedDevices[0].type
          }
          close={async (ok: boolean): Promise<void> => {
            if (ok) {
              await deleteTreeDevices(selectedDevices);
            }
            setDeletePopupOpen(false);
          }}
          open={deletePopupOpen}
        />
      )}
    </>
  );
}
