import {
  compareItems,
  instanceOfTreeFleetFE,
  TreeCustomerFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
} from '../model/frontendDataModels';

export type TreeFleetInfo = {
  treeFleet: TreeFleetFE;
  parentCustomer?: TreeCustomerFE;
  parentFleetGroup?: TreeFleetGroupFE;
};

export function treeFleetDevice(rootTreeFleet: TreeFleetFE, mui: string): TreeDeviceFE | undefined {
  return rootTreeFleet.batteries.find(d => d.mui === mui) || rootTreeFleet.chargers.find(d => d.mui === mui);
}

export function treeFleetGateway(rootTreeFleet: TreeFleetFE, mui: string): TreeGatewayFE | undefined {
  return rootTreeFleet.gateways.find(d => d.mui === mui);
}

export function treeFleetIncludesDevice(rootTreeFleet: TreeFleetFE, treeItem: TreeDeviceFE): boolean {
  return treeFleetDevice(rootTreeFleet, treeItem.mui) !== undefined;
}

export function treeFleetIncludesGateway(rootTreeFleet: TreeFleetFE, treeItem: TreeGatewayFE): boolean {
  return treeFleetGateway(rootTreeFleet, treeItem.mui) !== undefined;
}

function treeFleetUnassignedDevice(rootTreeFleetUnassigned: TreeFleetUnassignedFE, mui: string): TreeDeviceUnassignedFE | undefined {
  return rootTreeFleetUnassigned.batteries.find(d => d.mui === mui) || rootTreeFleetUnassigned.chargers.find(d => d.mui === mui);
}

export function treeFleetUnassignedGateway(
  rootTreeFleetUnassigned: TreeFleetUnassignedFE,
  mui: string
): TreeGatewayUnassignedFE | undefined {
  return rootTreeFleetUnassigned.gateways.find(d => d.mui === mui);
}

export function treeFleetUnassignedIncludes(rootTreeFleetUnassigned: TreeFleetUnassignedFE, treeItem: TreeDeviceUnassignedFE): boolean {
  return (
    treeFleetUnassignedDevice(rootTreeFleetUnassigned, treeItem.mui) !== undefined ||
    treeFleetUnassignedGateway(rootTreeFleetUnassigned, treeItem.mui) !== undefined
  );
}

export function treeFleetHasWarning(rootTreeFleet: TreeFleetFE): boolean {
  if (rootTreeFleet.chargers.findIndex(d => d.warning) !== -1) {
    return true;
  }
  return rootTreeFleet.batteries.findIndex(d => d.warning) !== -1;
}

export function treeFleetDeviceCount(rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE): number {
  return treeFleetChargerCount(rootTreeFleet) + treeFleetBatteryCount(rootTreeFleet) + treeFleetGatewayCount(rootTreeFleet);
}

export function treeFleetChargerCount(rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE): number {
  return rootTreeFleet.chargers.length;
}

export function treeFleetBatteryCount(rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE): number {
  return rootTreeFleet.batteries.length;
}

export function treeFleetGatewayCount(rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE): number {
  return rootTreeFleet.gateways.length;
}

export function treeFleetOnlineStatus(rootTreeFleet: TreeFleetFE): boolean | undefined {
  if (!rootTreeFleet.gateways || rootTreeFleet.gateways.length === 0) {
    return undefined;
  }
  return rootTreeFleet.gateways.findIndex(gateway => gateway.state === 'Offline') === -1;
}

function convertTreeDevicetoTreeDeviceFE(from: TreeDeviceFE | TreeDeviceUnassignedFE): TreeDeviceFE {
  return {
    mui: from.mui,
    deviceId: from.deviceId,
    deviceTag: from.deviceTag,
    serialNumber: from.serialNumber,
    type: from.type,
    productCategory: from.productCategory,
    lastSeen: from.lastSeen,
    new: from.new,
    state: from.state,
    warning: from.warning,
    createdBy: from.createdBy,
    updatedBy: from.updatedBy,
  };
}

function convertTreeDevicetoTreeDeviceUnassignedFE(from: TreeDeviceFE | TreeDeviceUnassignedFE): TreeDeviceUnassignedFE {
  return {
    ...from,
    unassigned: true,
  };
}

function convertTreeGatewaytoTreeGatewayUnassignedFE(from: TreeGatewayFE | TreeGatewayUnassignedFE): TreeGatewayUnassignedFE {
  return {
    ...from,
    unassigned: true,
  };
}

export function treeFleetAddTreeDevices(
  rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE,
  treeDevices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[]
): TreeFleetFE | TreeFleetUnassignedFE {
  if (instanceOfTreeFleetFE(rootTreeFleet)) {
    const newChargers = treeDevices
      .filter((d): d is TreeDeviceFE | TreeDeviceUnassignedFE => d.productCategory === 'charger')
      .map(d => convertTreeDevicetoTreeDeviceFE(d));
    const newBatteries = treeDevices
      .filter((d): d is TreeDeviceFE | TreeDeviceUnassignedFE => d.productCategory === 'bms' || d.productCategory === 'bmu')
      .map(d => convertTreeDevicetoTreeDeviceFE(d));
    const newGateways = treeDevices.filter((d): d is TreeGatewayFE | TreeGatewayUnassignedFE => d.productCategory === 'gateway');
    return {
      ...rootTreeFleet,
      chargers: rootTreeFleet.chargers.concat(newChargers),
      batteries: rootTreeFleet.batteries.concat(newBatteries),
      gateways: rootTreeFleet.gateways.concat(newGateways),
    } as TreeFleetFE;
  }

  const newChargers = treeDevices
    .filter((d): d is TreeDeviceFE | TreeDeviceUnassignedFE => d.productCategory === 'charger')
    .map(d => convertTreeDevicetoTreeDeviceUnassignedFE(d));
  const newBatteries = treeDevices
    .filter((d): d is TreeDeviceFE | TreeDeviceUnassignedFE => d.productCategory === 'bms' || d.productCategory === 'bmu')
    .map(d => convertTreeDevicetoTreeDeviceUnassignedFE(d));
  const newGateways = treeDevices
    .filter((g): g is TreeGatewayFE | TreeGatewayUnassignedFE => g.productCategory === 'gateway')
    .map(g => convertTreeGatewaytoTreeGatewayUnassignedFE(g));
  return {
    ...rootTreeFleet,
    chargers: rootTreeFleet.chargers.concat(newChargers),
    batteries: rootTreeFleet.batteries.concat(newBatteries),
    gateways: rootTreeFleet.gateways.concat(newGateways),
  } as TreeFleetUnassignedFE;
}

export function treeFleetDeleteDevices(
  rootTreeFleet: TreeFleetFE | TreeFleetUnassignedFE,
  devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[]
): TreeFleetFE | TreeFleetUnassignedFE {
  return {
    ...rootTreeFleet,
    chargers: rootTreeFleet.chargers.filter(charger => devices.findIndex(d => compareItems(d, charger)) === -1),
    batteries: rootTreeFleet.batteries.filter(battery => devices.findIndex(d => compareItems(d, battery)) === -1),
    gateways: rootTreeFleet.gateways.filter(gateway => devices.findIndex(d => compareItems(d, gateway)) === -1),
  } as TreeFleetFE | TreeFleetUnassignedFE;
}
