import React, { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Paper, useTheme } from '@mui/material';
import { SxProps } from '../../common/commonTypes';

type ViewWithHeaderProps = {
  header: ReactNode;
  children?: ReactNode;
};

export function ViewWithHeader({ header, children, sx }: ViewWithHeaderProps & SxProps): JSX.Element {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        ...sx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            padding: '0 24px',
            minHeight: '65px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            overflow: 'auto',
          }}
        >
          <Typography
            variant='sectionHeader'
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {header}
          </Typography>
        </Box>
        {children}
      </Box>
    </Paper>
  );
}
