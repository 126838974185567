import React from 'react';
import { useTheme } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ChartData, BarElement, ChartOptions, CategoryScale, LinearScale, Tooltip } from 'chart.js';
import { LabelOptions } from 'chartjs-plugin-datalabels/types/options';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export type CategoriesAndValues = {
  categories: string[];
  values: number[];
};

type BarGraphProps = {
  data: CategoriesAndValues;
};

export default function BarGraph({ data }: BarGraphProps): JSX.Element {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

  const theme = useTheme();

  const labelOptions: LabelOptions = {
    color: 'white',
    display: true,
  };

  const barOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: labelOptions,
    },
    scales: {
      y: {
        grid: {
          color: theme.palette.action.active,
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          color: theme.palette.action.active,
        },
      },
    },
  };

  const barData: ChartData<'bar'> = {
    labels: data.categories,
    datasets: [
      {
        backgroundColor: theme.palette.info.main,
        data: data.values,
        maxBarThickness: 40,
      },
    ],
  };

  return <Bar data={barData} options={barOptions} plugins={[ChartDataLabels]} />;
}
