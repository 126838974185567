import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Icon from './common/Icon';
import { useUser } from '../dataHooks/adminHooks';
import { AppContext } from '../App';
import { SxProps } from './common/commonTypes';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

type LabeledButtonProps = {
  icon: JSX.Element;
  to?: string;
  onClick?: (() => void) | undefined; // alternative to 'to' prop
  label: string;
  sx?: SxProps;
};

function LabeledButton({ icon, to, onClick, label, sx }: LabeledButtonProps & SxProps): JSX.Element {
  const hoverZoom = {
    transition: 'all 0.2s',
    '&:hover': {
      transform: 'scale(1.5)',
    },
  };
  const flexBox = {
    display: 'flex' as const,
    flexDirection: 'column' as const,
    alignItems: 'center' as const,
  };
  return (
    <IconButton sx={flexBox} onClick={onClick} {...(to && { component: RouterLink, to: to })}>
      <>
        <Box sx={{ ...hoverZoom, marginBottom: '-6px', ...sx }}>{icon}</Box>
        <Typography variant='tableText'>{label}</Typography>
      </>
    </IconButton>
  );
}

type MainMenuProps = {
  viewRoute: string;
  arrangeRoute: string;
  adminRoute: string;
  profileRoute: string;
  showHelpBox?: (() => void) | undefined;
};

function MainMenu({ viewRoute, arrangeRoute, adminRoute, profileRoute, showHelpBox }: MainMenuProps): JSX.Element {
  const appContext = useContext(AppContext);
  const { data, error } = useUser();

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        borderRight: '2px solid black',
        overflow: 'hidden',
        width: 86,
        minWidth: 86,
        maxWidth: 86,
        paddingTop: '8px',
        paddingBottom: '8px',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <LabeledButton label='Fleet Manager' to={viewRoute} icon={<Icon name='menu_view' fontSize='large' />} />
        {data && !data.roleId.toString().startsWith('2') && (
          <>
            <LabeledButton label='Arrange' to={arrangeRoute} icon={<Icon name='menu_arrange' fontSize='large' />} />
            <LabeledButton label='Admin' to={adminRoute} icon={<AdminPanelSettingsOutlinedIcon sx={{ width: '40px', height: '40px' }} />} />
          </>
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
        <LabeledButton label='Profile' to={profileRoute} icon={<Icon name='menu_user' fontSize='large' />} />
        {showHelpBox && (
          <LabeledButton sx={{ marginBottom: '3px' }} label='Help' onClick={showHelpBox} icon={<Icon name='?' fontSize='large' />} />
        )}
      </Box>
    </Box>
  );
}

export default MainMenu;
