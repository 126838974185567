import React from 'react';
import { TextareaAutosize, useTheme } from '@mui/material';
import InputComponent from './InputComponent';

type InputLongTextProps = {
  label: string;
  value: string;
  updateValue: (name: string) => void;
  key?: string;
};

export default function InputLongText({ label, value, updateValue, key }: InputLongTextProps): JSX.Element {
  const theme = useTheme();
  return (
    <InputComponent label={label}>
      <TextareaAutosize
        id={key || label}
        minRows={3}
        maxRows={3}
        value={value}
        onChange={(event): void => updateValue(event.target.value)}
        autoComplete='off'
        style={{ width: '100%', backgroundColor: 'black', color: theme.typography.columnHeader.color, resize: 'none' }}
      />
    </InputComponent>
  );
}
