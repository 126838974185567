import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import {
  instanceOfTreeDeviceFE,
  instanceOfTreeDeviceUnassignedFE,
  TreeDeviceFE,
  TreeItemFE,
  TreeDeviceUnassignedFE,
  TreeFleetUnassignedFE,
  instanceOfTreeFleetUnassignedFE,
  TreeGatewayUnassignedFE,
  instanceOfTreeGatewayUnassignedFE,
  TreeGatewayFE,
  instanceOfTreeGatewayFE,
} from '../../model/frontendDataModels';
import AddEditDeleteFooter from './addEditDeleteFooter/AddEditDeleteFooter';
import TransferButton from './TransferButton';
import HelpBox from '../common/HelpBox';
import { AppContext } from '../../App';
import HelpBoxArrange from './HelpBoxArrange';
import FleetBrowser from '../common/FleetBrowser';

function isDeviceOrGateway(item: TreeItemFE): boolean {
  return (
    instanceOfTreeDeviceFE(item) ||
    instanceOfTreeDeviceUnassignedFE(item) ||
    instanceOfTreeGatewayFE(item) ||
    instanceOfTreeGatewayUnassignedFE(item)
  );
}

type ArrangeProps = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function Arrange({ changeHelpBox, hideHelpBox }: ArrangeProps): JSX.Element {
  const appContext = useContext(AppContext);
  const [leftSelectedTreeItems, setLeftSelectedTreeItems] = useState<Exclude<TreeItemFE, TreeFleetUnassignedFE>[]>([]);
  const [rightSelectedTreeItem, setRightSelectedTreeItem] = useState<
    Exclude<TreeItemFE, TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE> | undefined
  >(undefined);

  useEffect(() => {
    changeHelpBox(<HelpBox hide={hideHelpBox} title='Arrange' info={<HelpBoxArrange />} />);
  }, []);

  if (!appContext.rootTreeCustomer) {
    return <></>;
  }

  return (
    <Box component='div' sx={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
      <FleetBrowser
        rootTreeCustomer={appContext.rootTreeCustomer}
        canSelect={(treeItem: TreeItemFE): boolean => {
          if (instanceOfTreeFleetUnassignedFE(treeItem)) {
            return false;
          }
          if (
            rightSelectedTreeItem &&
            instanceOfTreeFleetUnassignedFE(rightSelectedTreeItem) &&
            (instanceOfTreeDeviceUnassignedFE(treeItem) || instanceOfTreeGatewayUnassignedFE(treeItem))
          ) {
            return false;
          }
          return true;
        }}
        selectedTreeItems={leftSelectedTreeItems}
        selectTreeItems={(items): void => {
          if (items.length <= 1 || (isDeviceOrGateway(items[0]) && isDeviceOrGateway(items[1]))) {
            setLeftSelectedTreeItems(items as Exclude<TreeItemFE, TreeFleetUnassignedFE>[]);
          }
        }}
        footerComponent={
          <AddEditDeleteFooter
            selectedTreeItem={leftSelectedTreeItems.length === 1 ? leftSelectedTreeItems[0] : undefined}
            selectedDevices={
              leftSelectedTreeItems.length > 0 &&
              (instanceOfTreeDeviceFE(leftSelectedTreeItems[0]) ||
                instanceOfTreeDeviceUnassignedFE(leftSelectedTreeItems[0]) ||
                instanceOfTreeGatewayFE(leftSelectedTreeItems[0]) ||
                instanceOfTreeGatewayUnassignedFE(leftSelectedTreeItems[0]))
                ? (leftSelectedTreeItems as (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[])
                : undefined
            }
            changeSelectedTreeItem={(treeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>): void =>
              treeItem ? setLeftSelectedTreeItems([treeItem]) : setLeftSelectedTreeItems([])
            }
          />
        }
        footerHeight={150}
        includeUnassigned={true}
      />
      <Box
        component='div'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          width: 200,
          borderRight: '2px solid black',
          overflow: 'hidden',
          flexShrink: 0,
          alignItems: 'center',
        }}
      >
        <TransferButton
          leftSelectedTreeItems={leftSelectedTreeItems}
          rightSelectedTreeItem={rightSelectedTreeItem}
          clearLeftSelectedTreeItems={(): void => {
            setLeftSelectedTreeItems([]);
          }}
        />
      </Box>
      <FleetBrowser
        rootTreeCustomer={appContext.rootTreeCustomer}
        canSelect={(treeItem: TreeItemFE): boolean => {
          if (
            instanceOfTreeDeviceFE(treeItem) ||
            instanceOfTreeGatewayFE(treeItem) ||
            instanceOfTreeDeviceUnassignedFE(treeItem) ||
            instanceOfTreeGatewayUnassignedFE(treeItem)
          ) {
            return false;
          }
          if (
            leftSelectedTreeItems.length > 0 &&
            (instanceOfTreeDeviceUnassignedFE(leftSelectedTreeItems[0]) || instanceOfTreeGatewayUnassignedFE(leftSelectedTreeItems[0])) &&
            instanceOfTreeFleetUnassignedFE(treeItem)
          ) {
            return false;
          }
          return true;
        }}
        selectedTreeItems={rightSelectedTreeItem ? [rightSelectedTreeItem] : []}
        selectTreeItems={(items): void => {
          if (items.length === 0) {
            setRightSelectedTreeItem(undefined);
          } else {
            setRightSelectedTreeItem(
              items[0] as Exclude<TreeItemFE, TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE>
            );
          }
        }}
        includeUnassigned={true}
      />
    </Box>
  );
}
