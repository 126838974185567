import React from 'react';
import Stack from '@mui/material/Stack';

type InputRowProps = {
  children: React.ReactNode;
};

export default function InputRow({ children }: InputRowProps): JSX.Element {
  return (
    <Stack direction='row' spacing={3} sx={{ height: '80px', marginBottom: '10px' }}>
      {children}
    </Stack>
  );
}
