import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { TreeFleetFE, TreeGatewayFE } from '../../../model/frontendDataModels';
import { treeCustomerFindTreeFleetByGateway, treeCustomerUpdateTreeFleet } from '../../../utils/treeCustomerFunctions';
import { activateFleet, deactivateFleet } from '../../../services/fleetManipulation';
import { LoginContext } from '../../../Login';
import { AppContext } from '../../../App';
import { BackendError } from '../../../utils/BackendError';
import InactiveFleetDeviceView from '../selectedDeviceView/InactiveFleetDeviceView';
import GatewayHeader from './selectedGatewayHeader/GatewayHeader';
import GatewayConfigurationTab from './GatewayConfigurationTab';
import TabbedPage from '../../common/TabbedPage';
import EmptyFleetDeviceView from '../selectedDeviceView/EmptyFleetDeviceView';
import { COMPONENT_PADDING, WIDTH_OF_INFO_COMPONENT } from '../../../themes/theme';
import { useTheme } from '@mui/material';

type EmptyGatewayContextValues = {
  isInitializedGateway: boolean;
  isActiveFleet: boolean;
  setIsInitializedGateway: (initialized: boolean) => void;
};

export const EmptyGatewayContext = React.createContext<EmptyGatewayContextValues>({
  isInitializedGateway: true,
  isActiveFleet: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setIsInitializedGateway: (initialized: boolean) => {},
});

type SelectedGatewayViewProps = {
  selectedGateway: TreeGatewayFE;
};

function SelectedDeviceView({ selectedGateway }: SelectedGatewayViewProps): JSX.Element | null {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);

  const theme = useTheme();

  const [isInitializedGateway, setIsInitializedGateway] = useState<boolean>(true);
  const treeFleet = appContext.rootTreeCustomer ? treeCustomerFindTreeFleetByGateway(appContext.rootTreeCustomer, selectedGateway) : null;
  const [fullscreen, setFullscreen] = useState<boolean>(false);

  if (!treeFleet) {
    return null;
  }
  const isActiveFleet = treeFleet.activated;

  useEffect(() => {
    // assume gateway is initialized until data provided prove otherwise
    setIsInitializedGateway(true);
  }, [selectedGateway]);

  async function update(active: boolean): Promise<void> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return;
    }
    if (treeFleet && appContext.rootTreeCustomer) {
      if (active) {
        const result = await activateFleet(treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          const newTreeFleet: TreeFleetFE = {
            ...treeFleet,
            activated: true,
          };
          appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
        }
      } else {
        const result = await deactivateFleet(treeFleet.fleetId, loginContext.accessToken, appContext.addBackendError);
        if (result) {
          const newTreeFleet: TreeFleetFE = {
            ...treeFleet,
            activated: false,
          };
          appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
        }
      }
    }
  }

  return (
    <EmptyGatewayContext.Provider value={{ isActiveFleet, isInitializedGateway, setIsInitializedGateway }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: fullscreen ? '100%' : WIDTH_OF_INFO_COMPONENT * 2 + COMPONENT_PADDING * 2,
          borderRight: '2px solid black',
        }}
        style={{
          transition: theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.standard,
          }),
        }}
      >
        <GatewayHeader selectedGateway={selectedGateway} onFullscreenChanged={setFullscreen} fullscreen={fullscreen} />
        {!isInitializedGateway && treeFleet.activated && <EmptyFleetDeviceView />}
        {treeFleet.activated && (
          <TabbedPage
            tabAlignment='right'
            pages={[{ name: 'Configuration', body: <GatewayConfigurationTab selectedGateway={selectedGateway} /> }]}
            disableScroll={true}
          />
        )}
        {!treeFleet.activated && <InactiveFleetDeviceView treeFleet={treeFleet} update={update} />}
      </Box>
    </EmptyGatewayContext.Provider>
  );
}

export default React.memo(SelectedDeviceView);
