import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { addDays, addMonths, eachDayOfInterval, parse, subDays } from 'date-fns';
import BundlesChart from './BundlesChart';
import { subMonths } from 'date-fns/esm';
import { BundleSubscription, InvoiceDataInvAdmin } from '../../../../../model/backendDataModels';
import MonthAndYearPicker from '../../../../common/monthAndYearPicker/MonthAndYearPicker';
import Store from '../../../../../store/Store';
import { utcToZonedTime } from 'date-fns-tz';

export type SubscriptionHistoryEvent = {
  date: Date;
  change: number;
};

function createBundleValuesFromSubscriptions(dates: Date[], bundles: BundleSubscription[]): number[] {
  const startDate = dates[0];

  const allStartDatesInOrder = bundles
    .filter(b => b.startDate)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map(b => parse(b.startDate!, 'yyyy-MM-dd', new Date()))
    .sort((a, b) => a.getTime() - b.getTime());

  const allEndDatesInOrder = bundles
    .filter(b => b.endDate)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map(b => parse(b.endDate!, 'yyyy-MM-dd', new Date()))
    .map(date => addDays(date, 1))
    .sort((a, b) => a.getTime() - b.getTime());

  const startDatesBeforeFirstDate = allStartDatesInOrder.filter(d => d < startDate);
  const endDatesBeforeFirstDate = allEndDatesInOrder.filter(d => d < startDate);

  let currentBundles = startDatesBeforeFirstDate.length - endDatesBeforeFirstDate.length;
  const bundlesValues: number[] = [];
  dates.forEach(date => {
    let change = 0;
    change = change + allStartDatesInOrder.filter(d => d.getTime() === date.getTime()).length;
    change = change - allEndDatesInOrder.filter(d => d.getTime() === date.getTime()).length;
    currentBundles = currentBundles + change;
    bundlesValues.push(currentBundles);
  });

  return bundlesValues;
}

type SubscriptionHistoryProps = {
  invoiceDataInvAdmins: InvoiceDataInvAdmin[];
};

export default function SubscriptionHistory({ invoiceDataInvAdmins }: SubscriptionHistoryProps): JSX.Element {
  const { state } = useContext(Store);

  const now = utcToZonedTime(new Date(), state.timezone);

  const [year, setYear] = useState<number>(now.getFullYear());
  const [month, setMonth] = useState<number>(now.getMonth());

  const startDate = subMonths(new Date(year, month, 1), 1);
  const endDate = subDays(new Date(year, month + 1, 1), 1);
  const dates = eachDayOfInterval({ start: startDate, end: endDate });

  if (!Array.isArray(invoiceDataInvAdmins) || invoiceDataInvAdmins.length === 0 || !invoiceDataInvAdmins[0].bundles) {
    return <></>;
  }

  const bundlesValuesArray = invoiceDataInvAdmins.map(id => createBundleValuesFromSubscriptions(dates, id.bundles));

  const zeroArray = new Array(dates.length).fill(0);
  const totalBundlesValues = bundlesValuesArray.reduce((sumArray, nextArray) => sumArray.map((e, i) => e + nextArray[i]), zeroArray);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant='graphHeader'>Purchase history</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px', marginBottom: '10px' }}>
          <Typography variant='columnHeader'>Invoice month:</Typography>
          <Box sx={{ width: '300px' }}>
            <MonthAndYearPicker year={year} month={month} onYearSelected={setYear} onMonthSelected={setMonth} maxDate={addMonths(now, 1)} />
          </Box>
        </Box>
      </Box>

      <BundlesChart dates={dates} values={totalBundlesValues} />
    </Box>
  );
}
