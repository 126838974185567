import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { SxProps } from './commonTypes';

type Props = {
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
};

export default function DefaultLoadingButton({ onClick, loading, disabled, children, sx }: Props & SxProps): JSX.Element {
  return (
    <LoadingButton sx={sx} loading={loading} variant='contained' onClick={onClick} disabled={disabled}>
      {children}
    </LoadingButton>
  );
}
