import { SWRResponse } from 'swr';
import { BasicLogInfo, ConfigData, FieldsAndValues, LogChart, LogChartOptions, LogData } from '../model/backendDataModels';
import { TreeDeviceFE, TreeGatewayFE } from '../model/frontendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';
import moment from 'moment';

export function useDeviceConfig(device: TreeDeviceFE | TreeGatewayFE): SWRResponse<ConfigData, BackendError> {
  const fullUrl = getFullUrl(`/${device.productCategory}/${device.mui}/config`);

  return getUseSwr<ConfigData>(fullUrl);
}

export function useDeviceHistoryLogs(
  type: 'bms' | 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date
): SWRResponse<LogData, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/history`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<LogData>(fullUrl);
}

export function useHistoryChart(type: 'bmu' | 'charger', mui: string, from: Date, to: Date): SWRResponse<LogChart, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/historychart`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<LogChart>(fullUrl);
}

export function useHistoryChartOptions(type: 'bmu' | 'charger'): SWRResponse<LogChartOptions, BackendError> {
  const fullUrl = getFullUrl(`/${type}/historychartoptions`);

  return getUseSwr<LogChartOptions>(fullUrl);
}

export function useDeviceEventLogs(
  type: 'bms' | 'bmu' | 'charger',
  mui: string,
  from: Date,
  to: Date
): SWRResponse<FieldsAndValues, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/event`, {
    startDate: from.toISOString(),
    endDate: to.toISOString(),
  });

  return getUseSwr<FieldsAndValues>(fullUrl);
}

export function useBasicLogInfo(type: 'bmu' | 'charger', mui: string): SWRResponse<BasicLogInfo, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/basicinfo`);

  return getUseSwr<BasicLogInfo>(fullUrl);
}

export function useInstantChart(
  type: 'bmu' | 'charger',
  mui: string,
  from: Date,
  durationHours: number
): SWRResponse<LogChart, BackendError> {
  const fullUrl = getFullUrl(`/${type}/${mui}/logs/instant`, {
    startDate: from.toISOString(),
    endDate: moment(from).add(durationHours, 'hours').toISOString(),
  });

  return getUseSwr<LogChart>(fullUrl);
}
