import { SWRResponse } from 'swr';
import { UsersData, User, InvoiceDataInvAdmin, InvoiceDataSysAdmin } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useUser(): SWRResponse<User, BackendError> {
  const fullUrl = getFullUrl('/user');
  return getUseSwr<User>(fullUrl);
}

export function useUsers(): SWRResponse<UsersData, BackendError> {
  const fullUrl = getFullUrl('/users');
  return getUseSwr<UsersData>(fullUrl);
}

export function useInvoiceData(): SWRResponse<InvoiceDataInvAdmin | InvoiceDataSysAdmin, BackendError> {
  const fullUrl = getFullUrl('/subscription');
  return getUseSwr<InvoiceDataInvAdmin | InvoiceDataSysAdmin>(fullUrl);
}
