import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import FakeOverview from './fakeOverview/FakeOverview';

export default function EmptyFleetDeviceView(): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
      }}
    >
      <FakeOverview />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          position: 'absolute',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backdropFilter: 'blur(6px)',
        }}
      >
        <Typography variant='graphHeader' sx={{ color: theme.palette.info.main }}>
          No data has arrived for this device.
        </Typography>
        <Typography
          component='div'
          variant='graphHeader'
          sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', fontWeight: 'normal' }}
        >
          Please come back later.
        </Typography>
      </Box>
    </Box>
  );
}
