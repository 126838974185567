import React, { useContext, useEffect, useState } from 'react';
import { COMPONENT_PADDING } from '../../themes/theme';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { AppContext } from '../../App';
import { LoginContext } from '../../Login';
import { TreeCustomerFE } from '../../model/frontendDataModels';
import CustomerSelector from './common/CustomerSelector';
import InputItem from '../common/InputItem';
import DefaultButton from '../common/DefaultButton';
import { registerGW } from '../../services/registerGW';
import LoadingIndicator from '../common/LoadingIndicator';

export default function RegisterGetConnectedTab(): JSX.Element {
  const loginContext = useContext(LoginContext);
  const appContext = useContext(AppContext);
  const theme = useTheme();

  const [serialNumber, setSerialNumber] = useState('');
  const [syncing, setSyncing] = useState(false);
  const [result, setResult] = useState('');

  const [selectedCustomer, setSelectedCustomer] = useState<TreeCustomerFE | undefined>(undefined);

  if (!appContext.rootTreeCustomer) {
    return <></>;
  }

  useEffect(() => {
    if (serialNumber.length > 0 && isNaN(+serialNumber)) {
      setSerialNumber(serialNumber.slice(0, -1));
    }
  }, [serialNumber]);

  async function registerGWFunc(): Promise<void> {
    if (serialNumber !== '' && selectedCustomer) {
      setSyncing(true);
      try {
        await registerGW(serialNumber, { customerId: selectedCustomer.customerId }, loginContext.accessToken || '');
        setResult(`Success: Gateway with serialNumber ${serialNumber} was moved to customer ${selectedCustomer.customerName}.`);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err && err.message) {
          // eslint-disable-next-line no-console
          console.log(err.message);
          setResult(`Registration failed for ${serialNumber} - ${err.message}`);
        }
      }
      setSerialNumber('');
      setSyncing(false);
    }
  }

  return (
    <Box
      component='div'
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        width: '100%',
        borderTop: '2px solid black',
      }}
    >
      <Stack
        direction='column'
        spacing={2}
        sx={{
          height: '100%',
          width: 400,
          minWidth: 400,
          maxWidth: 400,
          borderRight: '2px solid black',
          overflow: 'hidden',
          flexShrink: 0,
          padding: `${COMPONENT_PADDING}px`,
        }}
      >
        <InputItem label='Serial number' value={serialNumber} updateValue={setSerialNumber} type='text' />
        {syncing && <LoadingIndicator />}
        {!syncing && (
          <DefaultButton
            onClick={(): void => {
              setResult('');
              registerGWFunc();
            }}
            disabled={!selectedCustomer || serialNumber === ''}
          >
            Submit
          </DefaultButton>
        )}
        {result && result.startsWith('Success') && (
          <Typography variant='sectionHeader' color={theme.palette.success.main}>
            {result}
          </Typography>
        )}
        {result && result.startsWith('Registration failed') && (
          <Typography variant='sectionHeader' color={theme.palette.error.main}>
            {result}
          </Typography>
        )}
      </Stack>
      <CustomerSelector
        rootTreeCustomer={appContext.rootTreeCustomer}
        selectedCustomers={selectedCustomer ? [selectedCustomer] : []}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        updateSelectCustomers={(customers: TreeCustomerFE[]): void => {
          if (customers.length > 0) {
            setSelectedCustomer(customers[0] as TreeCustomerFE);
          } else {
            setSelectedCustomer(undefined);
          }
        }}
      />
    </Box>
  );
}
