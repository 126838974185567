import React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import InputComponent from '../../../../common/InputComponent';

type Props = {
  label: string;
  value: string;
  items: string[];
  updateValue: (value: string | null) => void;
  disabled?: true;
};

export default function InputAutocomplete({ label, value, items, updateValue, disabled }: Props): JSX.Element {
  return (
    <InputComponent label={label}>
      <Autocomplete
        value={value}
        onChange={(event: unknown, newValue: string | null): void => {
          updateValue(newValue);
        }}
        options={items}
        sx={{
          backgroundColor: 'black',
          borderRadius: '5px',
          '&:before': {
            borderRadius: '5px',
          },
        }}
        renderInput={(params): JSX.Element => <TextField {...params} />}
        disabled={disabled}
      />
    </InputComponent>
  );
}
