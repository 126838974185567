import React from 'react';
import Box from '@mui/material/Box';
import { instanceOfTreeCustomerFE, TreeCustomerFE, TreeItemFE } from '../../../model/frontendDataModels';
import SearchableTree from '../../common/searchableTree/SearchableTree';
import { ViewWithHeader } from './ViewWithHeader';

type Props = {
  rootTreeCustomer: TreeCustomerFE;
  selectedCustomers: TreeCustomerFE[];
  updateSelectCustomers: (selectedCustomers: TreeCustomerFE[]) => void;
  multiselect?: boolean;
  canSelectCustomer?: (treeCustomer: TreeCustomerFE) => boolean;
};

export default function CustomerSelector({
  rootTreeCustomer,
  selectedCustomers,
  updateSelectCustomers,
  multiselect,
  canSelectCustomer,
}: Props): JSX.Element {
  return (
    <ViewWithHeader
      sx={{
        minWidth: 400,
        borderRight: '2px solid black',
      }}
      header={
        <>
          <Box sx={{ marginLeft: '1em' }}>{'Select Customer'}</Box>
        </>
      }
    >
      <SearchableTree
        rootTreeCustomer={rootTreeCustomer}
        canSelect={(treeItem: TreeItemFE): boolean => {
          if (instanceOfTreeCustomerFE(treeItem)) {
            if (canSelectCustomer) {
              return canSelectCustomer(treeItem);
            }
            return true;
          }
          return false;
        }}
        selectedTreeItems={selectedCustomers}
        selectTreeItems={(items): void => {
          if (items.length > 0) {
            if (multiselect) {
              updateSelectCustomers(items as TreeCustomerFE[]);
            } else {
              updateSelectCustomers([items[0] as TreeCustomerFE]);
            }
          } else {
            updateSelectCustomers([]);
          }
        }}
        includeUnassigned={false}
        includeDevices={false}
        onlyCustomers={true}
        wideMode={false}
      />
    </ViewWithHeader>
  );
}
