import React, { useContext, useState } from 'react';
import Box from '@mui/material/Box';
import AlternatingColorsList from '../common/AlternatingColorsList';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { TreeGatewayFE } from '../../../model/frontendDataModels';
import LoadingIndicator from '../../common/LoadingIndicator';
import { useDeviceConfig } from '../../../dataHooks/deviceHooks';
import InputRow from '../selectedDeviceView/logFilesTab/common/InputRow';
import InputComponent from '../../common/InputComponent';
import { CSVLink } from 'react-csv';
import { ConfigData, FieldsWithSingleValues } from '../../../model/backendDataModels';
import { AppContext } from '../../../App';
import DefaultButton from '../../common/DefaultButton';
import { EmptyGatewayContext } from './SelectedGatewayView';
import moment from 'moment-timezone';
import Store from '../../../store/Store';

type GatewayConfigurationTabHeaderProps = {
  csvData: string[][];
};

type GatewayConfigurationTabProps = {
  selectedGateway: TreeGatewayFE;
};

function mergeObjectData(fieldsAndValues: FieldsWithSingleValues, timezone: string): FieldsWithSingleValues {
  const values: string[] = [];

  fieldsAndValues.fields.forEach((field, index) => {
    if (field.type === 'isodate') {
      const date = moment(fieldsAndValues.values[index], moment.ISO_8601).tz(timezone);
      const dateString = date.format('YYYY-MM-DD HH:mm:ss');

      values.push(dateString);
    } else {
      values.push(fieldsAndValues.values[index]);
    }
  });

  return {
    fields: fieldsAndValues.fields,
    values: values,
  };
}

function getCsvData(data: ConfigData, timezone: string): string[][] {
  const basic = mergeObjectData(data.basic, timezone);
  const advanced = mergeObjectData(data.advanced, timezone);
  const other = mergeObjectData(data.other, timezone);
  const headers = basic.fields.concat(advanced.fields).concat(other.fields);
  const allData = basic.values.concat(advanced.values).concat(other.values);

  return [headers.map(field => field.name)].concat([allData]);
}

export function GatewayConfigurationTabHeader({ csvData }: GatewayConfigurationTabHeaderProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        paddingBottom: 0,
      }}
    >
      <InputRow>
        {csvData && (
          <InputComponent label=''>
            <CSVLink data={csvData} filename='config.csv'>
              <DefaultButton
                sx={{
                  height: '40px',
                }}
                onClick={(): void => void 0}
              >
                Export CSV
              </DefaultButton>
            </CSVLink>
          </InputComponent>
        )}
      </InputRow>
    </Box>
  );
}

export default function GatewayConfigurationTab({ selectedGateway }: GatewayConfigurationTabProps): JSX.Element {
  const [showAdvanced, setShowAdvanced] = useState(false);

  const appContext = useContext(AppContext);
  const { state } = useContext(Store);
  const emptyGatewayContext = useContext(EmptyGatewayContext);
  const { data, error } = useDeviceConfig(selectedGateway);

  if (error) {
    appContext.addBackendError(error);
  }

  if (data) {
    const isInitalized = data.advanced.fields.length !== 0 || data.basic.fields.length !== 0 || data.other.fields.length !== 0;
    emptyGatewayContext.setIsInitializedGateway(isInitalized);
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Configuration
          </Typography>
        </Box>
      </Box>
    );
  }

  if (!data) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
        <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
          <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
            Configuration
          </Typography>
          <LoadingIndicator />
        </Box>
      </Box>
    );
  }

  const csvData = getCsvData(data, state.timezone);
  const basic = mergeObjectData(data.basic, state.timezone);
  const advanced = mergeObjectData(data.advanced, state.timezone);

  const basicData = basic.fields.map(function (e, i) {
    return [e.name, basic.values[i]];
  });

  const advancedData = advanced.fields.map(function (e, i) {
    return [e.name, advanced.values[i]];
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Box sx={{ padding: `${COMPONENT_PADDING}px`, height: '100%', overflow: 'auto' }}>
        <GatewayConfigurationTabHeader csvData={csvData} />
        <Typography variant='bigHeader' component='div' sx={{ marginBottom: '15px' }}>
          Configuration Tab
        </Typography>
        <AlternatingColorsList data={basicData} />
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%', marginTop: '10px' }}>
          <Typography variant='columnHeader' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            Advanced settings
          </Typography>
          <Switch checked={showAdvanced} onChange={(event, checked): void => setShowAdvanced(checked)} color='info' />
        </Box>
        {showAdvanced && (
          <>
            <Typography variant='graphHeader' component='div'>
              Advanced
            </Typography>
            <AlternatingColorsList data={advancedData} />
          </>
        )}
      </Box>
    </Box>
  );
}
