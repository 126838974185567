import React, { useContext, useEffect } from 'react';
import ExpandableRow from '../ExpandableRow';
import Box from '@mui/material/Box';
import { Table, TableHead, TableBody } from '@mui/material';
import { TreeContext } from '../SearchableTree';
import {
  compareItems,
  instanceOfTreeGatewayUnassignedFE,
  instanceOfTreeGatewayFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';
import GatewayTableRow from './GatewayTableRow';

type Props = {
  fleetId: string;
  gatewayList: (TreeGatewayFE | TreeGatewayUnassignedFE)[];
  depth: number;
  unassigned: boolean;
};

export default function GatewayList({ fleetId, gatewayList, depth, unassigned }: Props): JSX.Element {
  const treeContext = useContext(TreeContext);

  const gatewayListId = `${fleetId}gateways`;

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(gatewayListId)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (
          (instanceOfTreeGatewayFE(treeItem) || instanceOfTreeGatewayUnassignedFE(treeItem)) &&
          gatewayList.findIndex(gateway => compareItems(gateway, treeItem)) !== -1
        ) {
          treeContext.expandTree(gatewayListId);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label='Gateways'
      iconName='gateway'
      depth={depth}
      selected={false}
      expanded={treeContext.expandedTreeItemIds.includes(gatewayListId)}
      expandTree={(): void => treeContext.expandTree(gatewayListId)}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];

        treeContext.selectedTreeItems.forEach(treeItem => {
          if (!((instanceOfTreeGatewayFE(treeItem) || instanceOfTreeGatewayUnassignedFE(treeItem)) && gatewayList.includes(treeItem))) {
            newSelectedTreeItems.push(treeItem);
          }
        });

        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(gatewayListId);
      }}
      hasWarning={false}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Table
          size='small'
          sx={{
            width: '100%',
            whiteSpace: 'nowrap' as const,
            position: 'relative' as const,
            tableLayout: 'fixed' as const,
          }}
        >
          <TableHead>
            <GatewayTableRow gatewayList={gatewayList} unassigned={unassigned} />
          </TableHead>
          <TableBody>
            {gatewayList.map(treeGateway => {
              return <GatewayTableRow key={treeGateway.mui} treeGateway={treeGateway} gatewayList={gatewayList} unassigned={unassigned} />;
            })}
          </TableBody>
        </Table>
      </Box>
    </ExpandableRow>
  );
}
