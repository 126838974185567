import {
  compareItems,
  instanceOfTreeDeviceFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  instanceOfTreeGatewayFE,
  TreeCustomerFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeGatewayFE,
  TreeGatewayUnassignedFE,
  TreeItemFE,
} from '../model/frontendDataModels';
import {
  treeFleetDeviceCount,
  treeFleetIncludesDevice,
  treeFleetHasWarning,
  TreeFleetInfo,
  treeFleetDevice,
  treeFleetDeleteDevices,
  treeFleetAddTreeDevices,
  treeFleetGateway,
  treeFleetIncludesGateway,
} from './treeFleetFunctions';

export function treeFleetGroupFindById(rootTreeFleetGroup: TreeFleetGroupFE, id: string): TreeFleetGroupFE | undefined {
  if (rootTreeFleetGroup.fleetgroupId === id) {
    return rootTreeFleetGroup;
  }
  for (const fg of rootTreeFleetGroup.fleetgroups) {
    const fleetGroup = treeFleetGroupFindById(fg, id);
    if (fleetGroup) {
      return fleetGroup;
    }
  }
  return undefined;
}

export function treeFleetGroupFindFleetById(rootTreeFleetGroup: TreeFleetGroupFE, id: string): TreeFleetFE | undefined {
  const fleet = rootTreeFleetGroup.fleets.find(fleet => fleet.fleetId === id);
  if (fleet) {
    return fleet;
  }
  for (const fg of rootTreeFleetGroup.fleetgroups) {
    const fleet = treeFleetGroupFindFleetById(fg, id);
    if (fleet) {
      return fleet;
    }
  }
  return undefined;
}

export function treeFleetGroupDevice(rootTreeFleetGroup: TreeFleetGroupFE, mui: string): TreeDeviceFE | undefined {
  for (const fleet of rootTreeFleetGroup.fleets) {
    const device = treeFleetDevice(fleet, mui);
    if (device) {
      return device;
    }
  }
  for (const fg of rootTreeFleetGroup.fleetgroups) {
    const device = treeFleetGroupDevice(fg, mui);
    if (device) {
      return device;
    }
  }
  return undefined;
}

export function treeFleetGroupIncludes(rootTreeFleetGroup: TreeFleetGroupFE, treeItem: Exclude<TreeItemFE, TreeCustomerFE>): boolean {
  if (instanceOfTreeFleetGroupFE(treeItem)) {
    if (
      rootTreeFleetGroup.fleetgroups.find(
        child =>
          (instanceOfTreeFleetGroupFE(treeItem) && child.fleetgroupId === treeItem.fleetgroupId) || treeFleetGroupIncludes(child, treeItem)
      ) !== undefined
    ) {
      return true;
    }
  } else if (instanceOfTreeFleetFE(treeItem)) {
    if (rootTreeFleetGroup.fleetgroups.find(child => treeFleetGroupIncludes(child, treeItem)) !== undefined) {
      return true;
    }
    if (rootTreeFleetGroup.fleets.find(f => f.fleetId === treeItem.fleetId) !== undefined) {
      return true;
    }
  } else if (instanceOfTreeDeviceFE(treeItem)) {
    if (rootTreeFleetGroup.fleetgroups.find(child => treeFleetGroupIncludes(child, treeItem)) !== undefined) {
      return true;
    }
    if (rootTreeFleetGroup.fleets.find(f => treeFleetIncludesDevice(f, treeItem)) !== undefined) {
      return true;
    }
  } else if (instanceOfTreeGatewayFE(treeItem)) {
    if (rootTreeFleetGroup.fleetgroups.find(child => treeFleetGroupIncludes(child, treeItem)) !== undefined) {
      return true;
    }
    if (rootTreeFleetGroup.fleets.find(f => treeFleetGateway(f, treeItem.mui)) !== undefined) {
      return true;
    }
  }

  return false;
}

export function treeFleetGroupHasWarning(rootTreeFleetGroup: TreeFleetGroupFE): boolean {
  if (rootTreeFleetGroup.fleetgroups.findIndex(fg => treeFleetGroupHasWarning(fg)) !== -1) {
    return true;
  }
  return rootTreeFleetGroup.fleets.findIndex(f => treeFleetHasWarning(f)) !== -1;
}

export function treeFleetGroupDeviceCount(rootTreeFleetGroup: TreeFleetGroupFE): number {
  return (
    rootTreeFleetGroup.fleetgroups.reduce(
      (acc: number, treeFleetGroup: TreeFleetGroupFE) => acc + treeFleetGroupDeviceCount(treeFleetGroup),
      0
    ) + rootTreeFleetGroup.fleets.reduce((acc: number, treeFleet: TreeFleetFE) => acc + treeFleetDeviceCount(treeFleet), 0)
  );
}

export function treeFleetGroupTreeFleet(rootTreeFleetGroup: TreeFleetGroupFE, fleetId: string): TreeFleetFE | null {
  const fleetInfo = treeFleetGroupFleetInfo(rootTreeFleetGroup, fleetId);
  return fleetInfo ? fleetInfo.treeFleet : null;
}

export function treeFleetGroupAllFleets(rootTreeFleetGroup: TreeFleetGroupFE): TreeFleetFE[] {
  let allTreeFleets: TreeFleetFE[] = [];
  rootTreeFleetGroup.fleets.forEach(f => allTreeFleets.push(f));
  rootTreeFleetGroup.fleetgroups.forEach(fg => {
    const fgFleets = treeFleetGroupAllFleets(fg);
    allTreeFleets = allTreeFleets.concat(fgFleets);
  });
  return allTreeFleets;
}

export function treeFleetGroupFleetInfo(rootTreeFleetGroup: TreeFleetGroupFE, fleetId: string): TreeFleetInfo | undefined {
  const treeFleet = rootTreeFleetGroup.fleets.find(f => f.fleetId === fleetId);
  if (treeFleet) {
    return {
      treeFleet,
      parentFleetGroup: rootTreeFleetGroup,
    };
  }
  const treeFleetGroup = rootTreeFleetGroup.fleetgroups.find(treeFleetGroupChild => treeFleetGroupFleetInfo(treeFleetGroupChild, fleetId));
  return treeFleetGroup ? treeFleetGroupFleetInfo(treeFleetGroup, fleetId) : undefined;
}

export function treeFleetGroupParentTo(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeItem: TreeFleetGroupFE | TreeFleetFE
): TreeFleetGroupFE | undefined {
  if (treeFleetGroupIncludes(rootTreeFleetGroup, treeItem)) {
    if (instanceOfTreeFleetGroupFE(treeItem)) {
      if (rootTreeFleetGroup.fleetgroups.find(fg => fg.fleetgroupId === treeItem.fleetgroupId)) {
        return rootTreeFleetGroup;
      }

      return rootTreeFleetGroup.fleetgroups.find(fg => treeFleetGroupParentTo(fg, treeItem));
    } else {
      if (rootTreeFleetGroup.fleets.find(fleet => fleet.fleetId === treeItem.fleetId)) {
        return rootTreeFleetGroup;
      }

      return rootTreeFleetGroup.fleetgroups.find(fg => treeFleetGroupParentTo(fg, treeItem));
    }
  }
  return undefined;
}

export function treeFleetGroupFindTreeFleetByDevice(rootTreeFleetGroup: TreeFleetGroupFE, treeDevice: TreeDeviceFE): TreeFleetFE | null {
  const fleet = rootTreeFleetGroup.fleets.find(f => treeFleetIncludesDevice(f, treeDevice));
  if (fleet) {
    return fleet;
  }

  for (const fg of rootTreeFleetGroup.fleetgroups) {
    const fleet = treeFleetGroupFindTreeFleetByDevice(fg, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  return null;
}

export function treeFleetGroupFindTreeFleetByGateway(rootTreeFleetGroup: TreeFleetGroupFE, treeDevice: TreeGatewayFE): TreeFleetFE | null {
  const fleet = rootTreeFleetGroup.fleets.find(f => treeFleetIncludesGateway(f, treeDevice));
  if (fleet) {
    return fleet;
  }

  for (const fg of rootTreeFleetGroup.fleetgroups) {
    const fleet = treeFleetGroupFindTreeFleetByGateway(fg, treeDevice);
    if (fleet) {
      return fleet;
    }
  }

  return null;
}

export function treeFleetGroupAddTreeFleetGroup(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeFleetGroup: TreeFleetGroupFE,
  parentCustomerId: string
): TreeFleetGroupFE {
  if (rootTreeFleetGroup.fleetgroupId === parentCustomerId) {
    return {
      ...rootTreeFleetGroup,
      fleetgroups: rootTreeFleetGroup.fleetgroups.concat([treeFleetGroup]),
    };
  }

  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupAddTreeFleetGroup(fg, treeFleetGroup, parentCustomerId)),
  };
}

export function treeFleetGroupAddTreeFleet(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeFleetGroup: TreeFleetFE,
  parentCustomerId: string
): TreeFleetGroupFE {
  if (rootTreeFleetGroup.fleetgroupId === parentCustomerId) {
    return {
      ...rootTreeFleetGroup,
      fleets: rootTreeFleetGroup.fleets.concat([treeFleetGroup]),
    };
  }

  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupAddTreeFleet(fg, treeFleetGroup, parentCustomerId)),
  };
}

export function treeFleetGroupAddTreeDevices(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeDevices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[],
  treeFleet: TreeFleetFE | TreeFleetUnassignedFE
): TreeFleetGroupFE {
  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupAddTreeDevices(fg, treeDevices, treeFleet)),
    fleets: rootTreeFleetGroup.fleets.map(f => (compareItems(f, treeFleet) ? (treeFleetAddTreeDevices(f, treeDevices) as TreeFleetFE) : f)),
  };
}

export function treeFleetGroupUpdateTreeFleetGroup(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeFleetGroup: TreeFleetGroupFE
): TreeFleetGroupFE {
  if (rootTreeFleetGroup.fleetgroups.find(fg => fg.fleetgroupId === treeFleetGroup.fleetgroupId)) {
    return {
      ...rootTreeFleetGroup,
      fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => (fg.fleetgroupId === treeFleetGroup.fleetgroupId ? treeFleetGroup : fg)),
    };
  }

  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupUpdateTreeFleetGroup(fg, treeFleetGroup)),
  };
}

export function treeFleetGroupUpdateTreeFleet(rootTreeFleetGroup: TreeFleetGroupFE, treeFleet: TreeFleetFE): TreeFleetGroupFE {
  const oldTreeFleetIndex = rootTreeFleetGroup.fleets.findIndex(f => f.fleetId === treeFleet.fleetId);
  if (oldTreeFleetIndex !== -1) {
    const fleets = [...rootTreeFleetGroup.fleets];
    fleets[oldTreeFleetIndex] = treeFleet;
    return {
      ...rootTreeFleetGroup,
      fleets,
    };
  }

  const fleetgroups = rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupUpdateTreeFleet(fg, treeFleet));

  return {
    ...rootTreeFleetGroup,
    fleetgroups,
  };
}

export function treeFleetGroupDeleteTreeFleetGroup(
  rootTreeFleetGroup: TreeFleetGroupFE,
  treeFleetGroup: TreeFleetGroupFE
): TreeFleetGroupFE {
  if (rootTreeFleetGroup.fleetgroups.find(fg => fg.fleetgroupId === treeFleetGroup.fleetgroupId)) {
    return {
      ...rootTreeFleetGroup,
      fleetgroups: rootTreeFleetGroup.fleetgroups.filter(fg => fg.fleetgroupId !== treeFleetGroup.fleetgroupId),
    };
  }

  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupDeleteTreeFleetGroup(fg, treeFleetGroup)),
  };
}

export function TreeFleetGroupDeleteTreeFleet(rootTreeFleetGroup: TreeFleetGroupFE, treeFleet: TreeFleetFE): TreeFleetGroupFE {
  if (rootTreeFleetGroup.fleets.find(f => f.fleetId === treeFleet.fleetId)) {
    return {
      ...rootTreeFleetGroup,
      fleets: rootTreeFleetGroup.fleets.filter(f => f.fleetId !== treeFleet.fleetId),
    };
  }

  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => TreeFleetGroupDeleteTreeFleet(fg, treeFleet)),
  };
}

export function treeFleetGroupDeleteDevices(
  rootTreeFleetGroup: TreeFleetGroupFE,
  devices: (TreeDeviceFE | TreeDeviceUnassignedFE | TreeGatewayFE | TreeGatewayUnassignedFE)[]
): TreeFleetGroupFE {
  return {
    ...rootTreeFleetGroup,
    fleetgroups: rootTreeFleetGroup.fleetgroups.map(fg => treeFleetGroupDeleteDevices(fg, devices)),
    fleets: rootTreeFleetGroup.fleets.map(f => treeFleetDeleteDevices(f, devices) as TreeFleetFE),
  };
}
