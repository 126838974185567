import React, { useState, useContext } from 'react';
import EditFleetGroup from './EditFleetGroup';
import EditCustomer from './EditCustomer';
import EditFleet from './EditFleet';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  TreeCustomerFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeFleetUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';
import { editCustomer, EditCustomerData } from '../../../../services/customerManipulation';
import {
  treeCustomerUpdateTreeCustomer,
  treeCustomerParentTo,
  treeCustomerUpdateTreeFleet,
  treeCustomerUpdateTreeFleetGroup,
} from '../../../../utils/treeCustomerFunctions';
import { editFleetGroup, EditFleetGroupData } from '../../../../services/fleetGroupManipulation';
import { editFleet, EditFleetData } from '../../../../services/fleetManipulation';
import { LoginContext } from '../../../../Login';
import { AppContext } from '../../../../App';
import { BackendError } from '../../../../utils/BackendError';
import ArrangeButton from '../common/ArrangeButton';

type Props = {
  selectedTreeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>;
  changeSelectedTreeItem: (treeItem?: Exclude<TreeItemFE, TreeFleetUnassignedFE>) => void;
};

export default function EditButton({ selectedTreeItem, changeSelectedTreeItem }: Props): JSX.Element {
  const loginContext = useContext(LoginContext);
  const [editCustomerPopupOpen, setEditCustomerPopupOpen] = useState(false);
  const [editFleetGroupPopupOpen, setEditFleetGroupPopupOpen] = useState(false);
  const [editFleetPopupOpen, setEditFleetPopupOpen] = useState(false);
  const appContext = useContext(AppContext);

  const parent =
    selectedTreeItem && appContext.rootTreeCustomer
      ? instanceOfTreeFleetFE(selectedTreeItem) ||
        instanceOfTreeFleetGroupFE(selectedTreeItem) ||
        instanceOfTreeCustomerFE(selectedTreeItem)
        ? treeCustomerParentTo(appContext.rootTreeCustomer, selectedTreeItem)
        : undefined
      : undefined;

  async function updateCustomer(treeCustomer: TreeCustomerFE, newData: EditCustomerData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }
    if (appContext.rootTreeCustomer) {
      const result = await editCustomer(treeCustomer.customerId, newData, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeCustomer: TreeCustomerFE = {
          ...treeCustomer,
          customerName: newData.name,
          description: newData.description,
          timezone: newData.timezone,
        };
        appContext.updateRootTreeCustomer(treeCustomerUpdateTreeCustomer(appContext.rootTreeCustomer, newTreeCustomer));
        changeSelectedTreeItem(newTreeCustomer);
      }
      return result;
    }
    return false;
  }

  async function updateFleetGroup(treeFleetGroup: TreeFleetGroupFE, newData: EditFleetGroupData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }
    if (appContext.rootTreeCustomer) {
      const result = await editFleetGroup(treeFleetGroup.fleetgroupId, newData, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleetGroup: TreeFleetGroupFE = {
          ...treeFleetGroup,
          fleetgroupName: newData.name,
          description: newData.description,
        };
        appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleetGroup(appContext.rootTreeCustomer, newTreeFleetGroup));
        changeSelectedTreeItem(newTreeFleetGroup);
      }
      return result;
    }
    return false;
  }

  async function updateFleet(treeFleet: TreeFleetFE, newData: EditFleetData): Promise<boolean> {
    if (!loginContext.accessToken) {
      appContext.addBackendError(new BackendError(0, 'No login token', ''));
      return false;
    }
    if (appContext.rootTreeCustomer) {
      const result = await editFleet(treeFleet.fleetId, newData, loginContext.accessToken, appContext.addBackendError);
      if (result) {
        const newTreeFleet: TreeFleetFE = {
          ...treeFleet,
          fleetName: newData.name,
          lat: newData.lat,
          lng: newData.lng,
          description: newData.description,
          timezone: newData.timezone,
        };
        appContext.updateRootTreeCustomer(treeCustomerUpdateTreeFleet(appContext.rootTreeCustomer, newTreeFleet));
        changeSelectedTreeItem(newTreeFleet);
      }
      return result;
    }
    return false;
  }

  return (
    <>
      <ArrangeButton
        title='Edit'
        onClick={(): void => {
          if (selectedTreeItem && instanceOfTreeCustomerFE(selectedTreeItem)) {
            setEditCustomerPopupOpen(true);
          } else if (selectedTreeItem && instanceOfTreeFleetGroupFE(selectedTreeItem)) {
            setEditFleetGroupPopupOpen(true);
          } else if (selectedTreeItem && instanceOfTreeFleetFE(selectedTreeItem)) {
            setEditFleetPopupOpen(true);
          }
        }}
        disabled={
          !(
            selectedTreeItem &&
            (instanceOfTreeCustomerFE(selectedTreeItem) ||
              instanceOfTreeFleetGroupFE(selectedTreeItem) ||
              instanceOfTreeFleetFE(selectedTreeItem)) &&
            !(instanceOfTreeFleetFE(selectedTreeItem) && selectedTreeItem.demo)
          )
        }
      />
      {selectedTreeItem && instanceOfTreeCustomerFE(selectedTreeItem) && (
        <EditCustomer
          parent={parent ? (parent as TreeCustomerFE) : undefined}
          treeCustomer={selectedTreeItem as TreeCustomerFE}
          open={editCustomerPopupOpen}
          close={(): void => setEditCustomerPopupOpen(false)}
          updateCustomer={updateCustomer}
        />
      )}
      {parent && selectedTreeItem && instanceOfTreeFleetGroupFE(selectedTreeItem) && (
        <EditFleetGroup
          parent={parent}
          treeFleetGroup={selectedTreeItem as TreeFleetGroupFE}
          open={editFleetGroupPopupOpen}
          close={(): void => setEditFleetGroupPopupOpen(false)}
          updateFleetGroup={updateFleetGroup}
        />
      )}
      {parent && selectedTreeItem && instanceOfTreeFleetFE(selectedTreeItem) && (
        <EditFleet
          parent={parent}
          treeFleet={selectedTreeItem}
          open={editFleetPopupOpen}
          close={(): void => setEditFleetPopupOpen(false)}
          editFleet={updateFleet}
        />
      )}
    </>
  );
}
