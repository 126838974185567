interface DownloadFileProps {
  readonly apiDefinition: () => Promise<Response>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: (message: string) => void;
  readonly getFileName: () => string;
}

interface DownloadedFileInfo {
  readonly download: () => Promise<void>;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  getFileName,
}: DownloadFileProps): DownloadedFileInfo => {
  const download = async (): Promise<void> => {
    try {
      preDownloading();

      const data = await apiDefinition();
      const blob = await data.blob();
      const url = URL.createObjectURL(blob);
      const anchor = document.createElement('a');

      anchor.href = url;
      anchor.setAttribute('download', getFileName());
      anchor.click();

      postDownloading();
      URL.revokeObjectURL(url);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err && err.message) {
        onError(err.message);
      } else {
        onError('');
      }
    }
  };

  return { download };
};
