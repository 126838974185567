import React, { useContext, useEffect } from 'react';
import ExpandableRow from '../ExpandableRow';
import Box from '@mui/material/Box';
import { Table, TableHead, TableBody } from '@mui/material';
import { TreeContext } from '../SearchableTree';
import {
  compareItems,
  instanceOfTreeDeviceFE,
  instanceOfTreeDeviceUnassignedFE,
  TreeDeviceFE,
  TreeDeviceUnassignedFE,
  TreeItemFE,
} from '../../../../model/frontendDataModels';
import DeviceTableRow from './DeviceTableRow';

type Props = {
  name: string;
  suffix: 'batteries' | 'chargers';
  iconName: 'battery' | 'charger';
  fleetId: string;
  deviceList: (TreeDeviceFE | TreeDeviceUnassignedFE)[];
  depth: number;
  unassigned: boolean;
};

export default function DeviceList({ name, suffix, iconName, fleetId, deviceList, depth, unassigned }: Props): JSX.Element {
  const treeContext = useContext(TreeContext);

  const deviceListId = `${fleetId}${suffix}`;

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(deviceListId)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (
          (instanceOfTreeDeviceFE(treeItem) || instanceOfTreeDeviceUnassignedFE(treeItem)) &&
          deviceList.findIndex(device => compareItems(device, treeItem)) !== -1
        ) {
          treeContext.expandTree(deviceListId);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label={name}
      iconName={iconName}
      depth={depth}
      selected={false}
      expanded={treeContext.expandedTreeItemIds.includes(deviceListId)}
      expandTree={(): void => treeContext.expandTree(deviceListId)}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];

        treeContext.selectedTreeItems.forEach(treeItem => {
          if (!((instanceOfTreeDeviceFE(treeItem) || instanceOfTreeDeviceUnassignedFE(treeItem)) && deviceList.includes(treeItem))) {
            newSelectedTreeItems.push(treeItem);
          }
        });

        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(deviceListId);
      }}
      hasWarning={deviceList.findIndex(device => instanceOfTreeDeviceFE(device) && device.warning) !== -1}
    >
      <Box sx={{ overflow: 'hidden' }}>
        <Table
          size='small'
          sx={{
            width: '100%',
            whiteSpace: 'nowrap' as const,
            position: 'relative' as const,
            tableLayout: 'fixed' as const,
          }}
        >
          <TableHead>
            <DeviceTableRow deviceList={deviceList} unassigned={unassigned} />
          </TableHead>
          <TableBody>
            {deviceList.map(treeDevice => {
              return <DeviceTableRow key={treeDevice.mui} treeDevice={treeDevice} deviceList={deviceList} unassigned={unassigned} />;
            })}
          </TableBody>
        </Table>
      </Box>
    </ExpandableRow>
  );
}
