import React from 'react';
import Box from '@mui/material/Box';
import { TreeCustomerFE, TreeItemFE } from '../../../model/frontendDataModels';
import SearchableTree from '../../common/searchableTree/SearchableTree';
import { ViewWithHeader } from '../common/ViewWithHeader';

type Props = {
  rootTreeCustomer: TreeCustomerFE;
  canSelect: (treeItem: TreeItemFE) => boolean;
  selectedTreeItems: TreeItemFE[];
  selectTreeItems: (selectedTreeItems: TreeItemFE[]) => void;
};

export default function UserAccessSelector({ rootTreeCustomer, canSelect, selectedTreeItems, selectTreeItems }: Props): JSX.Element {
  return (
    <ViewWithHeader
      sx={{
        minWidth: 400,
        borderRight: '2px solid black',
      }}
      header={
        <>
          <Box sx={{ marginLeft: '1em' }}>{'User Access'}</Box>
        </>
      }
    >
      <SearchableTree
        rootTreeCustomer={rootTreeCustomer}
        canSelect={canSelect}
        selectedTreeItems={selectedTreeItems}
        selectTreeItems={selectTreeItems}
        includeUnassigned={false}
        includeDevices={false}
        onlyCustomers={true}
        wideMode={false}
      />
    </ViewWithHeader>
  );
}
