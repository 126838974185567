import { useSWRConfig } from 'swr';

type RevalidationOptions = {
  revalidateOnFocus: boolean;
  revalidateOnMount: boolean;
  revalidateOnReconnect: boolean;
  refreshWhenOffline: boolean;
  refreshWhenHidden: boolean;
  refreshInterval: number;
};

export function getSwrRevalidationOptions(key: string, noRefresh = false): RevalidationOptions {
  const { cache } = useSWRConfig();

  const refreshIntervalSeconds = process.env.REACT_APP_UPDATE_INTERVAL_SECONDS
    ? parseInt(process.env.REACT_APP_UPDATE_INTERVAL_SECONDS)
    : 180;

  const refreshInterval = noRefresh ? 0 : refreshIntervalSeconds * 1000;

  return {
    revalidateOnFocus: false,
    revalidateOnMount: !cache.get(key),
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    refreshInterval,
  };
}

export function getNoRefreshFromYearMonth(year: number, month: number): boolean {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  return year === currentYear && month === currentMonth;
}

export function getNoRefreshFromYear(year: number): boolean {
  return new Date().getFullYear() !== year;
}
