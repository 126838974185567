import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import FormDialog from '../../../../common/FormDialog';

type Props = {
  bundles: number;
  open: boolean;
  close: (ok: boolean) => Promise<void>;
};

export function AddBundlePopup({ bundles, close, open }: Props): JSX.Element | null {
  return (
    <FormDialog
      open={open}
      close={async (ok: boolean): Promise<void> => {
        await close(ok);
      }}
      title='Increase number of bundles'
      okButton='Yes'
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant='tableText'>
          {`Increase the number of invoiced bundles to a total of ${bundles + 1}, starting from today. Do you want to proceed?`}
        </Typography>
      </Box>
    </FormDialog>
  );
}
