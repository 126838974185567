import { DataObject } from '../model/backendDataModels';
import { BackendError } from './BackendError';

export function dataFetcher<Type>(token: string): (url: string) => Promise<Type> {
  return async (url: string): Promise<Type> => {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    const body = (await res.json()) as DataObject<Type>;
    if (!res.ok || body.status !== 'success' || !body.data) {
      // eslint-disable-next-line no-console
      console.log(body ? JSON.stringify(body) : '!res.ok');
      const infoMessage = body.message || 'Unknown server error';
      const message = body.status ? `${body.status}: ${infoMessage}` : infoMessage;
      throw new BackendError(res.status, message, url);
    }
    return body.data;
  };
}
