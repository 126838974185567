import React, { useContext } from 'react';
import { SWRResponse } from 'swr';
import { useDeviceHistoryLogs } from '../../../../dataHooks/deviceHooks';
import { LogData } from '../../../../model/backendDataModels';
import { CommonLogSettings } from '../../../../reducers/Reducer';
import Store from '../../../../store/Store';
import { BackendError } from '../../../../utils/BackendError';
import CommonLogTab from './common/CommonLogTab';

type HistoryLogTabProps = {
  mui: string;
  productCategory: 'bms' | 'bmu' | 'charger';
};

export default function HistoryLogTab({ mui, productCategory }: HistoryLogTabProps): JSX.Element {
  const { state, dispatch } = useContext(Store);

  function getHistoryLogDataHook(from: Date, to: Date): SWRResponse<LogData, BackendError> {
    return useDeviceHistoryLogs(productCategory, mui, from, to);
  }

  return (
    <CommonLogTab
      dataHook={getHistoryLogDataHook}
      logSettings={state.logFilesSettings.historyLogSettings}
      updateLogSettings={(settings: CommonLogSettings): void => {
        dispatch({
          type: 'SET_HISTORY_LOG_SETTINGS',
          payload: settings,
        });
      }}
    />
  );
}
