import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../App';
import { treeCustomerDevice } from '../../../../utils/treeCustomerFunctions';
import LogFilesTab from './LogFilesTab';

type LogFilesProps = {
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function LogFiles({ changeHelpBox }: LogFilesProps): JSX.Element | null {
  const params = useParams();
  const appContext = useContext(AppContext);

  const mui: string = params.mui ? params.mui : '';

  useEffect(() => {
    changeHelpBox(undefined);
  }, []);

  if (!appContext.rootTreeCustomer) {
    return null;
  }

  const treeDevice = treeCustomerDevice(appContext.rootTreeCustomer, mui);

  return <>{treeDevice && <LogFilesTab treeDevice={treeDevice} tabMode={false} />}</>;
}
