import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type DataInfoTitleProps = {
  title: string;
  icon?: JSX.Element;
};

export default function DataInfoTitle({ title, icon }: DataInfoTitleProps): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '18px',
      }}
    >
      <Typography variant='graphHeader'>{title}</Typography>
      {icon}
    </Box>
  );
}
