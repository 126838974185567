import { BackendError } from '../utils/BackendError';
import { sendDeleteRequestWithInfo, sendPostRequest } from '../utils/dataSenders';

type BundleInformation = {
  quantity: number;
  customerId?: string;
};

export async function addBundle(
  data: BundleInformation,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = data.customerId ? '/bundles/adminAdd' : '/bundles/add';
    return await sendPostRequest<BundleInformation>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}

export async function deleteBundle(
  data: BundleInformation,
  token: string,
  addBackendError: (newError: BackendError) => void
): Promise<boolean> {
  try {
    const url = data.customerId ? '/bundles/adminDelete' : '/bundles/delete';
    return await sendDeleteRequestWithInfo<BundleInformation>({ url, data, token });
  } catch (err: unknown) {
    addBackendError(err as BackendError);
    return false;
  }
}
