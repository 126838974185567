import { SWRResponse } from 'swr';
import { BmuOverviewStatus } from '../model/backendDataModels';
import { BackendError } from '../utils/BackendError';
import { getFullUrl } from '../utils/getFullUrl';
import { getUseSwr } from '../utils/getUseSwr';

export function useBmuOverviewStatus(mui: string): SWRResponse<BmuOverviewStatus, BackendError> {
  const fullUrl = getFullUrl(`/bmu/${mui}/overview/status`);

  return getUseSwr<BmuOverviewStatus>(fullUrl);
}
