import React, { useState } from 'react';
import { COMPONENT_PADDING } from '../../../../../themes/theme';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import MonitoringButton from './MonitoringButton';
import { ListTabs } from '../../../../../model/backendDataModels';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import Charging from './Charging';
import Equalizing from './Equalizing';
import Box from '@mui/material/Box';

type AddTabProps = {
  listTabs: ListTabs;
  updateAlarms: (active: boolean) => Promise<void>;
  updateStateOfCharge: (active: boolean) => Promise<void>;
  updateCharging: (active: boolean, chargingPerWeek: number[] | null) => Promise<void>;
  updateEqualizing: (active: boolean, equalizingCycles: number | null) => Promise<void>;
  expanded: boolean;
};

export default function AddTab({
  listTabs,
  updateAlarms,
  updateStateOfCharge,
  updateCharging,
  updateEqualizing,
  expanded,
}: AddTabProps): JSX.Element {
  const [addingAlarms, setAddingAlarms] = useState<boolean>(false);
  const [addingSoc, setAddingSoc] = useState<boolean>(false);

  async function updateAlarmsLocal(active: boolean): Promise<void> {
    setAddingAlarms(true);
    await updateAlarms(active);
    setAddingAlarms(false);
  }

  async function updateStateOfChargeLocal(active: boolean): Promise<void> {
    setAddingSoc(true);
    await updateStateOfCharge(active);
    setAddingSoc(false);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', borderTop: '2px solid black' }}>
      <Paper sx={{ padding: `${COMPONENT_PADDING}px` }}>
        <Typography variant='bigHeader' component='div'>
          Add a routine
        </Typography>
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <Grid item sx={{ width: '100%' }}>
            <Charging listTabs={listTabs} updateCharging={updateCharging} expanded={expanded} />
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <Equalizing listTabs={listTabs} updateEqualizing={updateEqualizing} />
          </Grid>
        </Grid>
        <Typography sx={{ paddingTop: '16px' }} variant='bigHeader' component='div'>
          Add monitoring
        </Typography>
        <Grid container spacing={`${COMPONENT_PADDING}px`}>
          <Grid item>
            {addingAlarms && <LoadingIndicator />}
            {!addingAlarms && (
              <MonitoringButton
                title={'Alarms'}
                disabled={!!listTabs.alarms}
                onClick={(): void => {
                  updateAlarmsLocal(true);
                }}
              />
            )}
          </Grid>

          <Grid item>
            {addingSoc && <LoadingIndicator />}
            {!addingSoc && (
              <MonitoringButton
                title={'State of Charge'}
                disabled={!!listTabs.soc}
                onClick={(): void => {
                  updateStateOfChargeLocal(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
