import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { COMPONENT_PADDING } from '../../themes/theme';
import { FormControl, MenuItem, Paper, Select, SelectChangeEvent, useTheme } from '@mui/material';
import Store from '../../store/Store';
import { timeZonesNames } from '@vvo/tzdb';
import SettingsIcon from '@mui/icons-material/Settings';

function TimezoneSetting(): JSX.Element {
  const { state, dispatch } = useContext(Store);

  const theme = useTheme();

  const timezone = state === undefined ? '' : state.timezone;

  return (
    <FormControl sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column' }}>
      <Typography variant='columnHeader' sx={{ height: '24px' }}>
        The timezone will be reset on reload
      </Typography>
      <Select
        value={timezone}
        sx={{
          height: '40px',
          width: '300px',
          backgroundColor: theme.palette.background.default,
          borderRadius: '5px',
          '&:before': {
            borderRadius: '5px',
          },
        }}
        onChange={(event: SelectChangeEvent<string>): void => {
          dispatch({ type: 'SET_TIMEZONE', payload: event.target.value });
        }}
        MenuProps={{ disablePortal: true }}
      >
        {timeZonesNames.map((timezone, index) => (
          <MenuItem key={index} value={timezone} sx={{ height: '40px' }}>
            {timezone}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default function UserSetting(): JSX.Element {
  return (
    <Paper elevation={3} sx={{ display: 'inline-block', padding: `${COMPONENT_PADDING}px` }}>
      <Typography
        sx={{
          display: 'flex',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
        variant='sectionHeader'
      >
        <SettingsIcon />
        <Box sx={{ marginLeft: '1em' }}>Settings</Box>
      </Typography>
      <Box sx={{ marginTop: `${COMPONENT_PADDING}px`, display: 'flex', flexDirection: 'column' }}>
        <TimezoneSetting />
      </Box>
    </Paper>
  );
}
