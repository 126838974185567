import React, { ReactNode } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SxProps } from '../../common/commonTypes';

type AlternatingColorsListProps = {
  headers?: ReactNode[];
  data: ReactNode[][]; // if headers is not set, the first element in each row is treated as the data header.
};

export default function AlternatingColorsList({ data, headers, sx }: AlternatingColorsListProps & SxProps): JSX.Element {
  const theme = useTheme();
  return (
    <Table size='small' sx={{ background: theme.palette.background.default, ...sx }}>
      {headers && (
        <TableHead>
          <TableRow>
            {headers.map((element, cellIndex) => (
              <TableCell key={`cell${cellIndex}`}>
                <Typography variant='columnHeader'>{element}</Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((element, cellIndex) => (
              <TableCell
                key={`cell${cellIndex}`}
                sx={{
                  background: rowIndex % 2 ? theme.palette.background.paper : theme.palette.background.default,
                  borderBottom: 'none',
                  width: cellIndex === 0 ? '300px' : 'none',
                }}
              >
                <Typography variant={!headers && cellIndex === 0 ? 'columnHeader' : 'tableText'}>{element}</Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
