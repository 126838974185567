import React, { useContext } from 'react';
import ComponentBase from '../../componentBase/ComponentBase';
import LineGraph from '../../LineGraph';
import { instanceOfTreeFleetFE, TreeFleetFE, TreeFleetGroupFE } from '../../../../../model/frontendDataModels';
import { useEqualizingCompletion } from '../../../../../dataHooks/fleetAndFleetgroupHooks';
import { AppContext } from '../../../../../App';
import LoadingIndicator from '../../../../common/LoadingIndicator';
import MonthAndYearPicker from '../../../../common/monthAndYearPicker/MonthAndYearPicker';
import moment from 'moment';

type Props = {
  selectedFleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
  title: string;
  startDate: Date;
  endDate: Date;
  onMonthSelected: (month: number) => void;
  onYearSelected: (year: number) => void;
  help?: React.ReactNode;
};

export default function WeeklyEqCompGraph({
  selectedFleetOrFleetGroup,
  title,
  startDate,
  endDate,
  onMonthSelected,
  onYearSelected,
  help,
}: Props): JSX.Element {
  const appContext = useContext(AppContext);

  const startYear = moment(startDate).startOf('year');
  const endYear = moment(endDate).endOf('year');

  const { data, error } = useEqualizingCompletion(
    instanceOfTreeFleetFE(selectedFleetOrFleetGroup) ? 'fleet' : 'fleetgroup',
    instanceOfTreeFleetFE(selectedFleetOrFleetGroup) ? selectedFleetOrFleetGroup.fleetId : selectedFleetOrFleetGroup.fleetgroupId,
    startYear.toDate(),
    endYear.toDate()
  );

  if (error) {
    appContext.addBackendError(error);
  }

  return (
    <ComponentBase title={title} help={help} doubleSize={true}>
      <MonthAndYearPicker
        year={startDate.getFullYear()}
        month={startDate.getMonth()}
        onYearSelected={onYearSelected}
        onMonthSelected={onMonthSelected}
      />
      {data && !error && <LineGraph data={data} />}
      {!data && !error && <LoadingIndicator />}
    </ComponentBase>
  );
}
