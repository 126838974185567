import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CalendarStateDay from './CalendarStateDay';

type StateCalendarHelpBoxProps = {
  error: string;
  warning: string;
  success: string;
};
export default function StateCalendarHelpBox({ error, warning, success }: StateCalendarHelpBoxProps): JSX.Element {
  const flex = { display: 'flex', flexDirection: 'row', alignItems: 'center' };
  return (
    <Box>
      <Box sx={flex}>
        <CalendarStateDay
          date={new Date(1970, 0, 1)}
          calendarState={0}
          selectedDate={null}
          onDateSelected={(): void => {
            /* Mockup */
          }}
        />
        <Typography variant='tableText'>{success}</Typography>
      </Box>
      <Box sx={flex}>
        <CalendarStateDay
          date={new Date(1970, 0, 2)}
          calendarState={1}
          selectedDate={null}
          onDateSelected={(): void => {
            /* Mockup */
          }}
        />
        <Typography variant='tableText'>{warning}</Typography>
      </Box>
      <Box sx={flex}>
        <CalendarStateDay
          date={new Date(1970, 0, 3)}
          calendarState={2}
          selectedDate={null}
          onDateSelected={(): void => {
            /* Mockup */
          }}
        />
        <Typography variant='tableText'>{error}</Typography>
      </Box>
      <Box sx={flex}>
        <CalendarStateDay
          date={new Date()}
          calendarState={4}
          selectedDate={null}
          onDateSelected={(): void => {
            /* Mockup */
          }}
        />
        <Typography variant='tableText'>Today</Typography>
      </Box>
    </Box>
  );
}
