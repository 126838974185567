import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Checkbox, Link, Typography } from '@mui/material';
import FormDialog from '../../common/FormDialog';
import { treeFleetDeviceCount } from '../../../utils/treeFleetFunctions';
import { TreeFleetFE } from '../../../model/frontendDataModels';

type Props = {
  treeFleet: TreeFleetFE;
  open: boolean;
  close: (ok: boolean) => Promise<void>;
};

export default function ActivateFleetPopup({ treeFleet, close, open }: Props): JSX.Element | null {
  const [agree, setAgree] = useState(false);

  if (!treeFleet) {
    return null;
  }

  return (
    <FormDialog
      open={open}
      close={async (ok: boolean): Promise<void> => {
        setAgree(false);
        await close(ok);
      }}
      title='Confimation required'
      okButton='Activate'
      okDisabled={!agree ? true : undefined}
    >
      <Box sx={{ marginTop: '20px' }}>
        <Typography variant='tableText'>
          {`This will activate a monthly subscription with one month notice for ${treeFleetDeviceCount(treeFleet)} devices. 
          The devices will be added to your total number of active devices and be invoiced at the beginning of each month.`}
        </Typography>
      </Box>
      <Box sx={{ marginTop: '14px' }}>
        <Typography variant='tableText'>
          <Box>
            I have read and accept the applicable{' '}
            <Link href='https://docs.micropower-group.com/#!doc/topic:GET_User_Terms_and_conditions' target='_blank'>
              terms and conditions.
            </Link>
            <Checkbox checked={agree} onChange={(): void => setAgree(!agree)} />
          </Box>
        </Typography>
      </Box>
    </FormDialog>
  );
}
