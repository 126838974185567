import React, { useContext, useEffect } from 'react';
import ExpandableRow from './ExpandableRow';
import FleetTree from './FleetTree';
import { TreeContext } from './SearchableTree';
import { selectItem } from './selectItem';
import { compareItems, instanceOfTreeCustomerFE, TreeFleetGroupFE, TreeItemFE } from '../../../model/frontendDataModels';
import { treeFleetGroupIncludes, treeFleetGroupHasWarning } from '../../../utils/treeFleetGroupFunctions';

type Props = {
  treeFleetGroup: TreeFleetGroupFE;
  depth: number;
  unassigned: boolean;
};

export default function FleetGroupTree({ treeFleetGroup, depth, unassigned }: Props): JSX.Element | null {
  const treeContext = useContext(TreeContext);

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(treeFleetGroup.fleetgroupId)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (!instanceOfTreeCustomerFE(treeItem) && treeFleetGroupIncludes(treeFleetGroup, treeItem)) {
          treeContext.expandTree(treeFleetGroup.fleetgroupId);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label={treeFleetGroup.fleetgroupName}
      iconName='fleetgroup'
      depth={depth}
      selected={treeContext.selectedTreeItems.findIndex(item => compareItems(item, treeFleetGroup)) !== -1}
      select={
        treeContext.canSelect(treeFleetGroup)
          ? (event: React.MouseEvent): void => selectItem(treeFleetGroup, treeContext.selectedTreeItems, treeContext.selectTreeItems, event)
          : undefined
      }
      expanded={treeContext.expandedTreeItemIds.includes(treeFleetGroup.fleetgroupId)}
      expandTree={(): void => treeContext.expandTree(treeFleetGroup.fleetgroupId)}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];
        treeContext.selectedTreeItems.forEach(treeItem => {
          if (instanceOfTreeCustomerFE(treeItem) || !treeFleetGroupIncludes(treeFleetGroup, treeItem)) {
            newSelectedTreeItems.push(treeItem);
          }
        });
        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(treeFleetGroup.fleetgroupId);
      }}
      hasWarning={treeFleetGroupHasWarning(treeFleetGroup)}
    >
      {treeFleetGroup.fleetgroups.map(treeFleetGroup => (
        <FleetGroupTree key={treeFleetGroup.fleetgroupId} treeFleetGroup={treeFleetGroup} depth={depth + 1} unassigned={unassigned} />
      ))}
      {treeFleetGroup.fleets.map(treeFleet => (
        <FleetTree key={treeFleet.fleetId} treeFleet={treeFleet} depth={depth + 1} unassigned={unassigned} />
      ))}
    </ExpandableRow>
  );
}
