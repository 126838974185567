import React from 'react';
import { CHART_COLORS } from '../../../../../../themes/theme';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, ChartOptions, ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

type Props = {
  xValues: string[] | Date[];
  data: number[][];
  allOptions: string[];
  leftAxisOptions: string[];
  rightAxisOptions: string[];
  options: ChartOptions<'bar'>;
};

export default function MultiAxisBarChart({ xValues, data, allOptions, leftAxisOptions, rightAxisOptions, options }: Props): JSX.Element {
  ChartJS.register(zoomPlugin);
  const seriesData: ChartData<'bar'> = {
    labels: xValues,
    datasets: [],
  };

  leftAxisOptions.forEach((option: string) => {
    const dataIndex = allOptions.findIndex(o => o === option);
    if (dataIndex !== -1) {
      seriesData.datasets.push({
        label: `${option} (left)`,
        data: data[dataIndex],
        borderColor: CHART_COLORS[seriesData.datasets.length],
        backgroundColor: CHART_COLORS[seriesData.datasets.length],
        yAxisID: 'left',
        pointStyle: 'line',
      });
    }
  });

  rightAxisOptions.forEach((option: string) => {
    const dataIndex = allOptions.findIndex(o => o === option);
    if (dataIndex !== -1) {
      seriesData.datasets.push({
        label: `${option} (right)`,
        data: data[dataIndex],
        borderColor: CHART_COLORS[seriesData.datasets.length],
        backgroundColor: CHART_COLORS[seriesData.datasets.length],
        yAxisID: 'right',
        pointStyle: 'line',
      });
    }
  });

  return <Bar options={options} data={seriesData} />;
}
