import React, { useContext, useState } from 'react';
import LabelledInput from '../selectedDeviceView/logFilesTab/common/LabelledInput';
import { addDays, subMonths, subDays, format } from 'date-fns';
import { Grid, Stack, Typography } from '@mui/material';
import ValidatedDatePicker from '../selectedDeviceView/logFilesTab/common/ValidatedDatePicker';
import DefaultLoadingButton from '../../common/DefaultLoadingButton';
import { useDownloadFile } from '../../../dataHooks/useDownloadFile';
import { LoginContext } from '../../../Login';
import { generateLogExport } from '../../../services/generateLogExport';
import { TreeFleetFE, TreeFleetGroupFE, instanceOfTreeFleetGroupFE } from '../../../model/frontendDataModels';
import ComponentBase from './componentBase/ComponentBase';
import { COMPONENT_PADDING } from '../../../themes/theme';
import { utcToZonedTime } from 'date-fns-tz';
import Store from '../../../store/Store';

type ExportLogsViewProps = {
  maxInterval: number;
  selectedFleetOrFleetGroup: TreeFleetFE | TreeFleetGroupFE;
};

export default function ExportLogsView({ selectedFleetOrFleetGroup, maxInterval }: ExportLogsViewProps): JSX.Element {
  const { state } = useContext(Store);
  const title = 'Export Logs';
  const help = `This action will export all history, config and event logs from underlying fleets and groups in selected time period (maximum ${maxInterval} days).`;
  const now = utcToZonedTime(new Date(), state.timezone);
  const loginContext = useContext(LoginContext);
  const [startDate, setStartDate] = useState<Date>(subMonths(now, 1));
  const [endDate, setEndDate] = useState<Date>(now);
  const [generating, setGenerating] = useState(false);
  const fleetgroup = instanceOfTreeFleetGroupFE(selectedFleetOrFleetGroup);
  const selectedType = fleetgroup ? 'fleetgroup' : 'fleet';
  const selectedId = fleetgroup ? selectedFleetOrFleetGroup.fleetgroupId : selectedFleetOrFleetGroup.fleetId;
  const selectedName = fleetgroup ? selectedFleetOrFleetGroup.fleetgroupName : selectedFleetOrFleetGroup.fleetName;
  const selectedDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24));
  const invalidInterval = selectedDays > maxInterval;
  const intervalErrorMessage = `Too many days selected: ${selectedDays}, max: ${maxInterval} days`;
  const preDownloading = (): void => setGenerating(true);
  const postDownloading = (): void => setGenerating(false);
  const onErrorDownloadFile = (): void => {
    setGenerating(false);
  };
  const getFileName = (): string => {
    return `GET Logs ${selectedName} ${format(startDate, 'yyyy-MM-dd')}-${format(endDate, 'yyyy-MM-dd')}.zip`;
  };
  const downloadFile = (): Promise<Response> => {
    const token = loginContext.accessToken || '';

    return generateLogExport(selectedType, selectedId, startDate, endDate, token, state.timezone);
  };
  const { download } = useDownloadFile({
    apiDefinition: downloadFile,
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    getFileName,
  });

  return (
    <ComponentBase title={title} help={help}>
      <Stack direction='row' sx={{ paddingTop: '6px', marginBottom: '16px', borderTop: '2px solid black' }}>
        <LabelledInput
          label='Start date'
          input={
            <ValidatedDatePicker
              value={startDate}
              updateValue={(date: Date): void => {
                setStartDate(date);
              }}
              maxDate={subDays(endDate, 1)}
            />
          }
          sx={{ lineHeight: '1.5rem' }}
        />
        <LabelledInput
          label='End date'
          input={
            <ValidatedDatePicker
              value={endDate}
              updateValue={(date: Date): void => {
                setEndDate(date);
              }}
              minDate={addDays(startDate, 1)}
              maxDate={now}
            />
          }
          sx={{ lineHeight: '1.5rem' }}
        />
      </Stack>
      <Grid item sx={{ width: '100%' }}>
        <DefaultLoadingButton
          sx={{
            height: '40px',
          }}
          loading={generating}
          onClick={download}
          disabled={invalidInterval}
        >
          Download
        </DefaultLoadingButton>
        <Typography variant='errorText' visibility={invalidInterval ? 'visible' : 'collapse'} sx={{ padding: `${COMPONENT_PADDING}px` }}>
          {intervalErrorMessage}
        </Typography>
      </Grid>
    </ComponentBase>
  );
}
