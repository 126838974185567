import React from 'react';
import DefaultButton from '../../../common/DefaultButton';

type Props = {
  title: string;
  disabled: boolean;
  onClick: () => void;
};

export default function ArrangeButton({ title, disabled, onClick }: Props): JSX.Element {
  return (
    <DefaultButton sx={{ width: '100%', height: '34px' }} onClick={onClick} disabled={disabled}>
      {title}
    </DefaultButton>
  );
}
