import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Icon from '../../../common/Icon';
import IconButton from '@mui/material/IconButton';
import InfoDialog from '../../../common/InfoDialog';

type HelpTextProps = {
  body: React.ReactNode;
};

export default function DataInfoHelpText({ body }: HelpTextProps): JSX.Element {
  const [infoHelpTextOpen, setInfoHelpTextOpen] = useState(false);
  const theme = useTheme();

  return (
    <>
      <IconButton sx={{ marginLeft: '10px', padding: '0px' }} onClick={(): void => setInfoHelpTextOpen(true)}>
        <Icon name='info' sx={{ color: theme.palette.action.active }} fontSize='small' />
      </IconButton>
      <InfoDialog open={infoHelpTextOpen} close={(): void => setInfoHelpTextOpen(false)} title={'Explanation'}>
        {body}
      </InfoDialog>
    </>
  );
}
