import React from 'react';
import { TreeDeviceFE } from '../../../../model/frontendDataModels';
import { useChargerOverviewStatus } from '../../../../dataHooks/chargerHooks';
import { useBmsOverviewStatus } from '../../../../dataHooks/bmsHooks';
import { useBmuOverviewStatus } from '../../../../dataHooks/bmuHooks';
import { useBluOverviewStatus } from '../../../../dataHooks/bluHooks';
import CommonHeader from './CommonHeader';

type Props = {
  selectedDevice: TreeDeviceFE;
  onFullscreenChanged: (fullscreen: boolean) => void;
  fullscreen: boolean;
};

export default function SelectedDeviceHeader({ selectedDevice, onFullscreenChanged, fullscreen }: Props): JSX.Element {
  if (selectedDevice.productCategory === 'charger') {
    return (
      <CommonHeader
        selectedDevice={selectedDevice}
        dataHook={useChargerOverviewStatus}
        onFullscreenChanged={onFullscreenChanged}
        fullscreen={fullscreen}
      />
    );
  }
  if (selectedDevice.productCategory === 'bms') {
    return (
      <CommonHeader
        selectedDevice={selectedDevice}
        dataHook={useBmsOverviewStatus}
        onFullscreenChanged={onFullscreenChanged}
        fullscreen={fullscreen}
      />
    );
  }
  if (selectedDevice.productCategory === 'bmu') {
    if (selectedDevice.type.toLowerCase() === 'blu') {
      return (
        <CommonHeader
          selectedDevice={selectedDevice}
          dataHook={useBluOverviewStatus}
          onFullscreenChanged={onFullscreenChanged}
          fullscreen={fullscreen}
        />
      );
    } else {
      return (
        <CommonHeader
          selectedDevice={selectedDevice}
          dataHook={useBmuOverviewStatus}
          onFullscreenChanged={onFullscreenChanged}
          fullscreen={fullscreen}
        />
      );
    }
  }
  return <></>;
}
