import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { COMPONENT_PADDING } from '../../../themes/theme';
import FleetOrGroupOverviewInfo from '../common/FleetOrGroupOverviewInfo';
import { TreeFleetFE } from '../../../model/frontendDataModels';
import { treeFleetBatteryCount, treeFleetChargerCount, treeFleetGatewayCount } from '../../../utils/treeFleetFunctions';
import DefaultButton from '../../common/DefaultButton';

type InactiveFleetViewProps = {
  selectedFleet: TreeFleetFE;
  admin: boolean;
  openActivateFleetPopup: () => void;
};

export default function InactiveFleetView({ selectedFleet, admin, openActivateFleetPopup }: InactiveFleetViewProps): JSX.Element {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: `0 ${COMPONENT_PADDING}px`,
      }}
    >
      <FleetOrGroupOverviewInfo
        chargerCount={treeFleetChargerCount(selectedFleet)}
        batteryCount={treeFleetBatteryCount(selectedFleet)}
        gatewayCount={treeFleetGatewayCount(selectedFleet)}
        selectedFleetOrFleetGroup={selectedFleet}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 3,
        }}
      >
        <Typography variant='graphHeader' sx={{ marginTop: '20px', color: theme.palette.info.main }}>
          This fleet is not Active
        </Typography>
        {admin && (
          <>
            <Typography variant='graphHeader' sx={{ fontWeight: 'normal' }}>
              Do you want to activate it?
            </Typography>
            <DefaultButton
              sx={{
                marginTop: '10px',
                paddingLeft: '20px',
                paddingRight: '20px',
              }}
              onClick={openActivateFleetPopup}
            >
              Activate Fleet
            </DefaultButton>
          </>
        )}
      </Box>
    </Box>
  );
}
