import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { ListTabs } from '../../../../../model/backendDataModels';
import Routine from './Routine';

type Weekday = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

type WeekdaysExpandedProps = {
  weekdays: Weekday[];
  chargingPerWeek: number[];
  updateChargingPerWeek: (index: number, value: number) => void;
  disabled: boolean;
};

function WeekdaysExpanded({ weekdays, chargingPerWeek, updateChargingPerWeek, disabled }: WeekdaysExpandedProps): JSX.Element {
  return (
    <Box sx={{ padding: '0px 30px 0px 30px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {weekdays.map((weekday, index) => {
        return (
          <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant='highlightText' sx={{ color: '#7297AC' }}>
              {weekday}
            </Typography>
            <TextField
              type='number'
              sx={{ bgcolor: '#232C30', borderRadius: 1 }}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 24 }}
              value={chargingPerWeek[index]}
              onChange={(event): void => updateChargingPerWeek(index, Math.min(Number.parseInt(event.target.value), 24))}
              disabled={disabled}
            />
          </Box>
        );
      })}
    </Box>
  );
}

type WeekdaysNarrowProps = {
  weekdays: Weekday[];
  chargingPerWeek: number[];
  updateChargingPerWeek: (index: number, value: number) => void;
  disabled: boolean;
};

function WeekdaysNarrow({ weekdays, chargingPerWeek, updateChargingPerWeek, disabled }: WeekdaysNarrowProps): JSX.Element {
  return (
    <>
      <Box sx={{ padding: '0px 15px 0px 15px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {weekdays.slice(0, 4).map((weekday, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant='highlightText' sx={{ color: '#7297AC' }}>
                {weekday}
              </Typography>
              <TextField
                type='number'
                sx={{ bgcolor: '#232C30', borderRadius: 1 }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 24 }}
                value={chargingPerWeek[index]}
                onChange={(event): void => updateChargingPerWeek(index, Math.min(Number.parseInt(event.target.value), 24))}
                disabled={disabled}
              />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ padding: '0px 45px 0px 45px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        {weekdays.slice(4).map((weekday, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant='highlightText' sx={{ color: '#7297AC' }}>
                {weekday}
              </Typography>
              <TextField
                type='number'
                sx={{ bgcolor: '#232C30', borderRadius: 1 }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0, max: 24 }}
                value={chargingPerWeek[index + 4]}
                onChange={(event): void => updateChargingPerWeek(index + 4, Math.min(Number.parseInt(event.target.value), 24))}
                disabled={disabled}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}

type Props = {
  listTabs: ListTabs;
  updateCharging: (active: boolean, chargingPerWeek: number[] | null) => Promise<void>;
  expanded: boolean;
};

export default function Charging({ listTabs, updateCharging, expanded }: Props): JSX.Element {
  const [chargingPerWeek, setChargingPerWeek] = useState<number[]>(listTabs.chargingPerWeek || [3, 3, 3, 3, 3, 0, 0]);
  const weekdays: Weekday[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  useEffect(() => {
    const chargingPerWeekCopy = [...chargingPerWeek];
    if (chargingPerWeek.length < 7) {
      chargingPerWeekCopy.push(...Array(7 - chargingPerWeek.length).fill(0));
    }
    setChargingPerWeek(chargingPerWeekCopy);
  }, []);
  return (
    <Routine
      text='Times per day, I would like the batteries to be charged.'
      buttonText='Add charging routine'
      onClick={(): void => {
        const chargingPerWeekCopy = [...chargingPerWeek];
        chargingPerWeekCopy.forEach((number: number, index: number) => {
          if (!number) {
            chargingPerWeekCopy[index] = 0;
          }
        });
        setChargingPerWeek(chargingPerWeekCopy);
        updateCharging(true, chargingPerWeekCopy);
      }}
      buttonDisabled={listTabs.charging || chargingPerWeek.find(x => x > 0) === undefined}
    >
      {expanded && (
        <WeekdaysExpanded
          weekdays={weekdays}
          chargingPerWeek={chargingPerWeek}
          updateChargingPerWeek={(index: number, value: number): void => {
            const chargingPerWeekCopy = [...chargingPerWeek];
            chargingPerWeekCopy[index] = value;
            setChargingPerWeek(chargingPerWeekCopy);
          }}
          disabled={listTabs.charging}
        />
      )}
      {!expanded && (
        <WeekdaysNarrow
          weekdays={weekdays}
          chargingPerWeek={chargingPerWeek}
          updateChargingPerWeek={(index: number, value: number): void => {
            const chargingPerWeekCopy = [...chargingPerWeek];
            chargingPerWeekCopy[index] = value;
            setChargingPerWeek(chargingPerWeekCopy);
          }}
          disabled={listTabs.charging}
        />
      )}
    </Routine>
  );
}
