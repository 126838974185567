import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { COMPONENT_PADDING } from '../../../../themes/theme';
import SubscriptionHistory from './subscriptionHistory/SubscriptionHistory';
import BundleManagement from './bundleManagement/BundleManagement';
import { InvoiceDataInvAdmin, InvoiceDataSysAdmin } from '../../../../model/backendDataModels';
import InvoiceDetailsTable from './InvoiceDetailsTable';
import { KeyedMutator } from 'swr';

function getNrOfBundles(invoiceDataInvAdmin: InvoiceDataInvAdmin): number {
  return invoiceDataInvAdmin.bundles.filter(bundle => bundle.endDate === undefined).length;
}

type InvoiceDetailsTabInvoiceAdminProps = {
  invoiceDataInvAdmins: InvoiceDataInvAdmin[];
  mutate: KeyedMutator<InvoiceDataInvAdmin | InvoiceDataSysAdmin>;
};

export function ShowInvoiceDetails({ invoiceDataInvAdmins, mutate }: InvoiceDetailsTabInvoiceAdminProps): JSX.Element {
  const totalBundles =
    invoiceDataInvAdmins.length > 1 ? invoiceDataInvAdmins.map(id => getNrOfBundles(id)).reduce((sum, value) => sum + value, 0) : undefined;

  return (
    <Stack sx={{ padding: `${COMPONENT_PADDING}px` }} direction='column' spacing={3}>
      <InvoiceDetailsTable invoiceDataInvAdmins={invoiceDataInvAdmins} />
      {totalBundles && (
        <Box>
          <Typography variant='graphHeader'>Total number of bundles: {totalBundles}</Typography>
        </Box>
      )}
      {invoiceDataInvAdmins.length > 0 && <SubscriptionHistory invoiceDataInvAdmins={invoiceDataInvAdmins} />}
      {invoiceDataInvAdmins.length === 1 && <BundleManagement customerInfo={invoiceDataInvAdmins[0]} mutate={mutate} />}
    </Stack>
  );
}
