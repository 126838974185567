import React, { useContext, useEffect } from 'react';
import ExpandableRow from './ExpandableRow';
import { TreeContext } from './SearchableTree';
import { selectItem } from './selectItem';
import { compareItems, instanceOfTreeDeviceUnassignedFE, TreeFleetUnassignedFE, TreeItemFE } from '../../../model/frontendDataModels';
import { treeFleetUnassignedIncludes } from '../../../utils/treeFleetFunctions';
import DeviceList from './deviceList/DeviceList';
import GatewayList from './gatewayList/GatewayList';

type Props = {
  treeFleet: TreeFleetUnassignedFE;
  depth: number;
};

export default function FleetTreeUnassigned({ treeFleet, depth }: Props): JSX.Element | null {
  const treeContext = useContext(TreeContext);

  const fleetIdUnassigned = `${treeFleet.customerId}unassigned`;

  useEffect(() => {
    if (treeContext.selectedTreeItems && !treeContext.expandedTreeItemIds.includes(fleetIdUnassigned)) {
      treeContext.selectedTreeItems.forEach(treeItem => {
        if (instanceOfTreeDeviceUnassignedFE(treeItem) && treeFleetUnassignedIncludes(treeFleet, treeItem)) {
          treeContext.expandTree(fleetIdUnassigned);
        }
      });
    }
  }, [treeContext.selectedTreeItems, treeContext.expandedTreeItemIds]);

  return (
    <ExpandableRow
      label={treeFleet.fleetName}
      iconName='fleetnolocation'
      depth={depth}
      selected={treeContext.selectedTreeItems.findIndex(item => compareItems(item, treeFleet)) !== -1}
      select={
        treeContext.canSelect(treeFleet)
          ? (event: React.MouseEvent): void => selectItem(treeFleet, treeContext.selectedTreeItems, treeContext.selectTreeItems, event)
          : undefined
      }
      expanded={treeContext.expandedTreeItemIds.includes(fleetIdUnassigned)}
      expandTree={(): void => {
        treeContext.expandTree(fleetIdUnassigned);
      }}
      collapseTree={(): void => {
        const newSelectedTreeItems: TreeItemFE[] = [];
        treeContext.selectedTreeItems.forEach(treeItem => {
          if (!(instanceOfTreeDeviceUnassignedFE(treeItem) && treeFleetUnassignedIncludes(treeFleet, treeItem))) {
            newSelectedTreeItems.push(treeItem);
          }
        });
        treeContext.selectTreeItems(newSelectedTreeItems);
        treeContext.collapseTree(fleetIdUnassigned);
      }}
      hasWarning={false}
    >
      {treeContext.includeDevices && treeFleet.chargers.length > 0 && (
        <DeviceList
          name='Chargers'
          suffix='chargers'
          iconName='charger'
          fleetId={fleetIdUnassigned}
          deviceList={treeFleet.chargers}
          depth={depth + 1}
          unassigned={true}
        />
      )}

      {treeContext.includeDevices && treeFleet.batteries.length > 0 && (
        <DeviceList
          name='Batteries'
          suffix='batteries'
          iconName='battery'
          fleetId={fleetIdUnassigned}
          deviceList={treeFleet.batteries}
          depth={depth + 1}
          unassigned={true}
        />
      )}
      {treeContext.includeDevices && treeFleet.gateways.length > 0 && (
        <GatewayList fleetId={fleetIdUnassigned} gatewayList={treeFleet.gateways} depth={depth + 1} unassigned={true} />
      )}
    </ExpandableRow>
  );
}
