import React, { useEffect, useContext } from 'react';
import {
  instanceOfTreeCustomerFE,
  instanceOfTreeDeviceFE,
  instanceOfTreeFleetFE,
  instanceOfTreeFleetGroupFE,
  TreeDeviceUnassignedFE,
  TreeFleetFE,
  TreeFleetGroupFE,
  TreeItemFE,
  TreeFleetUnassignedFE,
  TreeCustomerFE,
  TreeGatewayUnassignedFE,
  instanceOfTreeGatewayFE,
} from '../../model/frontendDataModels';
import SelectedFleetView from './selectedFleetView/SelectedFleetView';
import SelectedFleetGroupView from './selectedFleetGroupView/SelectedFleetGroupView';
import SelectedDeviceView from './selectedDeviceView/SelectedDeviceView';
import { AppContext } from '../../App';
import FleetBrowser from '../common/FleetBrowser';
import { treeCustomerDevice, treeCustomerFindFleetById, treeCustomerFindFleetGroupById } from '../../utils/treeCustomerFunctions';
import SelectedGatewayView from './selectedGatewayView/SelectedGatewayView';

type Props = {
  selectedTreeItem:
    | Exclude<TreeItemFE, TreeCustomerFE | TreeFleetUnassignedFE | TreeDeviceUnassignedFE | TreeGatewayUnassignedFE>
    | undefined;
  setSelectedTreeItem: (
    item: Exclude<TreeItemFE, TreeCustomerFE | TreeFleetUnassignedFE | TreeDeviceUnassignedFE | TreeGatewayUnassignedFE> | undefined
  ) => void;
  changeHelpBox: (helpBox: React.ReactNode) => void;
  hideHelpBox: () => void;
};

export default function FleetView({ selectedTreeItem, setSelectedTreeItem, changeHelpBox }: Props): JSX.Element {
  const appContext = useContext(AppContext);
  const isFleetGroup = selectedTreeItem && instanceOfTreeFleetGroupFE(selectedTreeItem);
  const isFleet = selectedTreeItem && instanceOfTreeFleetFE(selectedTreeItem);
  const isDevice = selectedTreeItem && instanceOfTreeDeviceFE(selectedTreeItem);
  const isGateway = selectedTreeItem && instanceOfTreeGatewayFE(selectedTreeItem);

  useEffect(() => {
    changeHelpBox(undefined);
  }, []);

  useEffect(() => {
    if (selectedTreeItem && appContext.rootTreeCustomer) {
      if (instanceOfTreeFleetFE(selectedTreeItem)) {
        setSelectedTreeItem(treeCustomerFindFleetById(appContext.rootTreeCustomer, selectedTreeItem.fleetId));
      }
      if (instanceOfTreeFleetGroupFE(selectedTreeItem)) {
        setSelectedTreeItem(treeCustomerFindFleetGroupById(appContext.rootTreeCustomer, selectedTreeItem.fleetgroupId));
      }
      if (instanceOfTreeDeviceFE(selectedTreeItem)) {
        setSelectedTreeItem(treeCustomerDevice(appContext.rootTreeCustomer, selectedTreeItem.mui));
      }
    }
  }, [appContext.rootTreeCustomer]);

  if (!appContext.rootTreeCustomer) {
    return <></>;
  }

  return (
    <>
      <FleetBrowser
        rootTreeCustomer={appContext.rootTreeCustomer}
        canSelect={(item: TreeItemFE): boolean => {
          return !instanceOfTreeCustomerFE(item);
        }}
        selectedTreeItems={selectedTreeItem ? [selectedTreeItem] : []}
        selectTreeItems={(items: TreeItemFE[]): void => {
          if (items.length > 0) {
            setSelectedTreeItem(
              items[0] as Exclude<TreeItemFE, TreeCustomerFE | TreeFleetUnassignedFE | TreeDeviceUnassignedFE | TreeGatewayUnassignedFE>
            );
          } else {
            setSelectedTreeItem(undefined);
          }
        }}
        includeUnassigned={false}
      />
      {isDevice && <SelectedDeviceView selectedDevice={selectedTreeItem} />}
      {isGateway && <SelectedGatewayView selectedGateway={selectedTreeItem} />}
      {isFleetGroup && <SelectedFleetGroupView selectedFleetGroup={selectedTreeItem as TreeFleetGroupFE} />}
      {isFleet && <SelectedFleetView selectedFleetId={(selectedTreeItem as TreeFleetFE).fleetId} />}
    </>
  );
}
